import * as React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TableMui from "../../../../components/TableMui/TableMui";

function TableDocuments({
  listTypeDocument,
  rowCount,
  page,
  pageSize,
  loading,
  changePage,
  changePageSize,
  getDataSdvancedFilter,
  filters,
  updateFilters,
  handleChangeStatus,
  handleAlertStatus,
  handleEditTypeDocument,
}) {
  const handleChangePage = (value) => changePage(value);

  const handlePageSize = (newPageSize) => changePageSize(newPageSize);

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    { field: "code", headerName: "Código", width: 180, type: "string", fieldRef: "code" },
    {
      field: "name",
      headerName: "Nombre del tipo de documento",
      width: 400,
      type: "string",
      fieldRef: "name",
    },
    {
      field: "isRequired",
      headerName: "Requerido",
      width: 180,
      type: "string",
      fieldRef: "isRequired",
      renderCell: ({ row }) => {
        const template = <>{row.isRequired ? "Si" : "No"}</>;
        return template;
      },
      filterOptions: [
        {
          name: "Si",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
    },
    {
      field: "hasExpirationDate",
      headerName: "Fecha de expiración",
      width: 180,
      type: "string",
      fieldRef: "hasExpirationDate",
      renderCell: ({ row }) => {
        const template = <>{row.hasExpirationDate ? "Si" : "No"}</>;
        return template;
      },
      filterOptions: [
        {
          name: "Si",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
    },
    {
      field: "nDays",
      headerName: "Días de vigencia",
      width: 180,
      type: "number",
      fieldRef: "nDays",
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 220,
      renderCell: ({ row }) => {
        const template = (
          <>
            {row.isActive ? "Activo" : "Inactivo"}
            <Switch
              checked={row.isActive}
              inputProps={{ "aria-label": "controlled" }}
              onChange={() => handleChangeStatus(row.id)}
            />
            <Tooltip title="Editar Tipo de documento">
              <IconButton
                onClick={() => handleEditTypeDocument(row.id)}
                aria-label="delete"
                size="large"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar Tipo de documento">
              <IconButton
                onClick={() => handleAlertStatus(row.id)}
                aria-label="delete"
                size="large"
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        );
        return template;
      },
    },
  ];

  return (
    <>
      <TableMui
        paginationMode="server"
        columns={columns}
        rows={listTypeDocument}
        rowCount={rowCount}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        onPageChange={handleChangePage}
        onPageSizeChange={handlePageSize}
        disableSelectionOnClick
        checkboxSelection={false}
        getDataSdvancedFilter={getDataSdvancedFilter}
        filters={filters}
        updateFilters={updateFilters}
        autoHeight
      />
    </>
  );
}

TableDocuments.defaultProps = {
  listTypeDocument: [],
  getDataSdvancedFilter: () => {},
  filters: [],
  updateFilters: () => {},
};

TableDocuments.propTypes = {
  listTypeDocument: PropTypes.arrayOf(PropTypes.string),
  rowCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(),
  updateFilters: PropTypes.func,
  getDataSdvancedFilter: PropTypes.func,
  handleChangeStatus: PropTypes.func.isRequired,
  handleAlertStatus: PropTypes.func.isRequired,
  handleEditTypeDocument: PropTypes.func.isRequired,
};

export default TableDocuments;
