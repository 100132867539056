import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDInput from "components/MDInput";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import { Field, Form, Formik } from "formik";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import * as Yup from "yup";

const validationResolution = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  key: Yup.string().required("Campo requerido"),
  prefix: Yup.string().required("Campo requerido"),
  number: Yup.string().required("Campo requerido"),
  rangeinitial: Yup.string().required("Campo requerido"),
  validFrom: Yup.string().required("Campo requerido"),
  validUntil: Yup.string().required("Campo requerido"),
});

function FormResolution({
  handleClose,
  loadingButton,
  handleCreateResolition,
  isModal,
  idCredential,
}) {
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          key: "",
          prefix: "",
          number: "",
          rangeinitial: "",
          validFrom: "",
          validUntil: "",
          electronicInvoiceCredentialId: idCredential,
        }}
        validationSchema={validationResolution}
        onSubmit={(values) => {
          handleCreateResolition(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            {isModal === true ? (
              <>
                <DialogTitle>Resolución DIAN</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="name"
                        type="text"
                        as={MDInput}
                        variant="standard"
                        label="Nombre de resolución"
                        fullWidth
                        error={errors.name && touched.name}
                        helperText={touched.name && errors.name}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="key"
                        type="text"
                        as={MDInput}
                        variant="standard"
                        label="Key"
                        fullWidth
                        error={errors.key && touched.key}
                        helperText={touched.key && errors.key}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="prefix"
                        type="text"
                        as={MDInput}
                        variant="standard"
                        label="Prefix"
                        fullWidth
                        error={errors.prefix && touched.prefix}
                        helperText={touched.prefix && errors.prefix}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="number"
                        type="number"
                        as={MDInput}
                        variant="standard"
                        label="Número de resolución"
                        fullWidth
                        error={errors.number && touched.number}
                        helperText={touched.number && errors.number}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="rangeinitial"
                        type="number"
                        as={MDInput}
                        variant="standard"
                        label="Rango inicial"
                        fullWidth
                        error={errors.rangeinitial && touched.rangeinitial}
                        helperText={touched.rangeinitial && errors.rangeinitial}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="rangeFinal"
                        type="number"
                        as={MDInput}
                        variant="standard"
                        label="Rango final"
                        fullWidth
                        error={errors.rangeFinal && touched.rangeFinal}
                        helperText={touched.rangeFinal && errors.rangeFinal}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        as={TextField}
                        label="Fecha de Inicio"
                        type="date"
                        variant="standard"
                        fullWidth
                        name="validFrom"
                        InputLabelProps={{ shrink: true }}
                        error={errors.validFrom && touched.validFrom}
                        helperText={touched.validFrom && errors.validFrom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        as={TextField}
                        label="Fecha final"
                        type="date"
                        variant="standard"
                        fullWidth
                        name="validUntil"
                        InputLabelProps={{ shrink: true }}
                        error={errors.validUntil && touched.validUntil}
                        helperText={touched.validUntil && errors.validUntil}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={handleClose} variant="outlined" color="info">
                    Cancelar
                  </MDButton>
                  <MDButton type="submit" disabled={loadingButton} variant="gradient" color="info">
                    Guardar
                  </MDButton>
                </DialogActions>
              </>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="name"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Nombre de resolución"
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={touched.name && errors.name}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="key"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Key"
                      fullWidth
                      error={errors.key && touched.key}
                      helperText={touched.key && errors.key}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="prefix"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Prefix"
                      fullWidth
                      error={errors.prefix && touched.prefix}
                      helperText={touched.prefix && errors.prefix}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="number"
                      type="number"
                      as={MDInput}
                      variant="standard"
                      label="Número de resolución"
                      fullWidth
                      error={errors.number && touched.number}
                      helperText={touched.number && errors.number}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="rangeinitial"
                      type="number"
                      as={MDInput}
                      variant="standard"
                      label="Rango inicial"
                      fullWidth
                      error={errors.rangeinitial && touched.rangeinitial}
                      helperText={touched.rangeinitial && errors.rangeinitial}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      name="rangeFinal"
                      type="number"
                      as={MDInput}
                      variant="standard"
                      label="Rango final"
                      fullWidth
                      error={errors.rangeFinal && touched.rangeFinal}
                      helperText={touched.rangeFinal && errors.rangeFinal}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      as={TextField}
                      label="Fecha de Inicio"
                      type="date"
                      variant="standard"
                      fullWidth
                      name="validFrom"
                      InputLabelProps={{ shrink: true }}
                      error={errors.validFrom && touched.validFrom}
                      helperText={touched.validFrom && errors.validFrom}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      as={TextField}
                      label="Fecha final"
                      type="date"
                      variant="standard"
                      fullWidth
                      name="validUntil"
                      InputLabelProps={{ shrink: true }}
                      error={errors.validUntil && touched.validUntil}
                      helperText={touched.validUntil && errors.validUntil}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}

FormResolution.defaultProps = {
  loadingButton: false,
};

FormResolution.propTypes = {
  handleClose: PropTypes.func.isRequired,
  loadingButton: PropTypes.bool,
  handleCreateResolition: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  idCredential: PropTypes.string.isRequired,
};

export default FormResolution;
