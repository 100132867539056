import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Map from "../../components/HeatMap/Map";
import NormalMap from "../../components/Map/MapGeolocation";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import ViewDetailInfo from "./ViewDetailsInfo/Index";
import LoadingMap from "../../components/Map/LoadingMap";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import { getCustomersServiceMap } from "../../services/Customer/CustomerService";
import { getDataDashboard } from "../../services/dashboard/DashboardService";
// eslint-disable-next-line import/named
import { URL_MAP } from "../../utils/utils";
// eslint-disable-next-line import/named
import { getPersonalProfile } from "../../services/Personal/PersonalService";
// eslint-disable-next-line import/named
import { setCenterMap } from "../../store/map/actions";
import logo from "../../assets/images/logos/lista_usuarios.png";
import solicitudes from "../../assets/images/logos/solicitudes_pendientes.png";
import gestion from "../../assets/images/logos/gestion.png";
import encuestasGeneradas from "../../assets/images/logos/encuestas-generadas.jpeg";
import agregarEncuestas from "../../assets/images/logos/agregar-encuesta.jpeg";
import editar from "../../assets/images/logos/editar.png";
import agendar from "../../assets/images/logos/agendar.png";

const options = ["Mostrar solo los clientes", "Mostrar solo los empleados"];

const Dashboard = () => {
  const [menu, setMenu] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dataMap, setDataMap] = useState([]);
  const [heatMap, setHeatMap] = useState(false);
  const [dataDashboard, setDataDashboard] = useState(null);
  const [dataPie, setDataPie] = useState(null);
  const [PieDataService, setPieDataService] = useState(null);
  const [dataChart, setDataChart] = useState(null);
  const [openInfoDetails, setOpenInfoDetails] = useState(false);
  const [dataType, setDataType] = useState(0);
  const matches = useMediaQuery("(min-width:1030px)");
  const matchesPie = useMediaQuery("(min-width:663px)");
  const handleCloseViewDetails = () => setOpenInfoDetails(false);
  const [searchedAchar, setSearcheAchar] = useState("");
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();

  const buildPieData = (data) => {
    const pieData = [
      {
        id: "En progreso",
        value: (
          (data?.numberServiceOrderByState.amountInProgress /
            (data?.numberServiceOrderByState.amountInProgress +
              data?.numberServiceOrderByState.amountPending +
              data?.numberServiceOrderByState.amountCancelled)) *
          100
        ).toFixed(2),
        color: "hsl(288, 70%, 50%)",
        percentage: "20%",
      },
      {
        id: "Pendientes",
        value: (
          (data?.numberServiceOrderByState.amountPending /
            (data?.numberServiceOrderByState.amountInProgress +
              data?.numberServiceOrderByState.amountPending +
              data?.numberServiceOrderByState.amountCancelled)) *
          100
        ).toFixed(2),
        color: "hsl(288, 70%, 50%)",
        percentage: "20%",
      },
      {
        id: "Canceladas",
        value: (
          (data?.numberServiceOrderByState.amountCancelled /
            (data?.numberServiceOrderByState.amountInProgress +
              data?.numberServiceOrderByState.amountPending +
              data?.numberServiceOrderByState.amountCancelled)) *
          100
        ).toFixed(2),
        color: "hsl(288, 70%, 50%)",
        percentage: "20%",
      },
    ];
    setDataPie(pieData);
  };

  const buildBarData = (data) => {
    const DataBar = [
      {
        state: "AS",
        "Solicitudes en Progreso": (
          (data?.numberServiceSolicitudeByState.amountInProgress /
            (data?.numberServiceSolicitudeByState.amountInProgress +
              data?.numberServiceSolicitudeByState.amountCanceled +
              data?.numberServiceSolicitudeByState.amountClosed)) *
          100
        ).toFixed(2),
        "value stateColor": "hsl(288, 70%, 50%)",
      },
      {
        state: "AC",
        "Solicitudes Canceladas": (
          (data?.numberServiceSolicitudeByState.amountCanceled /
            (data?.numberServiceSolicitudeByState.amountInProgress +
              data?.numberServiceSolicitudeByState.amountCanceled +
              data?.numberServiceSolicitudeByState.amountClosed)) *
          100
        ).toFixed(2),
        "value stateColor": "hsl(173, 70%, 50%)",
      },
      {
        state: "AP",
        "Solicitudes Cerradas": (
          (data?.numberServiceSolicitudeByState.amountClosed /
            (data?.numberServiceSolicitudeByState.amountInProgress +
              data?.numberServiceSolicitudeByState.amountCanceled +
              data?.numberServiceSolicitudeByState.amountClosed)) *
          100
        ).toFixed(2),
        "value stateColor": "hsl(288, 70%, 50%)",
      },
    ];
    setDataChart(DataBar);
  };

  const buildLineData = (data) => {
    const ServicePieData = [];
    let cont = 0;
    data?.numbersOfTimesTheyRequestService.forEach((e) => {
      if (cont <= 2) {
        const pieServiceData = {
          id: e.serviceName,
          value: e.amount,
          color: "hsl(288, 70%, 50%)",
        };
        ServicePieData.push(pieServiceData);
        cont += 1;
      }
    });
    setPieDataService(ServicePieData);
  };

  const handleOpenViewDetails = (type) => {
    if (type === 1) {
      buildPieData(dataDashboard);
    } else if (type === 2) {
      buildBarData(dataDashboard);
    } else {
      buildLineData(dataDashboard);
    }
    setDataType(type);
    setOpenInfoDetails(true);
  };

  const handledHiddenDetail = (m) => {
    if (m) {
      // eslint-disable-next-line no-param-reassign
      m.showDetail = false;
      const newRows = dataMap.map((r) => (r.id === m.id ? m : r));
      setDataMap(newRows);
    }
  };
  const handledShowDetail = (m) => {
    if (m) {
      // eslint-disable-next-line no-param-reassign
      m.showDetail = true;
      const newRows = dataMap.map((r) => (r.id === m.id ? m : r));
      setDataMap(newRows);
    }
  };

  const closeMenu = () => setMenu(null);

  const openMenu = (event) => setMenu(event.currentTarget);

  const handleRemoveFilter = () => {
    setSelectedIndex(null);
    setMenu(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {options.map((option, index) => (
        <MenuItem
          key={option}
          selected={index === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, index)}
        >
          {option}
        </MenuItem>
      ))}
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={handleRemoveFilter}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remover Filtro
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  const ventanaSecundaria = () => {
    if (!heatMap) {
      window.open("/map", "ventana1", "width=1024,height=1024,scrollbars=NO");
    } else {
      window.open("/heatMap", "ventana1", "width=1024,height=1024,scrollbars=NO");
    }
  };

  const showHeatMap = () => {
    setHeatMap(!heatMap);
  };

  useEffect(() => {
    (async () => {
      const data = await getCustomersServiceMap();
      setDataMap(data);
      setRows(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await getDataDashboard();
        setDataDashboard(data);
        buildLineData(data);
        buildBarData(data);
        buildPieData(data);
      } catch (err) {
        setDataChart([]);
        setDataPie([]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getPersonalProfile();
      if (data) {
        const location = {
          lat: parseFloat(data.tenantSettings.geographicalCoordinates.latitude),
          lng: parseFloat(data.tenantSettings.geographicalCoordinates.longitude),
        };
        dispatch(setCenterMap(location, 12));
      }
    })();
  }, []);

  const requestSearch = (searchedVal) => {
    const { value } = searchedVal.target;
    setSearcheAchar(value);
    const filteredRows = dataMap.filter((row) =>
      row?.name?.value.toLowerCase().includes(value.toLowerCase())
    );
    setRows(filteredRows);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {dataPie !== null && dataDashboard !== null && dataChart !== null ? (
        <>
          <MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
              <MDBox display="flex">
                <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                  filtros&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
                </MDButton>
                {renderMenu}
                &nbsp;&nbsp;
                <TextField
                  style={{ width: "250px", backgroundColor: "#fff" }}
                  label="Buscar..."
                  variant="standard"
                  value={searchedAchar}
                  onChange={requestSearch}
                />
              </MDBox>
              <MDBox>
                {heatMap === false ? (
                  <MDButton
                    style={{ marginRight: "5px" }}
                    onClick={showHeatMap}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Mapa de calor
                  </MDButton>
                ) : (
                  <MDButton
                    style={{ marginRight: "5px" }}
                    onClick={showHeatMap}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Mapa
                  </MDButton>
                )}

                <MDButton onClick={ventanaSecundaria} type="submit" variant="gradient" color="dark">
                  Expandir
                </MDButton>
              </MDBox>
            </MDBox>
            {dataPie !== null && dataDashboard !== null && dataChart !== null && (
              <>
                {heatMap === false ? (
                  <NormalMap
                    googleMapURL={URL_MAP}
                    containerElement={<div style={{ height: `250px`, width: "100%" }} />}
                    mapElement={<div style={{ height: "100%" }} />}
                    loadingElement={<LoadingMap />}
                    isMarkerShown
                    filter={selectedIndex}
                    hiddenDetail={handledHiddenDetail}
                    handleShowDetail={handledShowDetail}
                    customers={rows}
                  />
                ) : (
                  <Map
                    googleMapURL={URL_MAP}
                    containerElement={<div style={{ height: `250px`, width: "100%" }} />}
                    mapElement={<div style={{ height: "100%" }} />}
                    loadingElement={<LoadingMap />}
                    isMarkerShown
                    type="1"
                    style={
                      dataPie !== null && dataDashboard !== null && dataChart !== null
                        ? undefined
                        : { height: "100%", width: "95%" }
                    }
                    filter={selectedIndex}
                    hiddenDetail={handledHiddenDetail}
                    handleShowDetail={handledShowDetail}
                    customers={rows
                      .filter((row) => row.name.key === "CLIENTE")
                      ?.map((customer) => customer.coordinates)}
                    employees={rows
                      .filter((row) => row.name.key !== "CLIENTE")
                      ?.map((customer) => customer.coordinates)}
                  />
                )}
              </>
            )}
          </MDBox>
          <MDBox mt={heatMap === false ? 3 : 33}>
            <Card sx={{ maxWidth: "95%", marginLeft: "2%" }}>
              <CardHeader title="Solicitudes" />
              <Grid container pl={2} spacing={1} mb={4}>
                <Grid
                  item
                  xs
                  ml={10}
                  mt={6}
                  sm={!matchesPie ? 6 : 3}
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    borderColor: "gray",
                    height: "10%",
                    boxShadow: "2px 5px 10px gray",
                  }}
                >
                  <MDBox mt={0} mb={1} pr={1}>
                    <div
                      style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#FF7006",
                          width: "70%",
                        }}
                      >
                        Pendientes
                      </p>
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#FF7006",
                          width: "30%",
                          textAlign: "center",
                        }}
                      >
                        {dataDashboard.numberServiceOrderByState.amountPending}
                      </p>
                    </div>
                    <div
                      style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#1B4E7C",
                          width: "70%",
                        }}
                      >
                        Canceladas{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#1B4E7C",
                          width: "30%",
                          textAlign: "center",
                        }}
                      >
                        {dataDashboard?.numberServiceOrderByState.amountCancelled}
                      </p>
                    </div>

                    <div
                      style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#FF7006",
                          width: "70%",
                        }}
                      >
                        En progreso{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#FF7006",
                          width: "30%",
                          textAlign: "center",
                        }}
                      >
                        {dataDashboard?.numberServiceOrderByState.amountInProgress}
                      </p>
                    </div>
                    <div
                      style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#96BE1F",
                          width: "70%",
                          fontWeight: "bold",
                        }}
                      >
                        Totales{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "17px",
                          color: "#96BE1F",
                          width: "30%",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {dataDashboard?.numberServiceOrderByState.amountInProgress +
                          dataDashboard?.numberServiceOrderByState.amountCancelled +
                          dataDashboard.numberServiceOrderByState.amountPending}
                      </p>
                    </div>
                  </MDBox>
                  <MDBox mt={2} mb={1} pr={1} style={{ width: "80%", alignItems: "center" }}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      style={{ backgroundColor: "#96BE1F", color: "#3C3C3B", marginLeft: "15%" }}
                      fullWidth
                      onClick={() => handleOpenViewDetails(1)}
                    >
                      Ver más
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={!matchesPie ? 6 : 3}
                  ml={2}
                  mt={-2}
                  sm={4}
                  style={{ height: "300px" }}
                >
                  <ResponsivePie
                    data={dataPie}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    enableArcLinkLabels={false}
                    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
                  />
                </Grid>

                <Grid item xs={!matchesPie ? 12 : 4} pl={2} mt={!matchesPie ? 0 : -2} ml={-4}>
                  <Grid container>
                    <Grid item xs={!matchesPie ? 6 : 12}>
                      <MDBox component={NavLink} to="/usuarios">
                        <MDBox
                          component="img"
                          src={logo}
                          width="25%"
                          height="80px"
                          style={{ marginLeft: "40%" }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid mt={3} item xs={!matchesPie ? 6 : 12}>
                      <MDBox
                        component={NavLink}
                        to="/solicitudes"
                        mb={4}
                        pr={1}
                        style={{ width: "100%", height: "5%" }}
                      >
                        <MDBox
                          component="img"
                          src={solicitudes}
                          width="25%"
                          height="80px"
                          style={{ marginLeft: "40%" }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid mt={3} item xs={12}>
                      <MDBox
                        component={NavLink}
                        to="/usuarios"
                        pr={1}
                        mb={-2}
                        style={{ width: "100%", height: "5%" }}
                      >
                        <MDBox
                          component="img"
                          src={gestion}
                          width="25%"
                          height="80px"
                          style={{ marginLeft: "40%" }}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
          <Grid container mt={2} ml={2}>
            <Grid item xs={matches ? 6 : 12} mb={!matches ? 2 : 0}>
              <MDBox>
                <Card sx={{ maxWidth: "95%", height: "80%" }}>
                  <CardHeader title="Registro de servicios" />
                  <Grid container spacing={2} pl={2} mb={4}>
                    <Grid item xs={7} style={{ height: "250px", width: "300px" }}>
                      <ResponsiveBar
                        data={dataChart}
                        keys={[
                          "Solicitudes en Progreso",
                          "Solicitudes Canceladas",
                          "Solicitudes Cerradas",
                        ]}
                        indexBy="state"
                        margin={{ top: 5, right: 0, bottom: 20, left: 30 }}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "country",
                          legendPosition: "middle",
                          legendOffset: 32,
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "food",
                          legendPosition: "middle",
                          legendOffset: -40,
                        }}
                        enableGridY={false}
                        enableLabel={false}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                          from: "color",
                          modifiers: [["darker", 1.6]],
                        }}
                        legends={[]}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                      />
                    </Grid>
                    <Grid item xs={5} mt={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid mt={0} item xs={12}>
                            <MDBox
                              component={NavLink}
                              to="/Inspecciones"
                              pr={1}
                              mb={-2}
                              style={{ width: "100%", height: "5%" }}
                            >
                              <MDBox
                                component="img"
                                src={encuestasGeneradas}
                                width="45%"
                                height="80px"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid mt={2} item xs={12}>
                            <MDBox
                              component={NavLink}
                              to="/Inspecciones"
                              pr={1}
                              mb={-2}
                              style={{ width: "100%", height: "5%" }}
                            >
                              <MDBox
                                component="img"
                                src={agregarEncuestas}
                                width="45%"
                                height="80px"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs sm={12}>
                      <MDBox mt={4} mb={1} pr={1} ml={3}>
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "50%",
                          }}
                        >
                          <p style={{ fontSize: "17px", color: "#FF7006", width: "60%" }}>
                            Canceladas
                          </p>
                          <p style={{ fontSize: "17px", color: "#FF7006", width: "30%" }}>
                            {dataDashboard.numberServiceSolicitudeByState.amountCanceled}
                          </p>
                        </div>
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "50%",
                          }}
                        >
                          <p style={{ fontSize: "17px", color: "#1B4E7C", width: "60%" }}>
                            Cerradas
                          </p>
                          <p style={{ fontSize: "17px", color: "#1B4E7C", width: "30%" }}>
                            {dataDashboard.numberServiceSolicitudeByState.amountClosed}
                          </p>
                        </div>
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "50%",
                          }}
                        >
                          <p style={{ fontSize: "17px", color: "#FF7006", width: "60%" }}>
                            En progreso
                          </p>
                          <p style={{ fontSize: "17px", color: "#FF7006", width: "30%" }}>
                            {dataDashboard.numberServiceSolicitudeByState.amountInProgress}
                          </p>
                        </div>
                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "50%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "17px",
                              color: "#96BE1F",
                              width: "60%",
                              fontWeight: "bold",
                            }}
                          >
                            Total
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              color: "#96BE1F",
                              width: "30%",
                              fontWeight: "bold",
                            }}
                          >
                            {dataDashboard.numberServiceSolicitudeByState.amountInProgress +
                              dataDashboard.numberServiceSolicitudeByState.amountClosed +
                              dataDashboard.numberServiceSolicitudeByState.amountCanceled}
                          </p>
                        </div>
                      </MDBox>
                      <MDBox mt={2} mb={1} pr={1} style={{ width: "80%" }}>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          style={{ backgroundColor: "#96BE1F", color: "#3C3C3B" }}
                          fullWidth
                          onClick={() => handleOpenViewDetails(2)}
                        >
                          Ver más
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={matches ? 6 : 12}>
              <MDBox>
                <Card sx={{ maxWidth: "91%", height: "80%" }}>
                  <CardHeader title="Servicios solicitados" />
                  <Grid container pl={2} spacing={1} mb={4}>
                    <Grid item xs={7} style={{ height: "250px", width: "300px" }}>
                      <ResponsivePie
                        data={PieDataService}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        enableArcLinkLabels={false}
                        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
                      />
                    </Grid>
                    <Grid item xs={5} mt={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid mt={2} item xs={12}>
                            <MDBox
                              component={NavLink}
                              to="/cuenta"
                              pr={1}
                              mb={-2}
                              style={{ width: "100%", height: "5%" }}
                            >
                              <MDBox component="img" src={editar} width="45%" height="80px" />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid mt={2} item xs={12}>
                            <MDBox
                              component={NavLink}
                              to="/clientes-asociados"
                              pr={1}
                              mb={-2}
                              style={{ width: "100%", height: "5%" }}
                            >
                              <MDBox component="img" src={agendar} width="45%" height="80px" />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs sm={12}>
                      <MDBox mt={2} mb={1} pr={1} style={{ width: "80%" }}>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          style={{ backgroundColor: "#96BE1F", color: "#3C3C3B" }}
                          fullWidth
                          onClick={() => handleOpenViewDetails(3)}
                        >
                          Ver más
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
          <ViewDetailInfo
            open={openInfoDetails}
            data={dataDashboard}
            onClose={handleCloseViewDetails}
            dataType={dataType}
          />
        </>
      ) : (
        <Card sx={{ maxWidth: "95%", marginLeft: "2%", marginTop: "2%" }}>
          <CardHeader sx={{ marginLeft: "35%" }} title="No hay datos que mostrar" />
        </Card>
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
