/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";

function CardPermissions({ title, percentage, handleChange, check }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <Grid container>
          <Grid xs={10}>
            <Grid container>
              <Tooltip title={percentage.label}>
                <MDTypography noWrap="true" variant="button" fontWeight="light" color="text">
                  <strong>{percentage.label}</strong>
                </MDTypography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid xs={2} style={{ marginTop: "-10px" }}>
            <Checkbox
              onChange={(event) => handleChange(event, title)}
              inputProps={{ "aria-label": "controlled" }}
              checked={check}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
CardPermissions.defaultProps = {
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
  check: false,
};

// Typechecking props for the ComplexStatisticsCard
CardPermissions.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  check: PropTypes.bool,
};

export default CardPermissions;
