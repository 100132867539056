import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
// import Collapse from "@mui/material/Collapse";
import CardSection from "./CardSection";

function DetailAnswersInOrder({ form, answers, attachments }) {
  // const [collapsesOpen, setCollapsesOpen] = useState([]);

  // const handleCollapse = (Code) => {
  //   if (collapsesOpen.find((item) => item === Code))
  //     setCollapsesOpen(collapsesOpen.filter((item) => item !== Code));
  //   else setCollapsesOpen([...collapsesOpen, Code]);
  // };
  // const isOpen = (code) => !!collapsesOpen.find((item) => item === code);
  const renderQuestionsAndAnswers = (Questions) => {
    // let hasAnswer = false;
    const sectionsWithAnswers = Questions.filter((question) => {
      const answersByQuestion = answers.filter((answer) => answer.questionCode === question.Code);
      const attachmentsForQuestion = attachments.filter(
        (attachment) => attachment.questionCode === question.Code
      );
      return answersByQuestion.length > 0 || attachmentsForQuestion.length > 0;
    });
    return (
      <Grid container spacing={2}>
        {sectionsWithAnswers.map((question) => (
          <Grid item xs={6} key={question.Code}>
            <CardSection
              name={question.Name}
              answers={answers.filter((answer) => answer.questionCode === question.Code)}
              attachments={attachments.filter(
                (attachment) => attachment.questionCode === question.Code
              )}
            />
          </Grid>
        ))}
      </Grid>
    );
    // return (
    //   hasAnswer && (
    //     <Grid container spacing={1}>
    //       {sectionsWithAnswers}
    //     </Grid>
    //   )
    // );
  };

  const renderForm = () => {
    const sectionsWithAnswers = form.Sections.filter((section) => {
      const questionsWithAnswers = section.Questions.filter((question) => {
        const answersByQuestion = answers.filter((answer) => answer.questionCode === question.Code);
        const attachmentsForQuestion = attachments.filter(
          (attachment) => attachment.questionCode === question.Code
        );
        return answersByQuestion.length > 0 || attachmentsForQuestion.length > 0;
      });
      return questionsWithAnswers.length > 0;
    });

    if (sectionsWithAnswers.length === 0) {
      // El formulario no contiene ninguna sección con datos, no lo renderizamos.
      return (
        <>
          <Grid item xs={12} mt={5}>
            <Typography
              style={{
                backgroundColor: "#424242",
                borderRadius: "5px",
                color: "#fafafa",
                padding: "10px",
              }}
              variant="h6"
              align="center"
              gutterBottom
              component="div"
            >
              No hay resultados
            </Typography>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Typography
          style={{
            backgroundColor: "#001CBB",
            borderRadius: "5px",
            color: "#fafafa",
            padding: "10px",
          }}
          variant="h5"
          align="center"
          gutterBottom
          component="div"
        >
          {form.Name}
        </Typography>
        <Grid container spacing={2}>
          {sectionsWithAnswers.map((section) => (
            <Grid item xs={12} key={section.Code}>
              <Typography
                style={{
                  backgroundColor: "#0646FF",
                  borderRadius: "5px",
                  color: "#fafafa",
                  padding: "10px",
                  marginTop: "2px",
                }}
                variant="h6"
                align="center"
                gutterBottom
                component="div"
              >
                {section.Name}
              </Typography>
              <Paper elevation={3} sx={{ py: 2 }}>
                <MDBox sx={{ mr: 2, ml: 2, my: 2 }}>
                  {renderQuestionsAndAnswers(section.Questions)}
                </MDBox>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };
  return renderForm();
}
DetailAnswersInOrder.defaultProps = {
  form: {},
  answers: [],
  attachments: [],
};

DetailAnswersInOrder.propTypes = {
  form: PropTypes.objectOf(PropTypes.string),
  answers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
  attachments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
};

export default DetailAnswersInOrder;
