// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  card: {
    padding: "3rem",
    minHeight: "400px",
    width: "350px",
    borderRadius: "0.5rem",
    border: "1px solid #e1e1e1",
    textAlign: "center",
  },
});

function IllustrationLayout({ header, title, description, illustration, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // eslint-disable-next-line no-console
  console.log(illustration);
  const classes = useStyles();
  return (
    <PageLayout background="white">
      {/* <DefaultNavbar */}
      {/*  routes={pageRoutes} */}
      {/*  action={{ */}
      {/*    type: "external", */}
      {/*    route: "https://creative-tim.com/product/material-dashboard-pro-react", */}
      {/*    label: "buy now", */}
      {/*  }} */}
      {/* /> */}
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main,
        }}
      >
        {/* <Grid item xs={12} lg={6}> */}
        {/*  <MDBox */}
        {/*    display={{ xs: "none", lg: "flex" }} */}
        {/*    width="calc(100% - 2rem)" */}
        {/*    height="calc(100vh - 2rem)" */}
        {/*    borderRadius="lg" */}
        {/*    ml={2} */}
        {/*    mt={2} */}
        {/*    sx={{ backgroundImage: `url(${illustration})` }} */}
        {/*  /> */}
        {/* </Grid> */}
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <MDBox py={3} px={3} textAlign="center">
              {!header ? (
                <>
                  <MDBox mb={1} textAlign="center">
                    <MDTypography variant="h4" fontWeight="bold">
                      {title}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    {description}
                  </MDTypography>
                </>
              ) : (
                header
              )}
            </MDBox>
            <MDBox className={classes.card} p={3}>
              {children}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayout;
