// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Button from "@mui/material/Button";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import FileUploadPage from "./FileUploadPage";
import { updateImageProfile } from "../../store/profile/actions";
import { getDocumentsToUpload, getDocumentsCustomer } from "../../store/generateService/actions";
import UploadDocumentsModal from "../../layouts/documents/index";
import UploadedDocumentsModal from "../../layouts/documents/ModalDocumentsView";

function Header({ infoUser }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [savedData, setSavedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [documentsUpload, setDocumentsUpload] = useState([]);
  const [openDocumentsUpload, setDocumentUpload] = useState(false);
  const [openDocumentsUploaded, setDocumentUploaded] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [isVisibleDocuments, setIsVisibleDocuments] = useState(false);

  const handleOpenDocumentsUpload = () => {
    setDocumentUpload(true);
  };

  const handleCloseDocumentsUpload = () => setDocumentUpload(false);

  const handleOpenDocumentsUploaded = () => {
    setDocumentUploaded(true);
  };

  const handleCloseDocumentsUploaded = () => setDocumentUploaded(false);

  const alert = useAlert();
  const dispatch = useDispatch();

  const changeHandler = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // eslint-disable-next-line no-console
      setSelectedFile(reader.result);
    };
    reader.readAsDataURL(file);
    // eslint-disable-next-line no-param-reassign
    delete infoUser.imageUrl;
    // eslint-disable-next-line no-param-reassign
    delete infoUser.userName;
    // eslint-disable-next-line react/prop-types
    const { id } = infoUser;
    const extension = file.type.split("/")[1];
    // eslint-disable-next-line react/prop-types
    const area = infoUser.userProfile.area ? infoUser.userProfile.area : "";
    // eslint-disable-next-line react/prop-types
    const position = infoUser.userProfile.position ? infoUser.userProfile.position : "";
    const newData = {
      id,
      ...infoUser,
      /* eslint-disable react/prop-types */
      ...infoUser.userProfile,
      area,
      position,
      image: {
        name: `${id}.${extension}`,
        extension,
        data: "",
      },
    };
    setSavedData(newData);
  };

  useEffect(() => {
    if (
      localStorage.getItem("userName") != null &&
      localStorage.getItem("userName") !== undefined
    ) {
      if (localStorage.getItem("userName").includes(".administrador")) {
        setIsVisibleDocuments(true);
      }
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const request = { ...savedData, image: { ...savedData.image, data: selectedFile } };
      await dispatch(updateImageProfile(request));
      alert.success("Actualizado correctamente");
    } catch (e) {
      alert.error("Ocurrió un error al actualizar la información");
    }
  };

  useEffect(() => {
    (async () => {
      if (selectedFile !== null) {
        try {
          setLoading(true);
          await handleSubmit();
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    })();
  }, [selectedFile]);

  useEffect(() => {
    (async () => {
      const documentsView = await dispatch(getDocumentsCustomer(1));
      // eslint-disable-next-line no-console
      console.log(documentsView);
      const data = await dispatch(getDocumentsToUpload(localStorage.getItem("idTenant")));
      setDocumentsUpload(data);
    })();
  }, []);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {infoUser.imageUrl === "" ? (
              <MDAvatar
                alt="profile-image"
                size="xl"
                src="https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png"
                shadow="sm"
              />
            ) : (
              <MDAvatar
                src={loading === false ? infoUser.imageUrl : null}
                alt="profile-image"
                size="xl"
                shadow="sm"
              >
                {loading === true && <CircularProgress size="30px" color="success" />}
              </MDAvatar>
            )}
            <MDBox ml={1}>
              <FileUploadPage changeHandler={changeHandler} />
            </MDBox>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}
          >
            <MDBox
              height="100%"
              mt={0.5}
              lineHeight={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                {infoUser?.firstName} {infoUser?.lastName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {infoUser.userName}
              </MDTypography>
            </MDBox>
          </Grid>
          {!isVisibleDocuments && (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              sx={{ ml: "auto", display: "flex", alignContent: "center", justifyContent: "center" }}
            >
              <MDBox
                ml={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleOpenDocumentsUpload}
                  startIcon={<ContentPasteGoIcon />}
                >
                  Subir documentos
                </Button>
              </MDBox>
              <MDBox
                ml={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleOpenDocumentsUploaded}
                  startIcon={<ContentPasteGoIcon />}
                >
                  Ver documentos
                </Button>
              </MDBox>
            </Grid>
          )}
        </Grid>
        {documentsUpload.length > 0 && (
          <UploadDocumentsModal
            open={openDocumentsUpload}
            uploadDocuments={documentsUpload}
            onClose={handleCloseDocumentsUpload}
            tenantId={localStorage.getItem("tenantId")}
            type={3}
          />
        )}
        <UploadedDocumentsModal
          open={openDocumentsUploaded}
          onClose={handleCloseDocumentsUploaded}
          documentsListData={[
            {
              id: 1,
              name: "Cámara de comercio",
              url: "https://architectcoders.com/wp-content/uploads/2019/08/principios-solid-devexperto.pdf",
              state: "Aprobado",
            },
          ]}
          rowCount={1}
          page={page}
          changePage={setPage}
          pageSize={pageSize}
          changePageSize={setPageSize}
        />
      </MDBox>
    </Card>
  );
}

Header.propTypes = {
  infoUser: PropTypes.shape({
    identificationType: PropTypes.string,
    firstName: PropTypes.string,
    userName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    imageUrl: PropTypes.string,
    email: PropTypes.string,
    nuip: PropTypes.string,
    address: PropTypes.string,
    area: PropTypes.string,
    position: PropTypes.string,
  }).isRequired,
};

export default Header;
