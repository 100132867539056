import httpRequest from "../httpRequest";

// eslint-disable-next-line import/prefer-default-export
export const getRegionService = async () => {
  try {
    return await httpRequest.getEntries(`regions`);
  } catch (error) {
    return JSON.parse(error);
  }
};
