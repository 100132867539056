import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DialogContext } from "components/ModalWithMap";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import logoPdf from "../../../assets/images/logos/pdf.png";
import logoDocs from "../../../assets/images/logos/docx.png";
import logoText from "../../../assets/images/logos/txt.png";
import logoXlsx from "../../../assets/images/logos/xlsx.png";
import logoAudio from "../../../assets/images/logos/audio.png";

function ViewAttachmentPdf({ attachments }) {
  const renderImages = (step) => (
    <Box
      component="img"
      sx={{
        marginTop: 8,
        height: 250,
        width: 400,
        objectFit: "contain",
      }}
      src={step.url}
      alt={step.questionCode}
    />
  );

  const renderVideos = (step) => (
    /* eslint-disable jsx-a11y/media-has-caption */
    <video controls name="media" style={{ marginRight: 70, width: 600, height: 230 }}>
      <source src={step.url} type="video/mp4" />
      <track />
    </video>
  );

  const renderAudios = () => (
    /* eslint-disable jsx-a11y/media-has-caption */
    <MDBox component="img" src={logoAudio} width="600" height="55%" style={{ marginLeft: "10%" }} />
  );

  const renderPdf = () => (
    /* eslint-disable jsx-a11y/media-has-caption */
    <MDBox component="img" src={logoPdf} width="50%" height="35%" style={{ marginLeft: "10%" }} />
  );

  const downloadOtherDocuments = (icon) => (
    <MDBox component="img" src={icon} width="20%" height="35%" style={{ marginRight: "10%" }} />
  );

  const renderMultimedia = (item) => {
    if (item) {
      if (item.url.endsWith(".mp4")) return renderVideos(item);
      if (item.url.endsWith(".m4a") || item.url.endsWith(".mp3")) return renderAudios(logoAudio);
      if (item.url.endsWith(".xlsx")) return downloadOtherDocuments(logoXlsx);
      if (item.url.endsWith(".txt")) return downloadOtherDocuments(logoText);
      if (item.url.endsWith(".doc") || item.url.endsWith(".docx"))
        return downloadOtherDocuments(logoDocs);
      if (item.url.endsWith(".pdf")) return renderPdf(item);
      return renderImages(item);
    }
    return null;
  };

  return (
    <DialogContext.Consumer>
      {() => (
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            gridColumnEnd: "span 1",
            ridRowEnd: "span 1",
          }}
        >
          <Grid container spacing={3}>
            {attachments.map((step) => (
              <Grid item xs={6} key={step.code}>
                <Box
                  sx={{
                    height: 400,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {renderMultimedia(step)}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </DialogContext.Consumer>
  );
}

ViewAttachmentPdf.defaultProps = {
  attachments: [],
};

ViewAttachmentPdf.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
};

export default ViewAttachmentPdf;
