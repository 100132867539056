import httpRequest from "../httpRequest";

// eslint-disable-next-line import/prefer-default-export
export const saveResolutionService = async (dataResolution) => {
  try {
    const idResolution = await httpRequest.post(`resolutiondian/create`, dataResolution);
    return idResolution;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const updateDataResolution = async (id, dataResolution) => {
  try {
    const idResolution = await httpRequest.put(`resolutiondian/${id}/update`, dataResolution);
    return idResolution;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const getResolutionsService = async (filters) => {
  try {
    const { data } = await httpRequest.post("resolutiondian/search", filters);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const changeStatusService = async (idChange) => {
  try {
    const { data } = await httpRequest.patch(`resolutiondian/${idChange}/toggle`, idChange);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};
