import { useState } from "react";
import { CardContent, Collapse, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import ViewAttachmentPdf from "./ViewAttachmentPdf";

function CardSectionPdf({ answers, name, attachments }) {
  const [checked] = useState(true);
  const [activeStep] = useState(0);
  return (
    <div style={{ flex: 3 }}>
      <CardContent>
        <Collapse in={checked} collapsedSize={80}>
          <Typography
            align="justify"
            fontSize="12px"
            variant="body1"
            color="#000"
            gutterBottom
            noWrap={!checked}
          >
            <strong>Pregunta: </strong> {name}
          </Typography>
          {answers.length > 0 ? (
            answers.map((answer) => (
              <Typography
                align="justify"
                fontSize="12px"
                variant="body1"
                color="#000"
                gutterBottom
                noWrap={!checked && typeof answer.value !== "boolean" && answer.value.length > 100}
              >
                <strong>Respuesta: </strong>
                {typeof answer.value !== "boolean" && answer.value}
                {typeof answer.value === "boolean" && (answer.value ? "Sí" : "No")}
              </Typography>
            ))
          ) : (
            <Typography align="justify" fontSize="12px" variant="body1" gutterBottom>
              No hay respuesta registrada
            </Typography>
          )}
          {attachments.length > 0 && (
            <Typography align="justify" fontSize="12px" variant="body1" color="#000" gutterBottom>
              <strong>Hora y Fecha: </strong> {attachments[activeStep].captureDate}
            </Typography>
          )}
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MDBox mt={2}>
              {attachments.length > 0 && <ViewAttachmentPdf attachments={attachments} />}
            </MDBox>
          </div>
        </Collapse>
      </CardContent>
    </div>
  );
}
CardSectionPdf.defaultProps = {};

CardSectionPdf.propTypes = {
  name: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf().isRequired,
  attachments: PropTypes.arrayOf().isRequired,
};

export default CardSectionPdf;
