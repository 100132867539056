import { Dialog, Box } from "@mui/material";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import PropTypes from "prop-types";

function DialogMapDirection({ open, onClose, corsd }) {
  return (
    <>
      <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="lg">
        <Box sx={{ height: 560, pr: 20, overflowY: "hidden" }}>
          <Map
            style={{ overflow: "hidden" }}
            google={window.google}
            zoom={10}
            center={corsd}
            initialCenter={corsd}
          >
            <Marker />
          </Map>
        </Box>
      </Dialog>
    </>
  );
}

DialogMapDirection.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  corsd: PropTypes.objectOf().isRequired,
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAKlcg3KFkR5dPgq2JLkcDHP2Yq4moIcA4",
  libraries: ["visualization"],
})(DialogMapDirection);
