import React from "react";
import { useDispatch } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDInput from "components/MDInput";
import { useAlert } from "react-alert";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import { Field, Form, Formik } from "formik";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { updateResolutionAction } from "../../../../store/Resolution/actions";

const validationResolution = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  key: Yup.string().required("Campo requerido"),
  prefix: Yup.string().required("Campo requerido"),
  number: Yup.string().required("Campo requerido"),
  rangeinitial: Yup.string().required("Campo requerido"),
  validFrom: Yup.string().required("Campo requerido"),
  validUntil: Yup.string().required("Campo requerido"),
});

function FormEditResolution({ handleClose, dataResolution }) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleCreateResolition = async (values) => {
    const data = {
      electronicInvoiceCredentialId: dataResolution?.electronicInvoiceCredentialId,
      name: values.name,
      key: values.key,
      prefix: values.prefix,
      number: values.number,
      rangeinitial: values.rangeinitial,
      rangeFinal: values.rangeFinal,
      validFrom: values.validFrom,
      validUntil: values.validUntil,
      id: dataResolution?.id,
      lastConsecutive: dataResolution?.lastConsecutive,
    };
    await dispatch(updateResolutionAction(dataResolution?.id, data));
    alert.show("Resolución actualizada", {
      position: "top right",
    });
    handleClose(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          name: dataResolution?.name,
          key: dataResolution?.key,
          prefix: dataResolution?.prefix,
          number: dataResolution?.number,
          rangeinitial: dataResolution?.rangeInitial,
          rangeFinal: dataResolution?.rangeFinal,
          lastConsecutive: dataResolution?.lastConsecutive,
          validFrom: dataResolution?.validFrom,
          validUntil: dataResolution?.validUntil,
        }}
        validationSchema={validationResolution}
        onSubmit={(values) => {
          handleCreateResolition(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <DialogTitle>Editar datos</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="name"
                    type="text"
                    as={MDInput}
                    variant="standard"
                    label="Nombre de resolución"
                    fullWidth
                    error={errors.name && touched.name}
                    helperText={touched.name && errors.name}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="key"
                    type="text"
                    as={MDInput}
                    variant="standard"
                    label="Key"
                    fullWidth
                    error={errors.key && touched.key}
                    helperText={touched.key && errors.key}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="prefix"
                    type="text"
                    as={MDInput}
                    variant="standard"
                    label="Prefix"
                    fullWidth
                    error={errors.prefix && touched.prefix}
                    helperText={touched.prefix && errors.prefix}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="number"
                    type="number"
                    as={MDInput}
                    variant="standard"
                    label="Número de resolución"
                    fullWidth
                    error={errors.number && touched.number}
                    helperText={touched.number && errors.number}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="rangeinitial"
                    type="number"
                    as={MDInput}
                    variant="standard"
                    label="Rango inicial"
                    fullWidth
                    error={errors.rangeinitial && touched.rangeinitial}
                    helperText={touched.rangeinitial && errors.rangeinitial}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="rangeFinal"
                    type="number"
                    as={MDInput}
                    variant="standard"
                    label="Rango final"
                    fullWidth
                    error={errors.rangeFinal && touched.rangeFinal}
                    helperText={touched.rangeFinal && errors.rangeFinal}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    name="lastConsecutive"
                    type="number"
                    as={MDInput}
                    variant="standard"
                    label="Ultimo consecutivo"
                    fullWidth
                    error={errors.lastConsecutive && touched.lastConsecutive}
                    helperText={touched.lastConsecutive && errors.lastConsecutive}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    as={TextField}
                    label="Fecha de Inicio"
                    type="datetime-local"
                    variant="standard"
                    fullWidth
                    name="validFrom"
                    InputLabelProps={{ shrink: true }}
                    error={errors.validFrom && touched.validFrom}
                    helperText={touched.validFrom && errors.validFrom}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    as={TextField}
                    label="Fecha final"
                    type="datetime-local"
                    variant="standard"
                    fullWidth
                    name="validUntil"
                    InputLabelProps={{ shrink: true }}
                    error={errors.validUntil && touched.validUntil}
                    helperText={touched.validUntil && errors.validUntil}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={() => handleClose(false)} variant="outlined" color="info">
                Cancelar
              </MDButton>
              <MDButton type="submit" variant="gradient" color="info">
                Guardar
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
}

FormEditResolution.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dataResolution: PropTypes.string.isRequired,
};

export default FormEditResolution;
