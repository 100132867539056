import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
// import { httpClient } from "services/httpRequest";
import MDBox from "../../components/MDBox";
import MDProgress from "../../components/MDProgress";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
// eslint-disable-next-line import/named
import {
  documentsUploadAnonymous,
  documentsUpload,
  documentsUploadCustomer,
} from "../../store/generateService/actions";
import { upload } from "../../services/GenerateServices/GenerateService";

function UploadDocumentsModal({
  open,
  onClose,
  uploadDocuments,
  tenantId,
  setDocumentsSend,
  idCustomer,
  type,
}) {
  const [documentsToUpload] = useState(uploadDocuments);
  const [position, setPosition] = useState(0);
  const [document, setDocument] = useState(documentsToUpload[position]);
  const [documentUpload, setDocumentUpload] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [progressValue, setProgressValue] = useState(0);
  const [valueToAdd] = useState(100 / documentsToUpload.length);
  const [originDate, setOriginDate] = useState(null);
  const [uploading, setUploading] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [documentToAnonimusRequest, setDocumentToAnonimusRequest] = useState([]);
  const [totalUploades, setTotalUploades] = useState(0);
  const [error, setError] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();

  const validateDocumentExistInTheArray = (file) => {
    const documentFind = documentUpload.find((f) => f.tDocumentoId === file.id);
    return documentFind;
  };

  const onClickButton = (e, file) => {
    if (!validateDocumentExistInTheArray(file)) {
      setDocumentName(e.name);
      const uploadDocument = {
        tDocumentoId: file.id,
        filename: e.name,
        originDate: null,
        documentName: file.name,
        type: e.type,
        document: e,
        customerId: idCustomer,
      };
      if (documentUpload.length > 0) {
        if (documentUpload.length > position) {
          const currentDocument = documentUpload[position];
          setDocumentUpload(
            documentUpload.map((itemDocument, index) =>
              index !== position ? itemDocument : { ...currentDocument, ...uploadDocument }
            )
          );
        } else {
          setDocumentUpload([...documentUpload, uploadDocument]);
        }
      } else {
        setDocumentUpload([uploadDocument]);
      }

      setProgressValue(progressValue + valueToAdd);
    }
  };

  const handleReset = () => {
    if (document.hasExpirationDate && !originDate) {
      setError(true);
      return;
    }
    setError(false);
    setOriginDate("");
    setDocumentName("");
    setPosition(position + 1);
  };

  const handleStatesReset = () => {
    setDocumentUpload([]);
    setDocumentName("");
    setProgressValue(0);
    if (type === 1) setDocumentsSend(documentToAnonimusRequest);
    onClose();
  };

  const handleChangeTime = (value) => {
    if (documentUpload.length > 0) {
      if (documentUpload.length > position) {
        const currentDocument = documentUpload[position];

        setDocumentUpload(
          documentUpload.map((itemDocument, index) =>
            index !== position ? itemDocument : { ...currentDocument, originDate: value }
          )
        );
      } else {
        setDocumentUpload([...documentUpload, { originDate: value }]);
      }
    } else {
      setDocumentUpload([{ originDate: value }]);
    }

    setOriginDate(value);
  };

  const uploadDocument = async (du) => {
    try {
      let upDocument = null;
      if (type === 1) upDocument = await dispatch(documentsUploadAnonymous(tenantId, du));
      if (type === 2) {
        upDocument = await dispatch(documentsUpload(tenantId, du));
        // debugger; // eslint-disable-line no-debugger
        // const dataRequest = {
        //   id: upDocument.id,
        // };
        // await httpClient.put(`documents/${upDocument.id}/inuse`, dataRequest);
        // debugger; // eslint-disable-line no-debugger
      }
      if (type === 3) upDocument = await dispatch(documentsUploadCustomer(tenantId, du));
      const buffer = await du.document.arrayBuffer();
      const bytes = new Uint8Array(buffer);
      await upload(du.type, bytes, upDocument.uploadUrl, (newValue) => setUploading(newValue)).then(
        () => {
          setTotalUploades(totalUploades + 1);
          setUploading(0);
        }
      );
      const documents = documentToAnonimusRequest;
      const documentToAdd = {
        id: upDocument.id,
        name: du.documentName,
        url: upDocument.cdnUrl,
        tDocumentoId: du.tDocumentoId,
        documentName: du.documentName,
      };
      documents.push(documentToAdd);
      setDocumentToAnonimusRequest(documents);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleStartUpload = async () => {
    if (document.hasExpirationDate && !originDate) {
      setError(true);
      return;
    }
    setError(false);
    setIsUploading(true);
  };

  const deleteSelectedFile = () => {
    const deleteFile = documentUpload.filter((e) => e.tDocumentoId !== document.id);
    setDocumentUpload(deleteFile);
    setDocumentName("");
    setProgressValue(progressValue - valueToAdd);
  };

  const lastDocument = () => {
    setPosition(position - 1);
  };

  useEffect(() => {
    setDocument(documentsToUpload[position]);
    if (documentUpload.length > 0 && documentUpload[position] !== undefined) {
      setDocumentName(documentUpload[position]?.filename);
    }
  }, [position]);

  useEffect(() => {
    if (totalUploades <= documentUpload.length - 1) {
      uploadDocument(documentUpload[totalUploades]);
    }
    if (totalUploades === documentUpload.length && documentUpload.length > 0) {
      alert.success("Documentos subidos con éxito");
    }
  }, [isUploading, totalUploades]);

  return (
    <>
      <Dialog
        aria-labelledby="responsive-dialog-title"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        style={{ height: "700px" }}
      >
        <DialogTitle>Subir documentos</DialogTitle>
        <DialogContent>
          <MDBox>
            <MDTypography
              variant="h5"
              textAlign="center"
              fontWeight="medium"
              color="#1B4E7C"
              mt={1}
            >
              {document.name}
            </MDTypography>
            <MDBox mt={3}>
              <Grid container>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#96BE1F", color: "#3C3C3B" }}
                    component="label"
                  >
                    Subir documento
                    <input
                      value=""
                      hidden
                      multiple
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => onClickButton(e.target.files[0], document)}
                    />
                  </Button>
                </Grid>
                <Grid item ml={5}>
                  {documentName !== "" && (
                    <Chip label={documentName} onDelete={deleteSelectedFile} />
                  )}
                </Grid>
              </Grid>
            </MDBox>
            {document.hasExpirationDate && (
              <MDBox mt={3}>
                <Grid container>
                  <Grid px={1} item xs={12}>
                    <TextField
                      label="Fecha de expedición"
                      required
                      fullWidth
                      type="datetime-local"
                      variant="standard"
                      error={error}
                      helperText={error && "Este campo es requerido"}
                      value={documentUpload[position]?.originDate}
                      onChange={(value) => {
                        if (value) handleChangeTime(value.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            )}

            <Divider />
            {!isUploading && (
              <MDBox>
                <MDProgress value={progressValue} style={{ width: "90%" }} />
                <p style={{ marginLeft: "92%", marginTop: "-3%" }}>
                  {documentUpload.length}/{documentsToUpload.length}
                </p>
              </MDBox>
            )}
            {isUploading && (
              <MDBox>
                <MDProgress value={uploading} style={{ width: "90%" }} />
                <p style={{ marginLeft: "92%", marginTop: "-3%" }}>
                  {totalUploades}/{documentUpload.length}
                </p>
              </MDBox>
            )}
            <Grid container sx={{ maxHeight: "150px", overflowY: "scroll" }}>
              {documentUpload.length > 0 &&
                documentUpload.map((d) => (
                  <>
                    <Grid container>
                      <Grid item>
                        <p>{d.documentName}:</p>
                      </Grid>
                      <Grid item ml={4}>
                        <Chip label={d.filename} />
                      </Grid>
                    </Grid>
                  </>
                ))}
            </Grid>
            {documentUpload.length > 0 && documentUpload.length === totalUploades ? (
              <Grid container>
                <Grid item style={{ marginLeft: position ? "55%" : "80%" }}>
                  <MDBox mt={4} mb={1} pr={1}>
                    <MDButton
                      type="submit"
                      onClick={handleStatesReset}
                      variant="gradient"
                      color="dark"
                      fullWidth
                    >
                      Aceptar
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item>
                  {position > 0 && (
                    <MDBox mt={4} mb={1} pr={1}>
                      <MDButton
                        type="submit"
                        onClick={lastDocument}
                        variant="gradient"
                        color="dark"
                        fullWidth
                      >
                        Anterior
                      </MDButton>
                    </MDBox>
                  )}
                </Grid>
                <Grid item style={{ marginLeft: position ? "55%" : "80%" }}>
                  {documentUpload.length === documentsToUpload.length ? (
                    <MDBox mt={4} mb={1} pr={1}>
                      <MDButton
                        type="submit"
                        onClick={handleStartUpload}
                        variant="gradient"
                        color="dark"
                        fullWidth
                      >
                        Cargar
                      </MDButton>
                    </MDBox>
                  ) : (
                    <MDBox mt={4} mb={1} pr={1}>
                      <MDButton
                        type="submit"
                        onClick={() => handleReset()}
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={!documentName}
                      >
                        Siguiente
                      </MDButton>
                    </MDBox>
                  )}
                </Grid>
              </Grid>
            )}
          </MDBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

UploadDocumentsModal.defaultProps = {
  setDocumentsSend: () => {},
  idCustomer: "",
};

UploadDocumentsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  setDocumentsSend: PropTypes.func,
  idCustomer: PropTypes.string,
  type: PropTypes.number.isRequired,
};

export default UploadDocumentsModal;
