import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import MDButton from "../../../components/MDButton";
import CardHistory from "./CardHistory";

import { ENDPOINT } from "../../../services/httpRequest";

function DialogHistory({ openDialogHistory, closeDialogHistory, history, payment }) {
  const [paymentData, setPaymentData] = useState(null);
  useEffect(async () => {
    if (payment !== null) {
      const isToken = localStorage.getItem("token");
      const customersData = await axios.get(`${ENDPOINT}/customers/${payment?.customer.id}`, {
        headers: { Authorization: `Bearer ${isToken}` },
      });
      const dataCustomer = customersData.data;
      setPaymentData(dataCustomer);
    }
  }, [payment, openDialogHistory]);
  return (
    <Dialog
      sx={{ m: 0, p: 2 }}
      aria-labelledby="responsive-dialog-title"
      open={openDialogHistory}
      onClose={() => closeDialogHistory()}
      fullWidth="true"
      maxWidth="md"
    >
      <DialogTitle>Registro de pagos</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {history.length > 0 ? (
            <Grid item xs={12} md={6} lg={6}>
              <CardHistory historyData={history[0]} payment={paymentData} />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="subtitle1" align="center" gutterBottom component="div">
                No hay pagos realizados
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={closeDialogHistory} variant="gradient" color="dark">
          Cerrar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

DialogHistory.defaultProps = {
  openDialogHistory: false,
};

DialogHistory.propTypes = {
  openDialogHistory: PropTypes.bool,
  closeDialogHistory: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  payment: PropTypes.objectOf().isRequired,
};

export default DialogHistory;
