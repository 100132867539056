import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { useAlert } from "react-alert";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import LocationOn from "@mui/icons-material/LocationOn";
import AssignmentTurnedIn from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentLate from "@mui/icons-material/AssignmentLate";
import HttpsIcon from "@mui/icons-material/Https";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import TableMui from "../../../../components/TableMui/TableMui";
import httpRequest from "../../../../services/httpRequest";
import AlertConfirm from "../../../../components/AlertConfirm";
import CreateOrder from "../../../../components/CreateOrder";
import ModalWithMap, { DialogContext } from "../../../../components/ModalWithMap";
import { forgotLoginPassword } from "../../../../store/user/actions";

function TableAssociatedClients({
  listClients,
  rowCount,
  page,
  pageSize,
  getAssociatedClients,
  handleEditClient,
  checkboxSelection,
  handleSelectRows,
  selectionModel,
  changePage,
  changePageSize,
  loading,
  getDataSdvancedFilter,
  filters,
  updateFilters,
}) {
  const alert = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [infoState, setInfoState] = useState({});
  const [openVerify, setOpenVerify] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [openCreateOrder, setOpenCreateOrder] = useState(false);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const solicitudeId = null;

  const handlePageSize = (newPageSize) => {
    changePageSize(newPageSize);
  };

  const handleChangePage = (newPage) => changePage(newPage);
  const handleCloseCreateOrder = () => setOpenCreateOrder(false);
  const handleOpenCreateOrder = (id) => {
    setUserId(id);
    setOpenCreateOrder(true);
  };

  const handleActionClient = async (active, id) => {
    if (active) {
      await httpRequest.create(`customers/${id}/deactivate`, id);
    } else {
      await httpRequest.create(`customers/${id}/activate`, id);
    }
    getAssociatedClients();
    setIsOpen(!isOpen);
  };

  const handleVerifyCustomer = async (idCustomerVerify) => {
    try {
      setLoadingVerify(true);
      await httpRequest.create(`customers/${idCustomerVerify}/verify`, idCustomerVerify);
      alert.show("Cliente verificado", { position: "top right" });
      setLoadingVerify(false);
      setOpenVerify(false);
      getAssociatedClients();
    } catch (error) {
      setLoadingVerify(false);
      alert.error("Error al verificar ", { position: "top right" });
    }
  };

  const handlePasswordCustomer = async (email) => {
    try {
      const tenant = localStorage.getItem("idTenant");
      await dispatch(forgotLoginPassword(email, tenant));
      alert.show("Se le a envidado un correo para el cambio de contraseña", {
        position: "top right",
      });
    } catch (error) {
      alert.error("Error al pedir el cambio contraseña ", { position: "top right" });
    }
  };

  const handleAlertStatus = (active, id) => {
    setInfoState({
      activateUser: active,
      userId: id,
    });
    setIsOpen(!isOpen);
  };

  const handleAlertVerify = (idVerify) => {
    setInfoState({
      verify: idVerify,
    });
    setOpenVerify(!openVerify);
  };

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    {
      field: "name",
      headerName: "Nombre",
      type: "string",
      fieldRef: "name",
      width: 180,
    },
    {
      field: "identificationType",
      headerName: "Tipo de identificación",
      type: "string",
      fieldRef: "identificationType",
      width: 200,
    },
    {
      field: "nIdentification",
      headerName: "N° Identificación",
      type: "string",
      fieldRef: "nIdentification",
      width: 180,
    },
    {
      field: "address",
      headerName: "Direccion",
      type: "string",
      fieldRef: "address",
      width: 180,
    },
    {
      field: "phoneNumber",
      headerName: "Teléfono",
      type: "string",
      fieldRef: "phoneNumber",
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      fieldRef: "email",
      width: 270,
    },
    {
      field: "arl",
      headerName: "ARL",
      type: "string",
      fieldRef: "arl",
      width: 180,
    },
    {
      field: "state",
      headerName: "Estado",
      type: "boolean",
      fieldRef: "isActive",
      filterOptions: [
        { name: "Activo", value: true },
        { name: "Inactivo", value: false },
      ],
      width: 120,
      renderCell: ({ row }) => {
        const template = (
          <>
            {" "}
            {row.isActive ? "Activo" : "Inactivo"}
            <Switch
              checked={row.isActive}
              onChange={() => handleAlertStatus(row.isActive, row.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
        return template;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 230,
      renderCell: ({ row }) => {
        const template = (
          <ModalWithMap>
            <Tooltip title="Editar cliente">
              <IconButton onClick={() => handleEditClient(row.id)} aria-label="delete" size="large">
                <AccountCircle fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Pedir nueva Contraseña">
              <IconButton
                onClick={() => handlePasswordCustomer(row.email)}
                aria-label="delete"
                size="large"
              >
                <HttpsIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Crear orden">
              <IconButton
                disabled={!row.isActive}
                onClick={() => handleOpenCreateOrder(row.id)}
                aria-label="delete"
                size="large"
              >
                <AddCircleOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Verificar cliente">
              <IconButton
                onClick={() => handleAlertVerify(row.id)}
                aria-label="delete"
                size="large"
                disabled={row.isVerified}
              >
                {row.isVerified ? (
                  <AssignmentTurnedIn fontSize="inherit" />
                ) : (
                  <AssignmentLate fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver ubicación">
              <ModalWithMap>
                <DialogContext.Consumer>
                  {(value) => (
                    <IconButton
                      disabled={!row.isActive}
                      onClick={() => {
                        value.setMarket({
                          lat: parseFloat(row.geographicalCoordinates.latitude),
                          lng: parseFloat(row.geographicalCoordinates.longitude),
                        });
                        value.setOpen(true);
                      }}
                      aria-label="delete"
                      size="large"
                    >
                      <LocationOn fontSize="inherit" />
                    </IconButton>
                  )}
                </DialogContext.Consumer>
              </ModalWithMap>
            </Tooltip>
          </ModalWithMap>
        );
        return template;
      },
    },
  ];

  return (
    <>
      <TableMui
        paginationMode="server"
        columns={columns}
        rows={listClients}
        rowCount={rowCount}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        onPageChange={handleChangePage}
        onPageSizeChange={handlePageSize}
        disableSelectionOnClick
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={handleSelectRows}
        selectionModel={selectionModel}
        getDataSdvancedFilter={getDataSdvancedFilter}
        filters={filters}
        updateFilters={updateFilters}
        autoHeight
      />
      <AlertConfirm
        open={isOpen}
        title="!Atención¡"
        context={`¿Seguro de que desea ${
          infoState.activateUser ? "desactivar" : "activar"
        } este cliente?`}
        onClose={() => setIsOpen(!isOpen)}
        onAccept={() => handleActionClient(infoState.activateUser, infoState.userId)}
      />
      <AlertConfirm
        open={openVerify}
        title="!Atención¡"
        context="¿Seguro que desea verificar el cliente?"
        onClose={() => setOpenVerify(false)}
        onAccept={() => handleVerifyCustomer(infoState.verify)}
        loading={loadingVerify}
      />
      <CreateOrder
        open={openCreateOrder}
        onClose={handleCloseCreateOrder}
        idUser={userId}
        idSolicitud={solicitudeId}
      />
    </>
  );
}

// Setting default values for the props of ComplexProjectCard
TableAssociatedClients.defaultProps = {
  checkboxSelection: false,
  listClients: [],
  getDataSdvancedFilter: () => {},
  filters: [],
  selectionModel: [],
  updateFilters: () => {},
};

// Typechecking props for the ProfileInfoCard
TableAssociatedClients.propTypes = {
  listClients: PropTypes.arrayOf(PropTypes.string),
  getAssociatedClients: PropTypes.func.isRequired,
  handleEditClient: PropTypes.func.isRequired,
  checkboxSelection: PropTypes.bool,
  handleSelectRows: PropTypes.func.isRequired,
  selectionModel: PropTypes.arrayOf(),
  rowCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(),
  updateFilters: PropTypes.func,
  getDataSdvancedFilter: PropTypes.func,
};

export default TableAssociatedClients;
