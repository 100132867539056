import { useState } from "react";
import { Fab } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import PropsTypes from "prop-types";
import HelpComponentRoot from "./HelpComponentRoot";
import ModalHelpComponent from "./ModalHelpComponent";

function HelpComponent({ path }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {!path.includes("inicio") && (
        <>
          <ModalHelpComponent open={open} onClose={() => setOpen(false)} />
          <Fab
            onClick={() => setOpen(true)}
            color="secondary"
            aria-label="add"
            sx={HelpComponentRoot()}
            style={{
              marginRight: path.includes("inicio") || path.includes("landing-page") ? "0%" : "75%",
            }}
          >
            <HelpIcon size="medium" fontSize="medium" />
          </Fab>
        </>
      )}
    </>
  );
}

HelpComponent.defaultProps = {
  path: "",
};

HelpComponent.propTypes = {
  path: PropsTypes.string,
};

export default HelpComponent;
