import * as React from "react";
import { useDispatch } from "react-redux";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { updateCredential } from "../../store/paymentCredentials/actions";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  token: Yup.string().required("Campo requerido"),
  key: Yup.string().required("Campo requerido"),
});

function DialogEditRunway({ openEditRunway, handleCloseEditRunway, credential, callback }) {
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleCreateRunway = async (values) => {
    const data = {
      id: credential?.id,
      accountName: values.name,
      accessToken: values.token,
      publicKey: values.key,
    };
    try {
      const result = await dispatch(updateCredential(data));
      /* eslint-disable no-console */
      console.log(result);
      alert.success("Credencial actualizada", { position: "top right" });
      callback();
      handleCloseEditRunway();
    } catch (e) {
      alert.error(`${e.Messages[0]}`, {
        position: "top right",
      });
    }
  };

  return (
    <div>
      <Dialog
        open={openEditRunway}
        onClose={handleCloseEditRunway}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="true"
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            name: credential?.accountName,
            token: credential?.accessToken,
            key: credential?.publicKey,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleCreateRunway(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogTitle>Editar pasarela</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      name="name"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Nombre"
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={touched.name && errors.name}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      name="token"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Token de acceso"
                      fullWidth
                      error={errors.token && touched.token}
                      helperText={touched.token && errors.token}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      name="key"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Llave pública"
                      fullWidth
                      error={errors.key && touched.key}
                      helperText={touched.key && errors.key}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDButton onClick={handleCloseEditRunway} variant="outlined" color="info">
                  Cancelar
                </MDButton>
                <MDButton type="submit" variant="gradient" color="info">
                  Guardar
                </MDButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

DialogEditRunway.defaultProps = {
  credential: null,
};

DialogEditRunway.propTypes = {
  openEditRunway: PropTypes.bool.isRequired,
  handleCloseEditRunway: PropTypes.func.isRequired,
  credential: PropTypes,
  callback: PropTypes.func.isRequired,
};

export default DialogEditRunway;
