import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { httpClient } from "services/httpRequest";
import TableMui from "../../components/TableMui/TableMui";
import { documentsUploadAnonymous } from "../../store/generateService/actions";
import { upload } from "../../services/GenerateServices/GenerateService";
import ViewPdf from "../../components/ViewPdf";
import MDTypography from "../../components/MDTypography";

function TableDocumentsState({
  documentsListData,
  rowCount,
  page,
  pageSize,
  changePage,
  changePageSize,
  setDocuments,
}) {
  const handleChangePage = (value) => changePage(value);

  const handlePageSize = (newPageSize) => changePageSize(newPageSize);
  const [uploading, setUploading] = useState(0);
  const [table, setTable] = useState(false);
  const [openPdf2, setOpenPdf2] = useState(false);
  const [pdfView, setPdfToView] = useState("");
  const [pdfName, setPdfName] = useState("");
  const dispatch = useDispatch();

  const tableUpdate = () => {
    setTable(!table);
  };

  const handleOpenViewPdf2 = (url, name) => {
    setOpenPdf2(true);
    setPdfToView(url);
    setPdfName(name);
  };

  const handleCloseViewPdf2 = () => setOpenPdf2(false);

  const updateDocument = async (id, file) => {
    const document = documentsListData.find((doc) => doc.id === id);
    const uploadDocument = {
      tDocumentoId: document.tDocumentoId,
      filename: file.name,
      originDate: null,
      documentName: document.documentName,
      type: file.type,
      document: file,
    };
    const upDocument = await dispatch(documentsUploadAnonymous("", uploadDocument));
    const buffer = await uploadDocument.document.arrayBuffer();
    const bytes = new Uint8Array(buffer);
    await upload(uploadDocument.type, bytes, upDocument.uploadUrl).then(() => {
      setUploading(0);
    });
    // eslint-disable-next-line no-console
    console.log(uploading);
    documentsListData.forEach((doc) => {
      if (doc.id === id) {
        doc.name = file.name; // eslint-disable-line no-param-reassign
        doc.url = upDocument.uploadUrl; // eslint-disable-line no-param-reassign
      }
    });
    // eslint-disable-next-line no-console
    console.log(documentsListData);
    tableUpdate();
  };

  const revisionDocument = async (id, status) => {
    try {
      const dataRequestRevision = {
        id,
        status,
      };
      await httpClient.put(`documents/${id}/revision`, dataRequestRevision);
      documentsListData.forEach((doc) => {
        if (doc.id === id) {
          doc.state = status; // eslint-disable-line no-param-reassign
        }
      });
      setDocuments(documentsListData);
      tableUpdate();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    { field: "name", headerName: "Nombre", width: 200, type: "string" },
    {
      field: "state",
      headerName: "Estado",
      width: 200,
      renderCell: ({ row }) => {
        const template = (
          <>
            <MDTypography
              variant="h6"
              textAlign="center"
              fontWeight="medium"
              color="#1B4E7C"
              mt={1}
            >
              {row.state === "Approved" ? "Aprobado" : ""}
              {row.state === "Rejected" ? "Rechazado" : ""}
              {row.state === "Pending" ? "Pendiente" : ""}
            </MDTypography>
          </>
        );
        return template;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 200,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Tooltip title="Editar documento">
              <Button
                variant="contained"
                component="label"
                style={{ backgroundColor: "transparent", color: "#3C3C3B", border: "none" }}
                startIcon={<EditIcon />}
              >
                <input
                  value=""
                  hidden
                  multiple
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => updateDocument(row.id, e.target.files[0])}
                />
              </Button>
            </Tooltip>
            <Tooltip title="Ver documento">
              <IconButton onClick={() => handleOpenViewPdf2(row.url, row.name)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Aceptar documento">
              <IconButton
                disabled={row.state === "Approved"}
                onClick={() => revisionDocument(row.id, "Approved")}
              >
                <DoneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rechazar">
              <IconButton
                disabled={row.state === "Rejected"}
                onClick={() => revisionDocument(row.id, "Rejected")}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </>
        );
        return template;
      },
    },
  ];

  return (
    <>
      {table ? (
        <>
          <TableMui
            paginationMode="server"
            columns={columns}
            rows={documentsListData}
            rowCount={rowCount}
            pagination
            page={page}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            onPageChange={handleChangePage}
            onPageSizeChange={handlePageSize}
            disableSelectionOnClick
            checkboxSelection={false}
            autoHeight
            disableColumnFilter
            isFilterActive={false}
            hiddenFooter="1"
          />
          <ViewPdf
            open={openPdf2}
            data={pdfView}
            type={3}
            tittlePdf={pdfName}
            onClose={handleCloseViewPdf2}
          />
        </>
      ) : (
        <>
          <TableMui
            paginationMode="server"
            columns={columns}
            rows={documentsListData}
            rowCount={rowCount}
            pagination
            page={page}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            onPageChange={handleChangePage}
            onPageSizeChange={handlePageSize}
            disableSelectionOnClick
            checkboxSelection={false}
            autoHeight
            disableColumnFilter
            isFilterActive={false}
            hiddenFooter="1"
          />
          <ViewPdf
            open={openPdf2}
            data={pdfView}
            type={3}
            tittlePdf={pdfName}
            onClose={handleCloseViewPdf2}
          />
        </>
      )}
    </>
  );
}

TableDocumentsState.defaultProps = {
  documentsListData: [],
};

TableDocumentsState.propTypes = {
  documentsListData: PropTypes.arrayOf(PropTypes.string),
  rowCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
};

export default TableDocumentsState;
