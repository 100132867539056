import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import * as Yup from "yup";
import MDInput from "components/MDInput";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import SignatureCanvas from "react-signature-canvas";
import Button from "@mui/material/Button";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAlert } from "react-alert";
import MDAvatar from "components/MDAvatar";
import { generateId } from "utils/utils";
import FileUploadPage from "components/Header/FileUploadPage";
import { getUserDetailById } from "store/user/actions";
import { useDispatch } from "react-redux";

const objectValidation = {
  firstName: Yup.string()
    .required("Campo requerido")
    .max(40, "El campo Nombres debe tener máximo 40 dígitos."),
  lastName: Yup.string()
    .required("Campo requerido")
    .max(40, "El campo Apellidos debe tener máximo 40 dígitos."),
  email: Yup.string()
    .email("Utilizar formato ejemplo@email.com")
    .required("El Correo electrónico es Requerido"),
  phoneNumber: Yup.string()
    .required("Campo requerido")
    .max(10, "El campo Teléfono debe tener máximo 10 dígitos."),
  nuip: Yup.string()
    .required("Campo requerido")
    .max(10, "El campo Número de documento debe tener máximo 10 dígitos."),
  address: Yup.string()
    .required("Campo requerido")
    .max(40, "El campo Dirección debe tener máximo 40 dígitos."),
};

const newObjectValidation = {
  ...objectValidation,
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Las contraseñas deben coincidir"
  ),
  userName: Yup.string()
    .required("Campo requerido")
    .max(40, "El campo Usuario debe tener máximo 40 dígitos."),
  password: Yup.string()
    .required("Campo requerido")
    .max(40, "El campo Contraseña debe tener máximo 40 dígitos.")
    .min(8, "El campo contraseña debe tener mínimo 8 digitos.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "El campo contraseña debe tener mínimo un número, mínimo una letra minúscula y mínimo una mayúscula."
    ),
};

// const validationSchema = Yup.object().shape();

function DialogRegisterUser({
  open,
  handleClose,
  handleRegisterUser,
  loading,
  listIdentification,
  typeForm,
  idUserEdit,
  setTypeIdentification,
  typeIdentification,
}) {
  const validationSchema = Yup.object().shape(
    typeForm === "registro" ? newObjectValidation : objectValidation
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [dataUserEdit, setDataUserEdit] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    role: "",
    identificationType: "",
    nuip: "",
    address: "",
    area: "",
    position: "",
    userProfile: {},
  });
  const inputEl = useRef(null);
  const alert = useAlert();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const extension = file.type.split("/")[1];
    const name = generateId("user_photo_");
    reader.onloadend = () => {
      setSelectedFile({
        name: `${name}.${extension}`,
        extension,
        data: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleChangeImaSignature = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      inputEl.current.clear();
      inputEl.current.fromDataURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleChange = (event) => {
    setTypeIdentification(event.target.value);
  };

  const handleOnSubmit = (values) => {
    if (inputEl.current.isEmpty()) {
      alert.error("EL campo firma no debe estar vacio", { position: "top right" });
      return;
    }
    const valueWithSignature = {
      ...values,
      image: selectedFile,
      signature: inputEl.current.getTrimmedCanvas().toDataURL("image/png"),
    };
    inputEl.current.clear();
    handleRegisterUser(valueWithSignature, typeIdentification);
  };

  const getDetailUser = async () => {
    try {
      const data = await dispatch(getUserDetailById(idUserEdit));
      setDataUserEdit(data);
      if (data.imageUrl !== null && data.imageUrl !== "") {
        const name = data.imageUrl.split("/")[4];
        const extension = name?.split(".")[1];
        setSelectedFile({
          name: `${name}.${extension}`,
          extension,
          data: data.imageUrl,
        });
      } else {
        setSelectedFile({
          name: "",
          extension: "",
          data: "https://flyclipart.com/thumb2/user-icon-png-pnglogocom-133466.png",
        });
      }

      setIsLoading(false);
    } catch (_) {
      alert.error("Ocurrio un error al consultar detalles del usuario");
    }
  };

  useEffect(() => {
    if (idUserEdit && open) {
      getDetailUser();
    }
  }, [idUserEdit, open]);

  useEffect(() => {
    if (dataUserEdit.signature && !isLoading && inputEl.current !== null && typeForm === "editar") {
      inputEl.current.fromDataURL(dataUserEdit.signature);
    }
  }, [dataUserEdit, isLoading, typeForm]);

  const closeModal = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog sx={{ zIndex: 98 }} open={open} onClose={closeModal} fullWidth="true" maxWidth="lg">
        <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {typeForm === "registro" || !isLoading ? (
            <Formik
              initialValues={{
                firstName: typeForm === "registro" ? "" : dataUserEdit.firstName,
                lastName: typeForm === "registro" ? "" : dataUserEdit.lastName,
                email: typeForm === "registro" ? "" : dataUserEdit.email,
                userName: typeForm === "registro" ? "" : dataUserEdit.userName,
                password: typeForm === "registro" ? "" : "edit",
                confirmPassword: typeForm === "registro" ? "" : "edit",
                phoneNumber: typeForm === "registro" ? "" : dataUserEdit.phoneNumber,
                role: typeForm === "registro" ? "" : dataUserEdit.role,
                identificationType: typeForm === "registro" ? "" : dataUserEdit.identificationType,
                nuip:
                  typeForm === "registro"
                    ? ""
                    : !!dataUserEdit.userProfile && dataUserEdit.userProfile.nuip,
                address:
                  typeForm === "registro"
                    ? ""
                    : !!dataUserEdit.userProfile && dataUserEdit.userProfile.address,
                area:
                  typeForm === "registro"
                    ? ""
                    : !!dataUserEdit.userProfile && dataUserEdit.userProfile.area,
                position:
                  typeForm === "registro"
                    ? ""
                    : !!dataUserEdit.userProfile && dataUserEdit.userProfile.position,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleOnSubmit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <DialogTitle>
                    {typeForm === "registro" ? "Nuevo usuario" : "Editar usuario"}
                  </DialogTitle>
                  <DialogContent sx={{ px: 5, pt: 5 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Grid container spacing={0.5}>
                          <Grid
                            item
                            xs={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <MDAvatar
                              src={selectedFile?.data}
                              alt="profile-image"
                              size="lg"
                              shadow="sm"
                            >
                              {loading === true && <CircularProgress size="30px" color="success" />}
                            </MDAvatar>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <FileUploadPage changeHandler={handleChangeImage} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2} mt={1}>
                          <Field
                            name="firstName"
                            type="text"
                            as={MDInput}
                            label="Nombres (*)"
                            variant="standard"
                            fullWidth
                            error={errors.firstName && touched.firstName}
                            helperText={touched.firstName && errors.firstName}
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2} mt={1}>
                          <Field
                            name="lastName"
                            type="text"
                            as={MDInput}
                            label="Apellidos (*)"
                            fullWidth
                            variant="standard"
                            error={errors.lastName && touched.lastName}
                            helperText={touched.lastName && errors.lastName}
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2} mt={1}>
                          <TextField
                            select
                            label="Tipo de documento (*)"
                            onChange={handleChange}
                            name="identificationType"
                            value={typeIdentification}
                            fullWidth
                            variant="standard"
                            id="outlined-size-normal"
                          >
                            {listIdentification.map((identification) => (
                              <MenuItem key={identification.code} value={identification.code}>
                                {identification.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2}>
                          <Field
                            name="nuip"
                            type="text"
                            as={MDInput}
                            label="Número de documento (*)"
                            variant="standard"
                            fullWidth
                            error={errors.nuip && touched.nuip}
                            helperText={touched.nuip && errors.nuip}
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2}>
                          <Field
                            name="address"
                            type="text"
                            as={MDInput}
                            label="Dirección (*)"
                            variant="standard"
                            fullWidth
                            error={errors.address && touched.address}
                            helperText={touched.address && errors.address}
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2}>
                          <Field
                            name="phoneNumber"
                            type="string"
                            as={MDInput}
                            label="Teléfono (*)"
                            variant="standard"
                            fullWidth
                            error={errors.phoneNumber && touched.phoneNumber}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2}>
                          <Field
                            name="email"
                            type="email"
                            as={MDInput}
                            label="Email (*)"
                            variant="standard"
                            fullWidth
                            error={errors.email && touched.email}
                            helperText={touched.email && errors.email}
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2}>
                          <Field
                            name="area"
                            type="text"
                            as={MDInput}
                            label="Área"
                            variant="standard"
                            fullWidth
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <MDBox mb={2}>
                          <Field
                            name="position"
                            type="text"
                            as={MDInput}
                            label="Cargo"
                            variant="standard"
                            fullWidth
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MDBox>
                      </Grid>
                      {typeForm === "registro" ? (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MDBox mb={2}>
                              <Field
                                name="userName"
                                type="text"
                                as={MDInput}
                                label="Usuario (*)"
                                variant="standard"
                                fullWidth
                                error={errors.userName && touched.userName}
                                helperText={touched.userName && errors.userName}
                                inputProps={{ autoComplete: "off" }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MDBox mb={2}>
                              <Field
                                name="password"
                                type="password"
                                as={MDInput}
                                label="Contraseña (*)"
                                variant="standard"
                                fullWidth
                                error={errors.password && touched.password}
                                helperText={touched.password && errors.password}
                                inputProps={{ autoComplete: "off" }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MDBox mb={2}>
                              <Field
                                name="confirmPassword"
                                type="password"
                                as={MDInput}
                                label="Confirmar contraseña (*)"
                                variant="standard"
                                fullWidth
                                error={errors.confirmPassword && touched.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                inputProps={{ autoComplete: "off" }}
                              />
                            </MDBox>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="subtitule2"> Firma:</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box sx={{ borderColor: "#00000", border: 1 }}>
                              <SignatureCanvas
                                ref={inputEl}
                                penColor="black"
                                canvasProps={{
                                  width: 420,
                                  height: 200,
                                  className: "sigCanvas",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item pt={1} xs={12} display="flex" justifyContent="center">
                            <FileUploadPage changeHandler={handleChangeImaSignature} />
                            <Button
                              mt={3}
                              size="small"
                              color="secondary"
                              onClick={() => inputEl.current.clear()}
                            >
                              Limpiar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <MDButton onClick={handleClose} variant="outlined" color="info">
                      Cancelar
                    </MDButton>
                    <MDButton type="submit" disabled={loading} variant="gradient" color="info">
                      {loading ? "Cargando..." : "Guardar"}
                    </MDButton>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          ) : (
            <CircularProgress sx={{ my: 3 }} color="secondary" />
          )}
        </MDBox>
      </Dialog>
    </div>
  );
}

DialogRegisterUser.defaultProps = {
  open: false,
  loading: false,
  idUserEdit: "",
  typeIdentification: "",
  setTypeIdentification: "",
};

DialogRegisterUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleRegisterUser: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  listIdentification: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)).isRequired,
  typeForm: PropTypes.string.isRequired,
  idUserEdit: PropTypes.string,
  setTypeIdentification: PropTypes.element,
  typeIdentification: PropTypes.string,
};

export default DialogRegisterUser;
