import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, CardActions, Tooltip } from "@mui/material";
import { useAlert } from "react-alert";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { httpClient } from "services/httpRequest";
import { getDocumentsCustomer, postRevision } from "../../../../store/generateService/actions";
import ViewPdf from "../../../../components/ViewPdf/index";
import MDButton from "../../../../components/MDButton";

function DialogDocument({ openDocument, handleCloseDocument, customerId }) {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [openViewPdf, setOpenViewPdf] = useState(false);
  const [pdfToPreview] = useState("");
  const [tittlePdf] = useState("");
  const [documentId] = useState("");
  const alert = useAlert();

  const handleCloseViewPdf = () => setOpenViewPdf(false);

  const consultarDocumentos = async () => {
    setDocuments([]);
    const data = await dispatch(getDocumentsCustomer(customerId));
    if (data.length > 0) {
      setDocuments(data);
    }
  };

  useEffect(() => {
    (async () => {
      consultarDocumentos();
    })();
  }, [customerId]);

  const reviseDocuments = async (idDocument, stateRevision) => {
    try {
      const data = {
        id: idDocument,
        status: stateRevision,
      };
      await dispatch(postRevision(idDocument, data));
      alert.success("Documento revisado con éxito");
      await consultarDocumentos();
    } catch (err) {
      alert.error("Error al momento de revisar el documento");
    }
  };

  const getColor = (status) => {
    if (status === "Pending") return "warning";
    if (status === "Approved") return "success";
    return "error";
  };
  const getStattus = (status) => {
    if (status === "Pending") return "Pendiente";
    if (status === "Approved") return "Aprobado";
    return "Rechazado";
  };

  const revisionDocument = async (id, status) => {
    try {
      const dataRequestRevision = {
        id,
        status,
      };
      await httpClient.put(`documents/${id}/revision`, dataRequestRevision);
      const newArray = documents.map((value) => (id === value.id ? { ...value, status } : value));
      setDocuments(newArray);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        open={openDocument}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDocument}
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }}>Lista de documentos</DialogTitle>
        {handleCloseDocument ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseDocument}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              {documents.length > 0 && openDocument === true && (
                <>
                  {documents.map((tDocument) => (
                    <Grid item xs={4}>
                      <Card>
                        <CardContent
                          sx={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            p: 0.5,
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignContent="center"
                              justifyContent="center"
                            >
                              <Tooltip title="Ver Documento">
                                <a
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                  without
                                  rel="noreferrer"
                                  target="_blank"
                                  href={tDocument.url}
                                >
                                  <Typography variant="caption" noWrap align="center">
                                    {tDocument.tDocument.name}
                                  </Typography>

                                  <RemoveRedEyeIcon sx={{ ml: 1 }} />
                                </a>
                              </Tooltip>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              py={1}
                              display="flex"
                              alignItems="center"
                              alignContent="center"
                              justifyContent="center"
                            >
                              <Typography variant="caption" sx={{ mr: 2 }}>
                                Estado:
                              </Typography>
                              <Chip
                                label={getStattus(tDocument.status)}
                                color={getColor(tDocument.status)}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions sx={{ p: 0.5, display: "flex", justifyContent: "center" }}>
                          {tDocument.status === "Pending" && (
                            <>
                              <MDButton
                                variant="contained"
                                color="success"
                                onClick={() => revisionDocument(tDocument.id, "Approved")}
                                size="small"
                              >
                                <Typography variant="caption">Aceptar</Typography>
                              </MDButton>
                              <MDButton
                                variant="contained"
                                color="error"
                                onClick={() => revisionDocument(tDocument.id, "Rejected")}
                                size="small"
                              >
                                <Typography variant="caption">Rechazar</Typography>
                              </MDButton>
                            </>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <ViewPdf
          open={openViewPdf}
          tittlePdf={tittlePdf}
          data={pdfToPreview}
          type={3}
          onClose={handleCloseViewPdf}
          revise={reviseDocuments}
          idDocument={documentId}
        />
      </Dialog>
    </div>
  );
}

DialogDocument.defaultProps = {
  openDocument: false,
};

DialogDocument.propTypes = {
  openDocument: PropTypes.bool,
  handleCloseDocument: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default DialogDocument;
