import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import { useAlert } from "react-alert";
import TableMui from "../../../../components/TableMui/TableMui";
import AlertConfirm from "../../../../components/AlertConfirm";
import {
  activeCertificate,
  disableCertificate,
} from "../../../../services/Certificate/CertificateServices";

function TableCertificates({
  listCertificateData,
  rowCount,
  page,
  pageSize,
  loading,
  changePage,
  changePageSize,
  getListCertificates,
  handleEdit,
  getDataSdvancedFilter,
  filters,
  updateFilters,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [infoState, setInfoState] = useState({});

  const alert = useAlert();
  const handleChangePage = (value) => changePage(value);

  const handlePageSize = (newPageSize) => changePageSize(newPageSize);

  const handleActionCertificate = async (active, id) => {
    try {
      let data = {};
      if (active) data = await disableCertificate(id);
      else data = await activeCertificate(id);
      if (typeof data !== "string") alert.error(`${data.Messages[0]}`, { position: "top right" });
      else alert.success("Estado cambiado correctamente", { position: "top right" });
    } catch (e) {
      alert.error(`${e.Messages[0]}`, { position: "top right" });
    }

    getListCertificates();
    setIsOpen(!isOpen);
  };

  const handleAlertStatus = (active, id) => {
    setInfoState({
      activateCertificate: active,
      idCertificate: id,
    });
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   debugger; // eslint-disable-line no-debugger
  //   console.log(listCertificateData);
  //   if (listCertificateData.length > 0) {
  //   }
  // }, [listCertificateData]);

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    { field: "code", headerName: "Código", width: 160, type: "string", fieldRef: "code" },
    {
      field: "name",
      headerName: "Nombre de plantilla",
      width: 600,
      type: "string",
      fieldRef: "name",
    },
    {
      field: "nDaysExpire",
      headerName: "# de días para expirar",
      width: 200,
      type: "number",
      fieldRef: "nDaysExpire",
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 200,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Tooltip title="Editar plantilla">
              <IconButton onClick={() => handleEdit(row.id)} aria-label="delete" size="large">
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {row.isActive ? "Activo" : "Inactivo"}
            <Switch
              checked={row.isActive}
              onChange={() => handleAlertStatus(row.isActive, row.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
        return template;
      },
    },
  ];

  return (
    <>
      <TableMui
        paginationMode="server"
        columns={columns}
        rows={listCertificateData}
        rowCount={rowCount}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
        onPageChange={handleChangePage}
        onPageSizeChange={handlePageSize}
        disableSelectionOnClick
        checkboxSelection={false}
        getDataSdvancedFilter={getDataSdvancedFilter}
        filters={filters}
        updateFilters={updateFilters}
        autoHeight
      />
      <AlertConfirm
        open={isOpen}
        title="!Atención¡"
        context={`¿Seguro de que desea ${
          infoState.activateCertificate ? "desactivar" : "activar"
        } esta plantilla?`}
        onClose={() => setIsOpen(!isOpen)}
        onAccept={() =>
          handleActionCertificate(infoState.activateCertificate, infoState.idCertificate)
        }
      />
    </>
  );
}

TableCertificates.defaultProps = {
  listCertificateData: [],
  getDataSdvancedFilter: () => {},
  filters: [],
  updateFilters: () => {},
};

TableCertificates.propTypes = {
  listCertificateData: PropTypes.arrayOf(PropTypes.string),
  rowCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getListCertificates: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(),
  updateFilters: PropTypes.func,
  getDataSdvancedFilter: PropTypes.func,
};

export default TableCertificates;
