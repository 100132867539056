import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TableMui from "components/TableMui/TableMui";
import StatusCell from "components/StatusCell/StatusCell";
import AlertConfirm from "components/AlertConfirm";
import MDButtonCustomByTenant from "components/MDButton/MDButtonCustomByTenant";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { useAlert } from "react-alert";
import { searchCredentialsActives } from "store/paymentCredentials/actions";
// import { openUrl } from "utils/utils";
import { getPaymentGateway } from "store/paymentGateway/actions";
import {
  getManualPayments,
  // changeStatusManualPayment,
} from "../../store/paymentsGenerated/actions";
import DialogToPayForAdmin from "./components/DialogToPayForAdmin";

function ManualPayments() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [listManualPaymnet, setListManualPaymnet] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenManual, setIsOpenManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [rowCount, setRowCount] = useState(5);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [selectionModel, setSelectionModel] = useState([]);
  const [totalToPay, setToPay] = useState(0);
  const [totalToPayMarketplace, setTotalToPayMarketplace] = useState(0);
  // const [selectedRow, setSelectedRow] = useState([]);
  const { tertiaryColor } = useSelector(({ company }) => company.tenantConfig);

  const credentials = useSelector(({ paymentCredentials }) => paymentCredentials.data);
  const paymentsGateway = useSelector(({ paymentGateway }) => paymentGateway.data);

  const getListManualPayment = useCallback(async () => {
    const dataSearch = {
      advancedFilter,
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
    };

    setLoading(true);
    const { data, totalCount } = await dispatch(getManualPayments(dataSearch));
    setListManualPaymnet(data);
    setRowCount(totalCount);
    setLoading(false);
  }, [pageSize, page, advancedFilter]);

  const handleOpenAlertConfirm = () => setIsOpen(!isOpen);
  const handleOpenManual = () => setIsOpenManual(!isOpenManual);

  const handleChangeStatusManualPayment = async (manualsPaymentsId) => {
    try {
      // await dispatch(changeStatusManualPayment(manualsPaymentsId));
      const selectedPayment = listManualPaymnet.find(
        (payment) => payment.id === manualsPaymentsId[0]
      );
      if (selectedPayment.percentageToCollect === 0) {
        const updatedPayments = listManualPaymnet.map((payment) =>
          manualsPaymentsId.includes(payment.id) ? { ...payment, isReceived: true } : payment
        );
        setListManualPaymnet(updatedPayments);
        alert.success("Se a recibido el pago correctamente", { position: "top right" });
      } else {
        alert.error("Error al recibir el pago", {
          position: "top right",
        });
      }
    } catch (e) {
      if (e.Messages) alert.error(e.Messages[0], { position: "top right" });
      else alert.error("Erro al actualizar pago manual", { position: "top right" });
    } finally {
      setIsOpenManual(false);
    }
  };

  const handleSelectRows = (newSelectionModel) => {
    let totalValueToPay = 0;
    let totalValueToPayMarketplace = 0;
    const newArray = newSelectionModel.filter(
      (item) => listManualPaymnet.find((payment) => payment.id === item).isReceived === false
    );

    for (let i = 0; i < listManualPaymnet.length; i += 1) {
      for (let x = 0; x < newArray.length; x += 1) {
        if (listManualPaymnet[i].id === newArray[x]) {
          totalValueToPay += listManualPaymnet[i].paymentOrder.valueToPay;
          totalValueToPayMarketplace += listManualPaymnet[i].valueToPayMarketplace;
        }
      }
    }
    setTotalToPayMarketplace(totalValueToPayMarketplace);
    setToPay(totalValueToPay);
    setSelectionModel(newArray);
  };

  const searchMyCredentials = async () => {
    const dataSearch = {
      pageNumber: 0,
      pageSize: 0,
      orderBy: [],
    };
    await dispatch(searchCredentialsActives(dataSearch));
  };
  const dispatchGetpaymentGateway = async () => {
    await dispatch(getPaymentGateway());
  };

  useEffect(() => {
    searchMyCredentials();
    dispatchGetpaymentGateway();
  }, []);

  useEffect(() => {
    getListManualPayment();
  }, [pageSize, page, advancedFilter]);

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    {
      field: "customer",
      headerName: "Cliente",
      type: "string",
      fieldRef: "paymentOrder.customer.name",
      renderCell: ({ row }) => row.paymentOrder.customer.name,
      width: 250,
    },
    {
      field: "totalPay",
      headerName: "Total Pagado",
      type: "number",
      fieldRef: "paymentOrder.valueToPay",
      align: "center",
      renderCell: ({ row }) => `$ ${row.paymentOrder.valueToPay.toLocaleString()}`,
      width: 200,
    },
    {
      field: "percentageToCollect",
      headerName: "% de comisión",
      type: "number",
      fieldRef: "percentageToCollect",
      renderCell: ({ row }) => `${row.percentageToCollect} %`,
      width: 130,
    },
    {
      field: "valueToPayMarketplace",
      headerName: "Valor a pagar",
      type: "number",
      fieldRef: "valueToPayMarketplace",
      align: "center",
      renderCell: ({ row }) => `$ ${row.valueToPayMarketplace.toLocaleString()}`,
      width: 130,
    },
    {
      field: "payTo",
      headerName: "Pagado por",
      type: "string",
      fieldRef: "deliveryUser.firstName",
      renderCell: ({ row }) => `${row.deliveryUser.firstName} ${row.deliveryUser.lastName}`,
      width: 200,
    },
    {
      field: "isReceived",
      headerName: "Estado",
      type: "boolean",
      fieldRef: "isReceived",
      renderCell: ({ value }) => {
        let status;
        if (value) {
          status = <StatusCell icon="done" color="success" status="Entregado" />;
        } else {
          status = <StatusCell icon="close" color="error" status="Sin entregar" />;
        }
        return status;
      },
      filterOptions: [
        {
          name: "Entregado",
          value: true,
        },
        {
          name: "Sin entregar",
          value: false,
        },
      ],
      width: 200,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" my={2}>
        <MDBox display="flex">
          <MDBox>
            <Tooltip key="CreatePayment" title="Marcada como pagado" placement="bottom">
              <MDButton
                onClick={
                  listManualPaymnet.percentageToCollect === 0
                    ? handleOpenManual
                    : handleOpenAlertConfirm
                }
                variant="gradient"
                color="info"
                disabled={!selectionModel.length}
                sx={() => MDButtonCustomByTenant(tertiaryColor)}
              >
                <CreditScoreIcon />
                Recesión de pagos
              </MDButton>
            </Tooltip>
          </MDBox>
        </MDBox>
      </MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            <strong>Valor total pagado: </strong>${totalToPay.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            <strong>Comisión total a pagar: </strong>${totalToPayMarketplace.toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <TableMui
          paginationMode="server"
          columns={columns}
          rows={listManualPaymnet}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={handleSelectRows}
          selectionModel={selectionModel}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          filters={filters}
          updateFilters={(newArrayFilters) => setFilters(newArrayFilters)}
          getDataSdvancedFilter={(filter) => {
            setAdvancedFilter(filter);
          }}
          autoHeight
        />
      </Card>
      <DialogToPayForAdmin
        open={isOpen}
        onClose={() => setIsOpen(false)}
        credentials={credentials}
        idsSelected={selectionModel}
        paymentsGateway={paymentsGateway}
        totalToPay={totalToPay}
        totalToPayMarketplace={totalToPayMarketplace}
      />
      {/* {false && ( */}
      <AlertConfirm
        open={isOpenManual}
        title="!Atención¡"
        context="¿Seguro de que desea cambiar a recibido estos pagos?"
        onClose={() => setIsOpenManual(!isOpenManual)}
        onAccept={() => handleChangeStatusManualPayment(selectionModel)}
      />
      {/* )} */}
    </DashboardLayout>
  );
}

export default ManualPayments;
