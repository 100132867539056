import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDButtonCustomByTenant from "components/MDButton/MDButtonCustomByTenant";
import httpRequest from "../../../services/httpRequest";
import TableAssociatedClients from "../components/TableAssociatedClients.js";
import DialogFullScreen from "../../../components/DialogFullScreen";
import excel from "../../../assets/Customer_Masive.xlsx";
// eslint-disable-next-line import/named
import {
  handleOpenDialogFullScreen,
  setGeometryLocations,
  setGeometryLocationsInitial,
} from "../../../store/map/actions";
import DialogCustomerDocument from "../components/DialogCustomerDocument";
import CreateOrder from "../../../components/CreateOrder";
// import ResetPassword from "../components/DialogResetPassword";

function ListClients() {
  const [listIdentification, setListIdentification] = useState([]);
  const [listClients, setListClients] = useState([]);
  const [rowCount, setRowCount] = useState(5);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listEconomicActivities, setListEconomicActivities] = useState([]);
  const [typeForm, setTypeForm] = useState("");
  const [dataClient, setDataClient] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectId, setSelectId] = useState(null);

  const openMapFullScreen = useSelector(({ map }) => map.openMapFullScreen);
  const { primaryColor, secondaryColor } = useSelector(({ company }) => company.tenantConfig);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(handleOpenDialogFullScreen(true));
    setTypeForm("registro");
  };

  function descargarExcel() {
    const nombreArchivo = "plantilla.xlsx";

    // Crear un enlace (link) a la ruta del archivo Excel
    const enlaceArchivo = document.createElement("a");
    enlaceArchivo.href = excel;
    enlaceArchivo.download = nombreArchivo;

    // Agregar el enlace a la página web y hacer clic en él
    document.body.appendChild(enlaceArchivo);
    enlaceArchivo.click();

    // Eliminar el enlace de la página
    document.body.removeChild(enlaceArchivo);
  }

  const handleCloseFullScreen = () => {
    dispatch(handleOpenDialogFullScreen(false));
    dispatch(setGeometryLocationsInitial(false));
  };

  const getTypeIdentification = useCallback(async () => {
    const responseTypeIdentiofi = await httpRequest.getEntries(`identificationtypes`);
    setListIdentification(responseTypeIdentiofi);
  }, []);

  const getEconomicActivities = useCallback(async () => {
    const responseEconomicActiv = await httpRequest.getEntries(`economicactivities`);
    setListEconomicActivities(responseEconomicActiv);
  }, []);

  const getAssociatedClients = useCallback(async () => {
    const dataSearch = {
      advancedFilter,
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
    };
    setLoading(true);
    const { data, totalCount } = await httpRequest.create(`customers/search`, dataSearch);
    setListClients(data);
    setRowCount(totalCount);
    setLoading(false);
  }, [pageSize, page, advancedFilter]);

  const handleEditClient = (idClientEdit) => {
    setTypeForm("editar");
    const dataClientFind = listClients.find((client) => client.id === idClientEdit);
    if (dataClientFind.geographicalCoordinates) {
      const coordinates = dataClientFind.geographicalCoordinates;
      const location = {
        lat: parseFloat(coordinates.latitude),
        lng: parseFloat(coordinates.longitude),
      };
      dispatch(setGeometryLocations(location, dataClientFind.address, 19));
    }
    setDataClient(dataClientFind);
    dispatch(handleOpenDialogFullScreen(true));
  };

  const handleClose = () => setOpen(false);

  const isNewClient = () => selectedRow.filter((e) => e.isAnonymous === true);

  const handlerGeneratorOrder = () => {
    if (isNewClient().length === 0) {
      setOpen(true);
    } else {
      alert.show(`Hay ${isNewClient().length} cliente no registrado`, {
        type: "warning",
        position: "top right",
      });
    }
  };

  const handleSelectRows = (newSelectionModel) => {
    // Check if selectionModel contains items
    if (newSelectionModel.length > 0) {
      // Find the selected rows based on the newSelectionModel
      const datSelected = listClients.filter((e) => newSelectionModel.includes(e.id));
      setSelectedRow(datSelected);
    } else {
      setSelectedRow([]);
    }

    setSelectionModel(newSelectionModel);
  };

  useEffect(() => {
    const isRefresh = JSON.parse(localStorage.getItem("isRefresh"));
    if (isRefresh) {
      window.location.reload();
      localStorage.setItem("isRefresh", false);
    } else {
      localStorage.setItem("isRefresh", false);
    }
  }, []);

  const editRiskLevel = (riskLevel) => {
    if (riskLevel === "LEVEL1") return "NIVEL 1";
    if (riskLevel === "LEVEL2") return "NIVEL 2";
    if (riskLevel === "LEVEL3") return "NIVEL 3";
    if (riskLevel === "LEVEL4") return "NIVEL 4";
    if (riskLevel === "LEVEL5") return "NIVEL 5";
    if (riskLevel === "LEVEL6") return "NIVEL 6";
    return "";
  };

  useEffect(() => {
    if (listClients.length > 0) {
      listClients.forEach((e) => {
        e.customerDetail.riskLevel = editRiskLevel(e.customerDetail.riskLevel);
      });
    }
  }, [listClients]);

  useEffect(() => {
    (async () => {
      await getTypeIdentification();
      await getEconomicActivities();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getAssociatedClients();
    })();
  }, [dispatch, pageSize, page, advancedFilter]);

  useEffect(() => {
    if (selectedRow.length > 0) {
      setSelectId(selectedRow[0].id);
    }
  }, [selectedRow]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            item
            xs={12}
            md={12}
          >
            <MDBox display="flex">
              <MDBox ml={1}>
                <MDButton
                  onClick={handleClickOpen}
                  variant="gradient"
                  color="info"
                  sx={() => MDButtonCustomByTenant(primaryColor, secondaryColor)}
                >
                  Crear Clientes
                </MDButton>
              </MDBox>
              <MDBox ml={1}>
                <MDButton
                  onClick={handlerGeneratorOrder}
                  variant="gradient"
                  color="info"
                  // disabled={!selectedRow.length}
                  disabled
                  sx={() => MDButtonCustomByTenant(primaryColor, secondaryColor)}
                >
                  Crear Ordenes masivas
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDButton
                onClick={() => descargarExcel()}
                variant="gradient"
                color="info"
                sx={() => MDButtonCustomByTenant(primaryColor, secondaryColor)}
                style={{ marginRight: "10px" }}
              >
                Descargar plantilla
              </MDButton>
              <MDButton
                onClick={() => setOpenModal(true)}
                variant="gradient"
                color="info"
                sx={() => MDButtonCustomByTenant(primaryColor, secondaryColor)}
              >
                Creacion Masiva de Clientes
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <TableAssociatedClients
                listClients={listClients}
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                loading={loading}
                getAssociatedClients={getAssociatedClients}
                handleEditClient={handleEditClient}
                handleSelectRows={handleSelectRows}
                checkboxSelection
                selectionModel={selectionModel}
                changePage={setPage}
                changePageSize={setPageSize}
                filters={filters}
                updateFilters={(newArrayFilters) => setFilters(newArrayFilters)}
                getDataSdvancedFilter={(filter) => {
                  setAdvancedFilter(filter);
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
      <DialogFullScreen
        open={openMapFullScreen}
        onClose={handleCloseFullScreen}
        listIdentification={listIdentification}
        listEconomicActivities={listEconomicActivities}
        typeForm={typeForm}
        dataClient={dataClient}
        get={getAssociatedClients}
      />
      {/* <ResetPassword open onClose dataReset={dataReset} tenant /> */}
      <CreateOrder
        open={open}
        onClose={handleClose}
        idSolicitud={null}
        idUser={selectedRow.length ? selectId : null}
      />
      <DialogCustomerDocument open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
}

export default ListClients;
