import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import DialogRegisterDocument from "./components/DialogRegisterDocument";
import TableDocuments from "./components/TableDocuments";
import AlertConfirm from "../../components/AlertConfirm";
import {
  getTipeDocuments,
  changeStatus,
  deleteTipeDocuments,
  saveTypeDocument,
  UpdateTypeDocument,
} from "../../store/tipeDocuments/actions";

function TipeDocuments() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [active, setActive] = useState(true);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [requered, setRequered] = useState(false);
  const [expiration, setExpiration] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [idDelete, setIdDelete] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [dataEdit, setDataEdit] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const listTypeDocument = useSelector(({ typeDocument }) => typeDocument.dataTypeDocument);
  const rowCount = useSelector(({ typeDocument }) => typeDocument.totalCount);

  const handleOpenDialogDocument = () => {
    setIsEdit(false);
    setOpen(true);
  };

  const handleCloseDocument = () => {
    setOpen(false);
  };

  const handleIsActive = () => {
    setActive(!active);
  };

  const handleIsRequered = () => {
    setRequered(!requered);
  };

  const handleExpiration = () => {
    setExpiration(!expiration);
  };

  const getListTipeDocuments = useCallback(async () => {
    const dataSearch = {
      advancedFilter,
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
    };
    setLoading(true);
    await dispatch(getTipeDocuments(dataSearch));
    setLoading(false);
  }, [pageSize, page, advancedFilter]);

  useEffect(() => {
    getListTipeDocuments();
  }, [pageSize, page, advancedFilter]);

  const handleChangeStatus = async (id) => {
    await dispatch(changeStatus(id));
    getListTipeDocuments();
  };

  const handleAlertStatus = (id) => {
    setIdDelete(id);
    setIsOpen(!isOpen);
  };

  const handleDeleteTDocument = async (id) => {
    await dispatch(deleteTipeDocuments(id));
    setIsOpen(false);
    getListTipeDocuments();
  };

  const handleSave = async (data) => {
    const dataTDocument = {
      id: isEdit ? dataEdit.id : "",
      code: data.code,
      name: data.name,
      isActive: active,
      isRequired: requered,
      hasExpirationDate: expiration,
      nDays: !expiration ? 0 : data.nDays,
    };

    if (isEdit) {
      try {
        await dispatch(UpdateTypeDocument(dataTDocument, dataEdit.id));
        alert.show("Tipo de documento actualizado", {
          position: "top right",
        });
        getListTipeDocuments();
        setOpen(false);
      } catch (error) {
        alert.error("Error al actualizar el tipo de documento", {
          position: "top right",
        });
      }
    } else {
      try {
        await dispatch(saveTypeDocument(dataTDocument));
        alert.show("Tipo de documento creado", {
          position: "top right",
        });
        getListTipeDocuments();
        setOpen(false);
      } catch (error) {
        alert.error("Error al crear el tipo de documento", {
          position: "top right",
        });
      }
    }
  };

  const handleEditTypeDocument = (id) => {
    setOpen(true);
    setIsEdit(true);
    const dataEditDocument = listTypeDocument.find((list) => list.id === id);
    setDataEdit(dataEditDocument);
    setActive(dataEditDocument.isActive);
    setRequered(dataEditDocument.isRequired);
    setExpiration(dataEditDocument.hasExpirationDate);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ textAlign: "right" }}
          >
            <MDButton variant="gradient" onClick={handleOpenDialogDocument} color="info">
              Crear documento
            </MDButton>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <TableDocuments
                listTypeDocument={listTypeDocument}
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                loading={loading}
                changePage={(value) => setPage(value)}
                changePageSize={(value) => setPageSize(value)}
                getListTipeDocuments={getListTipeDocuments}
                filters={filters}
                updateFilters={(newArrayFilters) => setFilters(newArrayFilters)}
                getDataSdvancedFilter={(filter) => {
                  setAdvancedFilter(filter);
                }}
                handleChangeStatus={handleChangeStatus}
                handleAlertStatus={handleAlertStatus}
                handleEditTypeDocument={handleEditTypeDocument}
              />
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
      <DialogRegisterDocument
        open={open}
        handleCloseDocument={handleCloseDocument}
        handleIsActive={handleIsActive}
        active={active}
        requered={requered}
        handleIsRequered={handleIsRequered}
        handleExpiration={handleExpiration}
        expiration={expiration}
        handleSave={handleSave}
        isEdit={isEdit}
        dataEdit={dataEdit}
      />
      <AlertConfirm
        open={isOpen}
        title="!Atención¡"
        context="¿Seguro de que desea eliminar este tipo de documento?"
        onClose={() => setIsOpen(!isOpen)}
        onAccept={() => handleDeleteTDocument(idDelete)}
      />
    </>
  );
}

export default TipeDocuments;
