const MDButtonCustomByTenant = (color1, color2) => ({
  color: `${color2} !important`,
  background: color1,
  "&:hover": {
    background: color1,
    color: `${color2} !important`,
  },
  "&:disabled": {
    background: color1,
    color: `${color2} !important`,
  },
});
export default MDButtonCustomByTenant;
