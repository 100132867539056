/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from "axios";
import { Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { useState, useMemo, useEffect } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "@reach/router";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Images

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";

import SectionTwo from "../../../image/SectionTwo.png";
import logo from "../../../assets/images/logos/logo.svg";

// Service
import { resetPassword } from "../../../store/user/actions";
import { getDetailTenantById } from "../../../store/tenant/actions";
import { ENDPOINT } from "../../../services/httpRequest";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Utilizar formato ejemplo@email.com"),
  tenant: Yup.string().required("Campo requerido"),
  password: Yup.string()
    .required("Campo requerido")
    .min(8, "El campo contraseña debe tener mínimo 8 digitos.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "El campo contraseña debe tener mínimo un número, mínimo una letra minúscula y mínimo una mayúscula."
    ),
});

const initialState = {
  email: "",
  tenant: "",
};

function PasswordReset() {
  const dispatch = useDispatch();
  const urlTenan = window.location;
  const [loading, setLoading] = useState(false);
  const [emailRecover, setEmailRecover] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [tokenUser, setToken] = useState(null);
  const [tenant, setValueTenan] = useState("root");
  const [listTenans, setListTenans] = useState([]);
  const [isProcessComplete, setIsProcessComplete] = useState(false);
  const alert = useAlert();

  // const handleChangeEmail = (event) => {
  //   console.log(event);
  //   setEmail(event.target.value);
  // };

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const handleChange = (event) => {
    setValueTenan(event.target.value);
  };

  const query = useQuery();
  const [queryItem] = useState(query.get("Token"));

  const getListTenans = async () => {
    try {
      const response = await axios.get(`${ENDPOINT}/tenants/basictenantinfo`);
      setListTenans(response.data);
    } catch (error) {
      alert.error("Error al obtener empresas", { position: "top right" });
    }
  };

  // useEffect(() => {
  //   if (urlTenan.hostname !== "localhost") {
  //     const tenanAuto = urlTenan.hostname.split(".localhost", 1);
  //     setValueTenan(tenanAuto);
  //   } else {
  //     getListTenans();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (urlTenan.hostname !== "www.aidcol.co") {
  //     const tenanAuto = urlTenan.hostname.split(".aidcol.co", 1);
  //     setValueTenan(tenanAuto);
  //   } else {
  //     getListTenans();
  //   }
  // }, []);

  useEffect(() => {
    if (urlTenan.hostname !== "www.aidcol.com") {
      const tenanAuto = urlTenan.hostname.split(".aidcol.com", 1);
      setValueTenan(tenanAuto);
    } else {
      getListTenans();
    }
  }, []);

  useEffect(async () => {
    if (urlTenan.hostname !== "www.aidcol.com") {
      const tenanAuto = urlTenan.hostname.split(".");
      setValueTenan(tenanAuto[0]);
    } else {
      await getListTenans();
    }
  }, []);

  useEffect(() => {
    if (queryItem !== null) {
      setToken(queryItem);
    }
  }, [queryItem]);

  const handleResetPassword = async () => {
    const data = {
      email: emailRecover,
      password: newPassword,
      token: tokenUser,
    };
    try {
      setLoading(true);
      await dispatch(getDetailTenantById(tenant));
      await dispatch(resetPassword(tenant, data));
      alert.success("Contraseña cambiada correctamente", { position: "top right" });
      setLoading(false);
      setIsProcessComplete(true);
    } catch (e) {
      alert.error(`${e.Messages[0]}`, {
        position: "top right",
      });
      setLoading(false);
    }
  };

  return (
    <PageLayout background="white">
      <MDBox
        width="100vw"
        height="100%"
        sx={{
          backgroundImage: `url(${SectionTwo})`,
          backgroundRepeat: "no-repeat",
          minHeight: "100vH",
          backgroundPositionX: "right",
        }}
      >
        <MDBox component="img" src={logo} width="15%" height="15%" style={{ marginLeft: "40%" }} />
        <MDBox pt={4} pb={3} px={1} ml={30} role="form" style={{ width: "60%" }}>
          <MDTypography variant="h5" textAlign="center" fontWeight="medium" color="#1B4E7C" mt={1}>
            Recuperar contraseña
          </MDTypography>
          <Formik initialValues={initialState} validationSchema={validationSchema}>
            {({ errors, touched }) => (
              <Form>
                <MDBox mb={4}>
                  <Field
                    name="email"
                    type="email"
                    as={TextField}
                    variant="standard"
                    label="Email"
                    onChange={(value) => {
                      setEmailRecover(value.target.value);
                    }}
                    value={emailRecover}
                    fullWidth
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                    inputProps={{ autoComplete: "off" }}
                  />
                </MDBox>
                <MDBox mb={4}>
                  <Field
                    name="password"
                    as={TextField}
                    variant="standard"
                    label="Contraseña"
                    type="password"
                    onChange={(value) => {
                      setNewPassword(value.target.value);
                    }}
                    value={newPassword}
                    fullWidth
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                    inputProps={{ autoComplete: "off" }}
                  />
                </MDBox>
                <MDBox mb={4}>
                  <Field
                    name="Token"
                    type="Token"
                    as={TextField}
                    variant="standard"
                    label="Token"
                    onChange={(value) => {
                      setToken(value.target.value);
                    }}
                    value={tokenUser}
                    fullWidth
                    disabled={queryItem}
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                    inputProps={{ autoComplete: "off" }}
                  />
                </MDBox>
                {listTenans.length > 0 && (
                  <MDBox mb={4}>
                    <TextField
                      select
                      label="Seleccionar empresa"
                      onChange={handleChange}
                      fullWidth
                      value={tenant}
                      variant="standard"
                      id="outlined-size-normal"
                    >
                      {listTenans.map((tenan) => (
                        <MenuItem key={tenan.id} value={tenan.id}>
                          {tenan.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </MDBox>
                )}
                {loading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <CircularProgress color="secondary" />
                    </Grid>
                  </Grid>
                ) : (
                  <MDBox mt={6} mb={1}>
                    <MDButton
                      onClick={handleResetPassword}
                      type="submit"
                      variant="gradient"
                      style={{ backgroundColor: "#96BE1F", color: "#3C3C3B" }}
                      fullWidth
                    >
                      Enviar
                    </MDButton>
                  </MDBox>
                )}
              </Form>
            )}
          </Formik>
        </MDBox>
      </MDBox>
      {isProcessComplete && <Navigate to="/inicio" />}
    </PageLayout>
  );
}

export default PasswordReset;
