import { SET_DATA_CREDENTIALS, SET_ID_CREDENTIAL } from "./types";
import { RESET_STATES } from "../allStorages/types";

export const CREDENTIAL_INITIAL_STATE = {
  dataCredential: [],
  totalCount: 5,
  idCredential: "",
};

export const credential = (state = CREDENTIAL_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DATA_CREDENTIALS:
      return {
        ...state,
        dataCredential: action.payload.data,
        totalCount: action.payload.totalCount,
      };
    case SET_ID_CREDENTIAL:
      return {
        ...state,
        idCredential: action.payload.data,
      };
    case RESET_STATES:
      return CREDENTIAL_INITIAL_STATE;
    default:
      return state;
  }
};
