import httpRequest from "../httpRequest";

// eslint-disable-next-line import/prefer-default-export
export const getListTipeDocumentsService = async (filters) => {
  try {
    const { data } = await httpRequest.post("tdocuments/search", filters);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const changeStatusService = async (id) => {
  try {
    const { data } = await httpRequest.put(`tdocuments/${id}/togglestatus`, id);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const deleteTipeDocumentsService = async (id) => {
  try {
    const { data } = await httpRequest.remove(`tdocuments/${id}`, "");
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const saveTypeDocumentService = async (dataDocument) => {
  try {
    const { data } = await httpRequest.post(`tdocuments/create`, dataDocument);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const UpdateTypeDocumentService = async (dataDocumentUpdate, id) => {
  try {
    const { data } = await httpRequest.update(`tdocuments/${id}`, "", dataDocumentUpdate);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};
