import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import CardHeader from "@mui/material/CardHeader";
import { ResponsivePie } from "@nivo/pie";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useAlert } from "react-alert";
import TextField from "@mui/material/TextField";
import MDTypography from "../../../components/MDTypography";
import {
  generateHistoryPaymentsReport,
  generateHistoryPaymentsExcelReport,
  generateHistoryPaymentsPdfReport,
} from "../../../services/report/ReportService";

const validationSchema = Yup.object().shape({
  startDate: Yup.string().required("Campo requerido"),
  finalDate: Yup.string().required("Campo requerido"),
});

const initialState = {
  startDate: "",
  finalDate: "",
};

const options = ["Pagado", "No pagado"];

function PaymentsReport() {
  const [menu, setMenu] = useState(null);
  const [dataPayments, setDataPayments] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dataReport, setDataReport] = useState(null);
  const [dataChart, setDataChart] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const alert = useAlert();

  const closeMenu = () => setMenu(null);

  const openMenu = (event) => setMenu(event.currentTarget);

  const handleRemoveFilter = () => {
    setSelectedIndex(null);
    setMenu(null);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setMenu(null);
  };

  const pieData = (data) => {
    const DataLine = [];
    let cont = 0;
    data.forEach((e) => {
      const dataG = {
        value: e?.valueToPay,
        color: "hsl(288, 70%, 50%)",
        id: e.serviceName !== null ? `${e.serviceName[0]}${e.serviceName[1]}${cont}` : `SS${cont}`,
      };
      DataLine.push(dataG);
      cont += 1;
    });
    setDataChart(DataLine);
  };

  useEffect(() => {
    if (dataReport !== null) {
      let dataFilter = [];
      if (selectedIndex === 0) dataFilter = dataReport.filter((data) => data.isPaid === true);
      if (selectedIndex === 1) dataFilter = dataReport.filter((data) => data.isPaid === false);
      if (!selectedIndex) dataFilter = dataReport;
      pieData(dataFilter);
    }
  }, [selectedIndex]);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {options.map((option, index) => (
        <MenuItem
          key={option}
          selected={index === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, index)}
        >
          {option}
        </MenuItem>
      ))}
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={handleRemoveFilter}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remover Filtro
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    setDataPayments([]);
  }, [dataChart]);

  const handleSubmit = async (value) => {
    try {
      setStartDate(value.startDate);
      setFinalDate(value.finalDate);
      const data = await generateHistoryPaymentsReport(value.startDate, value.finalDate);
      setDataReport(data);
      pieData(data);
    } catch (err) {
      alert.error("Error al momento de consultar");
    }
  };

  const handleDownloadExcel = async () => {
    const data = {
      fechaInicio: startDate,
      fechaFinal: finalDate,
    };
    const blob = await generateHistoryPaymentsExcelReport(data);
    const bloobUrl = window.URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = bloobUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadPDf = async () => {
    const blob = await generateHistoryPaymentsPdfReport();
    const bloobUrl = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = bloobUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {dataPayments !== null ? (
          <>
            <Grid>
              <Card>
                <CardHeader title="Reporte de Pagos" />
                <Grid item xs={12} mb={1}>
                  <Formik
                    initialValues={initialState}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              type="date"
                              variant="standard"
                              fullWidth
                              name="startDate"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Field
                              as={TextField}
                              type="date"
                              variant="standard"
                              fullWidth
                              name="finalDate"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <MDBox>
                              <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                                Buscar
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </Formik>
                </Grid>
              </Card>
            </Grid>
            {dataChart !== null && (
              <>
                <Grid container mt={2}>
                  <Grid item xs>
                    <MDBox display="flex">
                      <MDButton
                        variant={menu ? "contained" : "outlined"}
                        color="dark"
                        onClick={openMenu}
                      >
                        filtros&nbsp;
                        <Icon>keyboard_arrow_down</Icon>
                      </MDButton>
                      {renderMenu}
                      &nbsp;&nbsp;
                    </MDBox>
                  </Grid>
                  <Grid item xs style={{ marginLeft: "60%" }}>
                    <MDBox>
                      <MDButton
                        type="submit"
                        onClick={handleDownloadPDf}
                        variant="gradient"
                        color="dark"
                      >
                        PDF
                      </MDButton>
                      <MDButton
                        type="submit"
                        sx={{ marginLeft: "5%" }}
                        variant="gradient"
                        color="dark"
                        onClick={handleDownloadExcel}
                      >
                        EXCEL
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <MDBox>
                      <Card>
                        <CardHeader title="Pagos" />
                        <Grid container>
                          <Grid item xs={12} ml={43} mt={-2} sm={4} style={{ height: "300px" }}>
                            <ResponsivePie
                              data={dataChart}
                              innerRadius={0.5}
                              padAngle={0.7}
                              cornerRadius={3}
                              activeOuterRadiusOffset={8}
                              borderWidth={1}
                              enableArcLinkLabels={false}
                              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                              arcLinkLabelsSkipAngle={10}
                              arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsThickness={2}
                              arcLinkLabelsColor={{ from: "color" }}
                              arcLabelsSkipAngle={10}
                              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </MDBox>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </DashboardLayout>
    </>
  );
}

export default PaymentsReport;
