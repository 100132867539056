const HelpComponentRoot = () => ({
  position: "fixed",
  bottom: "30px",
  right: "30px",
  opacity: 0.3,
  transition: "all 0.5s ease-out",
  "&:hover": {
    opacity: 1,
  },
});

export default HelpComponentRoot;
