/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import axios from "axios";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Images

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import SectionTwo from "../../../../image/SectionTwo.png";
import logo from "../../../../assets/images/logos/logo.svg";

// Service
import { forgotPassword } from "../../../../store/user/actions";
import { ENDPOINT } from "../../../../services/httpRequest";
import { getDetailTenantById } from "../../../../store/tenant/actions";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Utilizar formato ejemplo@email.com"),
  tenant: Yup.string().required("Campo requerido"),
});

const initialState = {
  email: "",
  tenant: "",
};

function Cover() {
  const dispatch = useDispatch();
  const urlTenan = window.location;
  const [loading, setLoading] = useState(false);
  const [tenant, setValueTenan] = useState("root");
  const [listTenans, setListTenans] = useState([]);
  const [emailRecover, setEmailRecover] = useState("");
  const alert = useAlert();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValueTenan(event.target.value);
  };

  const getListTenans = async () => {
    try {
      debugger; // eslint-disable-line no-debugger
      /* eslint-disable no-console */
      const response = await axios.get(`${ENDPOINT}/tenants/basictenantinfo`);
      /* eslint-disable no-console */
      setListTenans(response.data);
    } catch (error) {
      alert.error("Error al obtener empresas", { position: "top right" });
    }
  };

  // useEffect(() => {
  //   if (urlTenan.hostname !== "localhost") {
  //     const tenanAuto = urlTenan.hostname.split(".localhost", 1);
  //     setValueTenan(tenanAuto);
  //   } else {
  //     getListTenans();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (urlTenan.hostname !== "www.aidcol.co") {
  //     const tenanAuto = urlTenan.hostname.split(".aidcol.co", 1);
  //     setValueTenan(tenanAuto);
  //   } else {
  //     getListTenans();
  //   }
  // }, []);

  useEffect(() => {
    if (urlTenan.hostname !== "www.aidcol.com") {
      const tenanAuto = urlTenan.hostname.split(".aidcol.com", 1);
      setValueTenan(tenanAuto);
    } else {
      getListTenans();
    }
  }, []);

  useEffect(async () => {
    if (urlTenan.hostname !== "www.aidcol.com") {
      const tenanAuto = urlTenan.hostname.split(".");
      setValueTenan(tenanAuto[0]);
    } else {
      await getListTenans();
    }
  }, []);

  const handleResetPassword = async () => {
    const data = {
      email: emailRecover,
    };
    try {
      setLoading(true);
      await dispatch(getDetailTenantById(tenant));
      await dispatch(forgotPassword(tenant, data));
      alert.show(
        "El correo de restablecimiento de contraseña se ha enviado a su correo electrónico.",
        { position: "top right" }
      );
      setLoading(false);
      navigate("/authentication/reset-password");
    } catch (e) {
      alert.error(`${e.Messages[0]}`, {
        position: "top right",
      });
      setLoading(false);
    }
  };

  return (
    <PageLayout background="white">
      <MDBox
        width="100vw"
        height="100%"
        sx={{
          backgroundImage: `url(${SectionTwo})`,
          backgroundRepeat: "no-repeat",
          minHeight: "100vH",
          backgroundPositionX: "right",
        }}
      >
        <MDBox component="img" src={logo} width="15%" height="15%" style={{ marginLeft: "40%" }} />
        <MDBox pt={4} pb={3} px={1} ml={30} role="form" style={{ width: "60%" }}>
          <MDTypography variant="h5" textAlign="center" fontWeight="medium" color="#1B4E7C" mt={1}>
            Recuperar contraseña
          </MDTypography>
          <Formik initialValues={initialState} validationSchema={validationSchema}>
            {({ errors, touched }) => (
              <Form>
                <MDBox mb={4}>
                  <Field
                    name="email"
                    type="email"
                    as={TextField}
                    required
                    variant="standard"
                    label="Email"
                    onChange={(value) => {
                      setEmailRecover(value.target.value);
                    }}
                    value={emailRecover}
                    fullWidth
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                    inputProps={{ autoComplete: "off" }}
                  />
                </MDBox>
                {listTenans.length > 0 && (
                  <MDBox mb={4}>
                    <TextField
                      select
                      label="Seleccionar empresa"
                      onChange={handleChange}
                      fullWidth
                      value={tenant}
                      variant="standard"
                      id="outlined-size-normal"
                      required
                    >
                      {listTenans.map((tenan) => (
                        <MenuItem key={tenan.id} value={tenan.id}>
                          {tenan.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </MDBox>
                )}
                {loading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <CircularProgress color="secondary" />
                    </Grid>
                  </Grid>
                ) : (
                  <MDBox mt={6} mb={1}>
                    <MDButton
                      onClick={handleResetPassword}
                      type="submit"
                      variant="gradient"
                      disabled={emailRecover === ""}
                      style={{ backgroundColor: "#96BE1F", color: "#3C3C3B" }}
                      fullWidth
                    >
                      Enviar
                    </MDButton>
                  </MDBox>
                )}
              </Form>
            )}
          </Formik>
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

export default Cover;
