import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Field, Form, Formik } from "formik";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { useAlert } from "react-alert";
import * as Yup from "yup";
import { getSubscriptionInfoTenant, updateSubscription } from "../../../store/tenant/actions";

function DialogRenewSubscription({ open, onClose, idTenant }) {
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();

  const validationSchema = Yup.object().shape({
    percentageToCollect: Yup.number().required("Campo requerido"),
  });

  const handleSubmit = async (value) => {
    try {
      const data = {
        tenantId: idTenant,
        extendedExpiryDate: value.subscription_endDate,
        percentageToCollect: value.percentageToCollect,
      };
      await dispatch(updateSubscription(data));
      onClose();
      alert.success("Subscripción actualizada correctamente", { position: "top right" });
    } catch (e) {
      alert.error(`${e.Messages[0]}`, {
        position: "top right",
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (open === true) {
          const data = await dispatch(getSubscriptionInfoTenant(idTenant));
          setSubscriptionInfo(data);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    })();
  }, [open]);

  useEffect(() => {
    if (subscriptionInfo !== null && subscriptionInfo !== undefined) {
      const initial = {
        subscription_startDate: subscriptionInfo?.startDate,
        subscription_endDate: subscriptionInfo?.endDate,
        percentageToCollect: subscriptionInfo?.percentageToCollect,
      };
      setInitialState(initial);
    }
  }, [subscriptionInfo]);

  return (
    <>
      <Dialog
        aria-labelledby="responsive-dialog-title"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        style={{ height: "700px" }}
      >
        <DialogTitle>Renovar suscripción tenant</DialogTitle>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {subscriptionInfo !== null && initialState !== null ? (
          <DialogContent style={{ marginTop: -10 }} width="90%" height="90%">
            <Formik
              validationSchema={validationSchema}
              initialValues={initialState}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label="Fecha de inicio"
                        type="datetime-local"
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        fullWidth
                        name="subscription_startDate"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label="Fecha de fin"
                        type="datetime-local"
                        variant="standard"
                        fullWidth
                        name="subscription_endDate"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        label="Porcentaje de comisión"
                        variant="standard"
                        type="number"
                        fullWidth
                        name="percentageToCollect"
                        error={errors.percentageToCollect && touched.percentageToCollect}
                      />
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginLeft: "80%" }}>
                    <MDBox mt={4} mb={1} pr={1}>
                      <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                        Renovar
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
}

DialogRenewSubscription.defaultProps = {
  idTenant: "",
};

DialogRenewSubscription.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  idTenant: PropTypes.string,
};

export default DialogRenewSubscription;
