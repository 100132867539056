import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useAlert } from "react-alert";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Button,
  Select,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
// import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Field, Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import { getPersonalProfile } from "../../../services/Personal/PersonalService";
import { ENDPOINT } from "../../../services/httpRequest";

function CompleteBillInformation({ open, onClose, callBack, paymentOrderId, payment }) {
  //   const alert = useAlert();
  //   const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [activitiesEconomic, setActivitiesEconomic] = useState([]);
  const [globalRegions, setGlobalRegions] = useState(null);
  const [globalCities, setGlobalCities] = useState([]);
  const [globalResponsabilities, setGlobalResponsabilities] = useState(null);
  const [globalResponsabilitiesFor, setGlobalResponsabilitiesFor] = useState(null);
  const [personName, setPersonName] = React.useState([]);
  const [paymentMeans, setPaymentMeans] = useState([]);
  const [personTypes, setPersonTypes] = useState([]);
  const [regimenTypes, setRegimenTypes] = useState([]);
  const [typesIdentifications, setTypesIdentifications] = useState([]);
  const [paymenType, setPaymentType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [personType, setPersonType] = useState("");
  const [regimeType, setRegimeType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationTypeCode, setIdentificationTypeCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [merchantRegistration, setMerchantRegistration] = useState("");
  const [responsibleFor, setResponsibleFor] = useState("");
  const [responsibilities, setResponsabilities] = useState("");
  const [defaultEconomic] = useState("161;162;164");
  // const [economicActivities, setEconomicActivities] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [billingRegionCode, setBillingRegionCode] = useState("");
  const [billingCityCode, setBillingCityCode] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingNeighborhood, setBillingNeighborhood] = useState("");
  const [billingContactName, setBillingContactName] = useState("");
  const [dataPaymentOrder, setDataPaymentOrder] = useState(undefined);
  const [showDigitCheck, setShowDigitCheck] = useState(false);
  const [showDigitCheckIdentification, setShowDigitCheckIdentification] = useState(false);
  const [digitCheck, setDigitCheck] = useState("");
  const initialValues = {
    description: "",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Campo requerido"),
  });

  const handleChangeMs = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (identificationTypeCode?.name === "NIT") {
      setShowDigitCheckIdentification(true);
    } else {
      setShowDigitCheckIdentification(false);
    }
  }, [identificationTypeCode]);

  const resetFields = () => {
    setCompanyName("");
    setFirstName("");
    setLastName("");
    setIdentification("");
    setEmail("");
    setPhone("");
    setMerchantRegistration("");
    setResponsibleFor("");
    setBillingAddress("");
    setBillingPhone("");
    setBillingRegionCode("");
    setBillingCityCode("");
    setBillingPostalCode("");
    setBillingNeighborhood("");
    setBillingContactName("");
    setIdentificationTypeCode("");
    setResponsabilities("");
    setRegimeType("");
    setPersonType("");
  };

  const closeModal = () => {
    resetFields();
    onClose();
  };

  const endCompleteData = async () => {
    const dataRquest = {
      paymentOrderId,
      paymentMeanCode: "10",
      billingInformation: {
        companyName,
        personType: personType.code,
        regimeType: regimeType.code,
        firstName,
        lastName,
        identification,
        digitCheck: !showDigitCheck ? "1" : digitCheck,
        identificationTypeCode: identificationTypeCode.code,
        email,
        phone,
        merchantRegistration,
        responsibleFor: responsibleFor.code,
        responsibilities: responsibilities.code,
        economicActivities: defaultEconomic,
        billingAddress,
        billingCountryName: "Colombia",
        billingCountryCode: "CO",
        billingRegionName: "Bogotá",
        billingRegionCode: billingRegionCode.code,
        billingCityName: "Bogotá, D.c.",
        billingCityCode: billingCityCode.code,
        billingPostalCode,
        billingNeighborhood,
        billingPhone,
        billingContactName,
      },
    };
    const isToken = localStorage.getItem("token");
    await axios.post(`${ENDPOINT}/electronicinvoicedocument/generate`, dataRquest, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    closeModal();
    callBack();
  };

  const getPaymentsMeans = async () => {
    const tenantId = localStorage.getItem("idTenant");
    const data = await getPersonalProfile();
    initialValues.identification = data?.userProfile.nuip;
    setTenantData(data);
    // eslint-disable-next-line no-console
    console.log(tenantData);
    const responsePaymentMeans = await axios.get(`${ENDPOINT}/paymentmeans`, {
      headers: { tenantId },
    });
    const isToken = localStorage.getItem("token");
    const personTypesC = await axios.get(`${ENDPOINT}/billinginformations/persontype`, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    const regimenType = await axios.get(`${ENDPOINT}/billinginformations/regimeType`, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    const responsabilitiesFor = await axios.get(`${ENDPOINT}/responsiblefor`, {
      headers: { tenantId },
    });
    const economicActivities = await axios.get(`${ENDPOINT}/economicactivities`, {
      headers: { tenantId },
    });
    const regions = await axios.get(`${ENDPOINT}/regions`, {
      headers: { tenantId },
    });
    const identificationTypes = await axios.get(
      `${ENDPOINT}/billinginformations/identificationtype`,
      { headers: { Authorization: `Bearer ${isToken}` } }
    );
    const responsabilitiesC = await axios.get(`${ENDPOINT}/billinginformations/responsibilities`, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    setGlobalResponsabilities(responsabilitiesC.data);
    setTypesIdentifications(identificationTypes.data);
    setGlobalRegions(regions.data);
    setActivitiesEconomic(economicActivities.data);
    setGlobalResponsabilitiesFor(responsabilitiesFor.data);
    setPersonTypes(personTypesC.data);
    setRegimenTypes(regimenType.data);
    setPaymentMeans(responsePaymentMeans.data);
  };

  const getDataCustomer = async () => {
    if (payment !== null) {
      const isToken = localStorage.getItem("token");
      const customersData = await axios.get(`${ENDPOINT}/customers/${payment?.customer.id}`, {
        headers: { Authorization: `Bearer ${isToken}` },
      });
      const dataCustomer = customersData.data;

      setIdentification(dataCustomer?.nIdentification);
      setIdentificationTypeCode(
        typesIdentifications.find((t) => t.name === dataCustomer?.identificationType)
      );
      setFirstName(dataCustomer?.name);
      setLastName("");
      setEmail(dataCustomer?.email);
      setPhone(dataCustomer?.phoneNumber);
      setBillingAddress(dataCustomer?.address);
    }
  };

  const validateStep1 = () => {
    if (
      paymenType === "" ||
      (showDigitCheck && companyName === "") ||
      personType === "" ||
      regimeType === "" ||
      (!showDigitCheck && (firstName === "" || lastName === "")) ||
      identification === "" ||
      (showDigitCheck && showDigitCheckIdentification && digitCheck === "") ||
      identificationTypeCode === ""
    ) {
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    if (
      email === "" ||
      phone === "" ||
      merchantRegistration === "" ||
      responsibleFor === "" ||
      responsibilities === "" ||
      personName === "" ||
      billingAddress === "" ||
      billingPhone === ""
    ) {
      return false;
    }
    return true;
  };

  const validateStep3 = () => {
    if (
      billingRegionCode === "" ||
      billingCityCode === "" ||
      billingPostalCode === "" ||
      billingNeighborhood === "" ||
      billingContactName === ""
    ) {
      return false;
    }
    return true;
  };

  useEffect(async () => {
    if (billingRegionCode !== "") {
      const tenantId = localStorage.getItem("idTenant");
      const isToken = localStorage.getItem("token");
      const regions = await axios.get(`${ENDPOINT}/cities?regionCode=${billingRegionCode.code}`, {
        headers: { tenantId, Authorization: `Bearer ${isToken}` },
      });
      setGlobalCities(regions.data);
    }
  }, [billingRegionCode]);

  useEffect(async () => {
    if (paymentOrderId !== undefined && paymentOrderId !== null && paymentOrderId !== "") {
      setLoading(true);
      await getPaymentsMeans();
      const isToken = localStorage.getItem("token");
      const dataPayment = await axios.get(`${ENDPOINT}/paymentorder/${paymentOrderId}`, {
        headers: { Authorization: `Bearer ${isToken}` },
      });
      if (dataPayment?.data?.billingInformation === null) {
        await getDataCustomer();
      } else {
        setDataPaymentOrder(dataPayment?.data?.billingInformation);
      }

      setLoading(false);
    }
  }, [paymentOrderId, payment]);

  useEffect(() => {
    if (dataPaymentOrder !== undefined && dataPaymentOrder !== null) {
      setCompanyName(dataPaymentOrder.companyName);
      setFirstName(dataPaymentOrder.firstName);
      setLastName(dataPaymentOrder.lastName);
      setIdentification(dataPaymentOrder.identification);
      setEmail(dataPaymentOrder.email);
      setPhone(dataPaymentOrder.phone);
      setMerchantRegistration(dataPaymentOrder.merchantRegistration);
      setResponsibleFor(dataPaymentOrder.responsibleFor);
      setBillingAddress(dataPaymentOrder.billingAddress);
      setBillingPhone(dataPaymentOrder.billingPhone);
      setBillingRegionCode(dataPaymentOrder.billingRegionCode);
      setBillingCityCode(dataPaymentOrder.billingCityCode);
      setBillingPostalCode(dataPaymentOrder.billingPostalCode);
      setBillingNeighborhood(dataPaymentOrder.billingNeighborhood);
      setBillingContactName(dataPaymentOrder.billingContactName);
      setIdentificationTypeCode(dataPaymentOrder.identificationTypeCode);
      const personTypeFind = personTypes.find(
        (p) => p.code === dataPaymentOrder.personType || p.name === dataPaymentOrder.personType
      );
      const regimeTypeFind = regimenTypes.find(
        (p) => p.code === dataPaymentOrder.regimeType || p.name === dataPaymentOrder.regimeType
      );
      const responsabilitiesFind = globalResponsabilities.find(
        (p) =>
          p.code === dataPaymentOrder.responsibilities ||
          p.name === dataPaymentOrder.responsibilities
      );
      setResponsabilities(responsabilitiesFind);
      setRegimeType(regimeTypeFind.name);
      setPersonType(personTypeFind.name);
    }
  }, [dataPaymentOrder]);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePRevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Dialog
        aria-labelledby="responsive-dialog-title"
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth="lg"
        style={{ height: "700px" }}
      >
        <DialogTitle>Completar información factura</DialogTitle>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <DialogContent style={{ marginTop: -10 }} width="90%" height="90%">
          {loading ? (
            <CircularProgress size="40" style={{ marginLeft: "48%" }} color="secondary" />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={() => {}}
            >
              {() => (
                <Form>
                  <Stepper
                    activeStep={activeStep}
                    style={{
                      background: "#fffefe",
                      border: "none",
                      borderRadius: "0",
                      boxShadow: "none",
                      overflow: "hidden",
                    }}
                  >
                    {activeStep === 0 && (
                      <Step
                        style={{
                          background: "#fffefe",
                          width: "100%",
                          border: "none",
                          borderRadius: "0",
                          boxShadow: "none",
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{
                            background: "#fffefe",
                            width: "100%",
                            border: "none",
                            borderRadius: "0",
                            boxShadow: "none",
                          }}
                        >
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                              id="paymentType"
                              value={paymenType}
                              name="paymentType"
                              onChange={(e, value) => {
                                setPaymentType(value.name);
                              }}
                              options={paymentMeans}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo de pago"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="paymentType"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          {showDigitCheck && (
                            <Grid item xs={6} sm={6} md={6}>
                              <Field
                                as={TextField}
                                label="Nombre empresa"
                                name="companyName"
                                value={companyName}
                                onChange={(e) => {
                                  setCompanyName(e.target.value);
                                }}
                                sx={{ width: "100%" }}
                                required
                                inputProps={{ type: "text", autoComplete: "off" }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                              />
                            </Grid>
                          )}
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="personType"
                              value={personType}
                              name="personType"
                              onChange={(e, value) => {
                                if (value.code === "1") {
                                  setShowDigitCheck(true);
                                  setShowDigitCheckIdentification(true);
                                  setIdentificationTypeCode({
                                    code: "31",
                                    name: "NIT",
                                  });
                                } else {
                                  setShowDigitCheck(false);
                                }
                                setPersonType(value);
                              }}
                              options={personTypes}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo de persona"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="personType"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="regimeType"
                              value={regimeType?.name}
                              name="regimeType"
                              onChange={(e, value) => {
                                setRegimeType(value);
                              }}
                              options={regimenTypes}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo de régimen"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="regimeType"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          {!showDigitCheck && (
                            <>
                              <Grid item xs={6} sm={6} md={6}>
                                <Field
                                  as={TextField}
                                  label="Nombre"
                                  name="firstName"
                                  sx={{ width: "100%" }}
                                  value={firstName}
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                  }}
                                  required
                                  inputProps={{ type: "text", autoComplete: "off" }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="standard"
                                />
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <Field
                                  as={TextField}
                                  label="Apellido"
                                  name="lastName"
                                  sx={{ width: "100%" }}
                                  value={lastName}
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                  required
                                  inputProps={{ type: "text", autoComplete: "off" }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="standard"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={6} sm={6} md={showDigitCheck ? 4 : 6}>
                            <Field
                              as={TextField}
                              label="Identificación"
                              name="identification"
                              sx={{ width: "100%" }}
                              value={identification}
                              onChange={(value) => {
                                setIdentification(value.target.value);
                              }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          {showDigitCheck && showDigitCheckIdentification && (
                            <>
                              <Grid item xs={6} sm={6} md={1}>
                                <p style={{ marginTop: "10px", marginLeft: "20px" }}>-</p>
                              </Grid>
                              <Grid item xs={6} sm={6} md={1}>
                                <Field
                                  as={TextField}
                                  label="DV"
                                  name="digitCheck"
                                  sx={{ width: "100%" }}
                                  value={digitCheck}
                                  onChange={(value) => {
                                    setDigitCheck(value.target.value);
                                  }}
                                  required
                                  inputProps={{ type: "text", autoComplete: "off" }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="standard"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="identificationTypeCode"
                              value={identificationTypeCode?.name}
                              onChange={(e, value) => {
                                setIdentificationTypeCode(value);
                              }}
                              name="identificationTypeCode"
                              options={typesIdentifications}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo de identificación"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="identificationTypeCode"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item style={{ marginLeft: "80%" }}>
                            <MDBox mt={4} mb={1} pr={1}>
                              {activeStep === 2 ? (
                                <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                                  Finalizar
                                </MDButton>
                              ) : (
                                <>
                                  <Grid container>
                                    <Button
                                      color="secondary"
                                      style={{ marginLeft: "-770px" }}
                                      onClick={handlePRevStep}
                                      disabled
                                    >
                                      Volver
                                    </Button>

                                    <MDButton
                                      onClick={handleNextStep}
                                      variant="gradient"
                                      color="dark"
                                      style={{ marginLeft: "700px", width: "100px" }}
                                      disabled={!validateStep1()}
                                    >
                                      Continuar
                                    </MDButton>
                                  </Grid>
                                </>
                              )}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Step>
                    )}
                    {activeStep === 1 && (
                      <Step style={{ background: "white", width: "100%" }}>
                        <Grid container spacing={2} style={{ background: "white", width: "100%" }}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Correo electrónico"
                              name="email"
                              value={email}
                              onChange={(value) => {
                                setEmail(value.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Teléfono"
                              name="phone"
                              value={phone}
                              onChange={(value) => {
                                setPhone(value.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Comerciante Registro"
                              name="merchantRegistration"
                              value={merchantRegistration}
                              onChange={(value) => {
                                setMerchantRegistration(value.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="responsibleFor"
                              defaultValue=""
                              name="responsibleFor"
                              value={responsibleFor?.name}
                              onChange={(e, value) => {
                                setResponsibleFor(value);
                              }}
                              options={globalResponsabilitiesFor}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Responsable de"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="responsibleFor"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="responsabilities"
                              defaultValue=""
                              name="responsabilities"
                              value={responsibilities?.name}
                              onChange={(e, value) => {
                                setResponsabilities(value);
                              }}
                              options={globalResponsabilities}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Responsabilidades"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="responsabilities"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="economicActivities"
                              multiple
                              value={personName}
                              onChange={handleChangeMs}
                              input={<OutlinedInput id="select-multiple-chip" label="Prueba" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </Box>
                              )}
                              style={{
                                width: "100%",
                                minHeight: "46px",
                              }}
                            >
                              {activitiesEconomic.map((value) => (
                                <MenuItem key={value.name} value={value.name}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Dirección de envío"
                              name="billingAddress"
                              value={billingAddress}
                              onChange={(value) => {
                                setBillingAddress(value.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Teléfono facturación"
                              name="billingPhone"
                              sx={{ width: "100%" }}
                              value={billingPhone}
                              onChange={(value) => {
                                setBillingPhone(value.target.value);
                              }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item style={{ marginLeft: "80%" }}>
                            <MDBox mt={4} mb={1} pr={1}>
                              {activeStep === 2 ? (
                                <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                                  Finalizar
                                </MDButton>
                              ) : (
                                <>
                                  <Grid container>
                                    <Button
                                      color="secondary"
                                      style={{ marginLeft: "-770px" }}
                                      onClick={handlePRevStep}
                                    >
                                      Volver
                                    </Button>
                                    <MDButton
                                      onClick={handleNextStep}
                                      variant="gradient"
                                      color="dark"
                                      style={{ marginLeft: "760px", width: "100px" }}
                                      disabled={!validateStep2()}
                                    >
                                      Continuar
                                    </MDButton>
                                  </Grid>
                                </>
                              )}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Step>
                    )}
                    {activeStep === 2 && (
                      <Step style={{ background: "#fffefe", width: "100%" }}>
                        <Grid
                          container
                          spacing={2}
                          style={{ background: "#fffefe", width: "100%" }}
                        >
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="billingRegionCode"
                              defaultValue=""
                              name="billingRegionCode"
                              value={billingRegionCode?.name}
                              onChange={(e, value) => {
                                setBillingRegionCode(value);
                              }}
                              options={globalRegions}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Región facturación"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="billingRegionCode"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Autocomplete
                              id="billingCityCode"
                              defaultValue=""
                              value={billingCityCode?.name}
                              onChange={(e, value) => {
                                setBillingCityCode(value);
                              }}
                              name="billingCityCode"
                              options={globalCities}
                              disabled={globalCities.length === 0}
                              getOptionLabel={(option) => option.name ?? option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ciudad facturación"
                                  variant="standard"
                                  required
                                  fullWidth
                                  name="billingCityCode"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Código postal"
                              name="billingPostalCode"
                              value={billingPostalCode}
                              onChange={(value) => {
                                setBillingPostalCode(value.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Barrio facturación"
                              name="billingNeighborhood"
                              value={billingNeighborhood}
                              onChange={(value) => {
                                setBillingNeighborhood(value.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={12}>
                            <Field
                              as={TextField}
                              label="Nombre contacto"
                              name="billingContactName"
                              sx={{ width: "100%" }}
                              value={billingContactName}
                              onChange={(value) => {
                                setBillingContactName(value.target.value);
                              }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item style={{ marginLeft: "80%" }}>
                            <MDBox mt={4} mb={1} pr={1}>
                              {activeStep === 2 ? (
                                <>
                                  <Button
                                    color="secondary"
                                    style={{ marginLeft: "-770px" }}
                                    onClick={handlePRevStep}
                                  >
                                    Volver
                                  </Button>
                                  <MDButton
                                    type="submit"
                                    onClick={endCompleteData}
                                    variant="gradient"
                                    color="dark"
                                    fullWidth
                                    disabled={!validateStep3()}
                                    style={{ marginLeft: "690px", width: "100px" }}
                                  >
                                    Finalizar
                                  </MDButton>
                                </>
                              ) : (
                                <>
                                  <Grid container>
                                    <Button
                                      color="secondary"
                                      style={{ marginLeft: "-770px" }}
                                      onClick={handlePRevStep}
                                    >
                                      Volver
                                    </Button>
                                    <MDButton
                                      onClick={handleNextStep}
                                      variant="gradient"
                                      color="dark"
                                      style={{ marginLeft: "700px", width: "100px" }}
                                    >
                                      Continuar
                                    </MDButton>
                                  </Grid>
                                </>
                              )}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Step>
                    )}
                  </Stepper>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
CompleteBillInformation.defaultProps = {
  callBack: () => {},
  payment: null,
};

CompleteBillInformation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callBack: PropTypes.func,
  paymentOrderId: PropTypes.string.isRequired,
  payment: PropTypes.objectOf(),
};

export default CompleteBillInformation;
