import { useState } from "react";
import { Card, CardActions, CardContent, Collapse, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import ViewAttachment from "./VIewAttachments";

function CardSection({ answers, name, attachments }) {
  const [checked, setChecked] = useState(false);
  return (
    <Card>
      <CardContent sx={{ backgroundColor: "#f2f8fc", borderRadius: "5px" }}>
        <Collapse in={checked} collapsedSize={80}>
          <Typography
            align="justify"
            fontSize="15px"
            variant="body1"
            gutterBottom
            noWrap={!checked}
            color="#212121"
          >
            <strong>&#8226;Pregunta: {name}</strong>
          </Typography>
          {answers.length > 0 ? (
            answers.map((answer) => (
              <Typography
                align="justify"
                fontSize="16px"
                variant="body1"
                gutterBottom
                noWrap={!checked && typeof answer.value !== "boolean" && answer.value.length > 100}
                color="#212121"
              >
                <strong> Respuesta:</strong> {typeof answer.value !== "boolean" && answer.value}{" "}
                {typeof answer.value === "boolean" && (answer.value ? "Sí" : "No")}
              </Typography>
            ))
          ) : (
            <Typography
              align="justify"
              fontSize="12px"
              variant="body1"
              gutterBottom
              color="#212121"
            >
              No hay respuesta registrada
            </Typography>
          )}
          {attachments.length > 0 && (
            <Typography
              align="justify"
              fontSize="15px"
              variant="body1"
              gutterBottom
              color="#212121"
            >
              <strong> Cantidad de adjuntos: </strong> {attachments.length}
            </Typography>
          )}
          <MDBox mt={2}>
            {attachments.length > 0 && <ViewAttachment attachments={attachments} />}
          </MDBox>
        </Collapse>
      </CardContent>
      {attachments?.length > 0 && (
        <CardActions>
          <MDButton
            sx={{ width: "100%" }}
            onClick={() => {
              setChecked(!checked);
            }}
          >
            {!checked ? "Ver mas" : "Ver menos"}
          </MDButton>
        </CardActions>
      )}
    </Card>
  );
}
CardSection.defaultProps = {};

CardSection.propTypes = {
  name: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf().isRequired,
  attachments: PropTypes.arrayOf().isRequired,
};

export default CardSection;
