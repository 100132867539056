import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableMui from "../../components/TableMui/TableMui";
import { documentsUploadAnonymous } from "../../store/generateService/actions";
import { upload } from "../../services/GenerateServices/GenerateService";

function TableDocuments({
  documentsListData,
  rowCount,
  page,
  pageSize,
  changePage,
  changePageSize,
  handleShow,
  setDocumentsSend,
  tenantId,
}) {
  const handleChangePage = (value) => changePage(value);

  const handlePageSize = (newPageSize) => changePageSize(newPageSize);
  const [uploading, setUploading] = useState(0);
  const [table, setTable] = useState(false);
  const dispatch = useDispatch();

  const tableUpdate = () => {
    setTable(!table);
  };

  const updateDocument = async (id, file) => {
    const document = documentsListData.find((doc) => doc.id === id);
    const uploadDocument = {
      tDocumentoId: document.tDocumentoId,
      filename: file.name,
      originDate: null,
      documentName: document.documentName,
      type: file.type,
      document: file,
    };
    const upDocument = await dispatch(documentsUploadAnonymous(tenantId, uploadDocument));
    const buffer = await uploadDocument.document.arrayBuffer();
    const bytes = new Uint8Array(buffer);
    await upload(uploadDocument.type, bytes, upDocument.uploadUrl).then(() => {
      setUploading(0);
    });
    // eslint-disable-next-line no-console
    console.log(uploading);
    documentsListData.forEach((doc) => {
      if (doc.id === id) {
        doc.url = upDocument.uploadUrl; // eslint-disable-line no-param-reassign
      }
    });
    setDocumentsSend(documentsListData);
    tableUpdate();
  };

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    { field: "name", headerName: "Nombre", width: 200, type: "string" },
    {
      field: "actions",
      headerName: "Acciones",
      width: 200,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Tooltip title="Editar documento">
              <Button
                variant="contained"
                component="label"
                style={{ backgroundColor: "transparent", color: "#3C3C3B", border: "none" }}
                startIcon={<EditIcon />}
              >
                <input
                  value=""
                  hidden
                  multiple
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => updateDocument(row.id, e.target.files[0])}
                />
              </Button>
            </Tooltip>
            <Tooltip title="Ver documento">
              <IconButton onClick={() => handleShow(row.url, row.name)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </>
        );
        return template;
      },
    },
  ];

  return (
    <>
      {table ? (
        <TableMui
          paginationMode="server"
          columns={columns}
          rows={documentsListData}
          rowCount={rowCount}
          pagination
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageChange={handleChangePage}
          onPageSizeChange={handlePageSize}
          disableSelectionOnClick
          checkboxSelection={false}
          autoHeight
          disableColumnFilter
          isFilterActive={false}
          hiddenFooter="1"
        />
      ) : (
        <TableMui
          paginationMode="server"
          columns={columns}
          rows={documentsListData}
          rowCount={rowCount}
          pagination
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageChange={handleChangePage}
          onPageSizeChange={handlePageSize}
          disableSelectionOnClick
          checkboxSelection={false}
          autoHeight
          isFilterActive={false}
          hiddenFooter="1"
        />
      )}
    </>
  );
}

TableDocuments.defaultProps = {
  documentsListData: [],
};

TableDocuments.propTypes = {
  documentsListData: PropTypes.arrayOf(PropTypes.string),
  rowCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  setDocumentsSend: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired,
};

export default TableDocuments;
