import React, { forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import moment from "moment";
import DialogContent from "@mui/material/DialogContent";
import { Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import ModalWithMap from "components/ModalWithMap";
import MDBox from "components/MDBox";
import DetailAnswersInOrder from "./DetailAnswersInOrder";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DialogDetailOrder({
  openDetailOrder,
  handleCloseDetailOrder,
  dataDetailOrder,
  suvery,
  questions,
  attachments,
}) {
  return (
    <div>
      <Dialog
        fullScreen
        open={openDetailOrder}
        onClose={handleCloseDetailOrder}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            {localStorage.getItem("imageUrl") !== "null" && (
              <MDBox
                component="img"
                src={localStorage.getItem("imageUrl")}
                width="45px"
                height="40px"
                style={{ marginRight: "2%" }}
              />
            )}
            <Typography sx={{ flex: 1 }} variant="h5" component="div">
              Detalle de la Inspeccion
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseDetailOrder}>
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ backgroundColor: "#f0f2f5" }}>
          {dataDetailOrder ? (
            <ModalWithMap>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography variant="h5" align="center" gutterBottom component="div">
                            Información del empleado
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Nombre: </strong>
                            {!!dataDetailOrder && dataDetailOrder?.user?.firstName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Apellidos: </strong>
                            {!!dataDetailOrder && dataDetailOrder?.user?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Tipo de documento: </strong>
                            {!!dataDetailOrder &&
                              !!dataDetailOrder.user &&
                              !!dataDetailOrder.user.userProfile &&
                              dataDetailOrder.user.userProfile.identificationType}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Número de identificación: </strong>
                            {!!dataDetailOrder &&
                              !!dataDetailOrder.user &&
                              !!dataDetailOrder.user.userProfile &&
                              dataDetailOrder.user.userProfile.nuip}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Teléfono: </strong>
                            {!!dataDetailOrder && dataDetailOrder?.user?.phoneNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Cargo: </strong>
                            {!!dataDetailOrder &&
                              !!dataDetailOrder.user &&
                              !!dataDetailOrder.user.userProfile &&
                              dataDetailOrder.user.userProfile.position}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Fecha de visita: </strong>
                            {moment(!!dataDetailOrder && dataDetailOrder?.user?.visitDate).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography variant="h5" align="center" gutterBottom component="div">
                            Información del cliente
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Nombre: </strong>
                            {!!dataDetailOrder && dataDetailOrder.customer.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Tipo de documento: </strong>
                            {!!dataDetailOrder && dataDetailOrder.customer.identificationType}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Número de identificación: </strong>
                            {!!dataDetailOrder && dataDetailOrder.customer.nIdentification}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Dirección: </strong>
                            {!!dataDetailOrder && dataDetailOrder.customer.address}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Teléfono: </strong>
                            {!!dataDetailOrder && dataDetailOrder.customer.phoneNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>ARL: </strong>
                            {!!dataDetailOrder && dataDetailOrder.customer.arl}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!!dataDetailOrder && dataDetailOrder.service && false && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h5" align="center" gutterBottom component="div">
                              Estrategia de pago
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="body2" color="#212121" gutterBottom>
                              <strong>Nivel de riesgo: </strong>
                              {!!dataDetailOrder &&
                                dataDetailOrder.customer &&
                                dataDetailOrder.customer.customerDetail.riskLevel}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="body2" color="#212121" gutterBottom>
                              <strong>Metros cuadrados totales: </strong>
                              {!!dataDetailOrder &&
                                dataDetailOrder.customer &&
                                dataDetailOrder.customer.customerDetail.totalSquareMeters}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="body2" color="#212121" gutterBottom>
                              <strong>Metros construidos: </strong>
                              {!!dataDetailOrder &&
                                dataDetailOrder.customer &&
                                dataDetailOrder.customer.customerDetail.squareMetersBuilt}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {suvery ? (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h5" align="center" gutterBottom component="div">
                            Información de la Inspeccion
                            {/* {!!suvery && suvery.Name} */}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {!!suvery &&
                            suvery.Forms.length > 0 &&
                            suvery.Forms.map((form) => (
                              <DetailAnswersInOrder
                                form={form}
                                answers={questions}
                                attachments={attachments}
                              />
                            ))}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} mt={5}>
                          <Typography
                            style={{
                              backgroundColor: "#424242",
                              borderRadius: "5px",
                              color: "#fafafa",
                              padding: "10px",
                            }}
                            variant="h6"
                            align="center"
                            gutterBottom
                            component="div"
                          >
                            No hay formulario
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </ModalWithMap>
          ) : (
            <div>No hay resultados</div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

DialogDetailOrder.defaultProps = {
  openDetailOrder: false,
  suvery: {},
  questions: [],
  dataDetailOrder: {},
  attachments: [],
};

DialogDetailOrder.propTypes = {
  openDetailOrder: PropTypes.bool,
  handleCloseDetailOrder: PropTypes.func.isRequired,
  suvery: PropTypes.objectOf(PropTypes.string),
  questions: PropTypes.arrayOf(PropTypes.string),
  dataDetailOrder: PropTypes.objectOf(PropTypes.string),
  attachments: PropTypes.objectOf(PropTypes.string),
};

export default DialogDetailOrder;
