import * as React from "react";
import axios from "axios";
import { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import InboxIcon from "@mui/icons-material/Inbox";
import { Upload } from "antd";
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import { ENDPOINT } from "../../../../services/httpRequest";

function DialogCustomerDocument({ open, onClose, callBack }) {
  const [isExcel, setIsExcel] = useState(false);
  const [fileToSend, setFileToSend] = useState(false);

  // const dummyRequest = ({ file, onSuccess }) => {
  //   setTimeout(() => {
  //     /* eslint-disable no-console */
  //     console.log(file);
  //     onSuccess("ok");
  //   }, 0);
  // };
  const { Dragger } = Upload;
  const alert = useAlert();

  const props = {
    name: "file",
    multiple: false,
    action: `${ENDPOINT}/customers/createmasivecustomer`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    onChange(info) {
      if (info.file.status !== "uploading") {
        alert.show(`Subiendo ${info.file.name}`, { position: "top right" });
      }
      if (info.file.status === "done") {
        alert.success(`${info.file.name} subido correctamente.`);
        callBack();
        onClose();
      } else if (info.file.status === "error") {
        alert.error(`${info.file.response.Exception}.`, { position: "top right" });
      }
    },
    onDrop(e) {
      if (
        !e.dataTransfer.files[0].name.includes(".xls") ||
        !e.dataTransfer.files[0].name.includes(".xlsx")
      ) {
        setIsExcel(false);
        alert.error(`Solo subir archivos excel.`, { position: "top right" });
      } else {
        setIsExcel(true);
        setFileToSend(e.dataTransfer.files[0]);
      }
    },
  };

  const uploadCustomerFile = async () => {
    const blobArchivo = new Blob([fileToSend], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const file = new FormData();
    file.append("file", blobArchivo);

    try {
      const { data } = await axios.post(`${ENDPOINT}/customers/createmasivecustomer`, file, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      // eslint-disable-next-line no-console
      console.log(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Subir documento clientes</DialogTitle>
      <DialogContent>
        <Dragger accept=".xls, .xlsx" {...props}>
          <p className="ant-upload-drag-icon">
            <InboxIcon fontSize="large" />
          </p>
          <p className="ant-upload-text">Click o suelta un archivo excel</p>
        </Dragger>
        {false && (
          <MDBox mt={4} mb={1} pr={1}>
            <MDButton
              disabled={!isExcel}
              onClick={uploadCustomerFile}
              variant="gradient"
              color="dark"
              fullWidth
            >
              Subir archivo
            </MDButton>
          </MDBox>
        )}
      </DialogContent>
    </Dialog>
  );
}
DialogCustomerDocument.defaultProps = {
  callBack: () => {},
};

DialogCustomerDocument.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callBack: PropTypes.func,
};

export default DialogCustomerDocument;
