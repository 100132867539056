import PropTypes from "prop-types";
import MDBox from "components/MDBox";

function TabPanel({ children, value, index, styleToBox, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {value === index && <MDBox sx={styleToBox}>{children}</MDBox>}
    </div>
  );
}
TabPanel.defaultProps = {
  children: <></>,
  styleToBox: { p: 3 },
};

TabPanel.propTypes = {
  children: PropTypes.node,
  styleToBox: PropTypes.objectOf(),
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
