import React, { forwardRef, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import moment from "moment";
import DialogContent from "@mui/material/DialogContent";
import { CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import ModalWithMap from "components/ModalWithMap";
import MDBox from "components/MDBox";
import { useReactToPrint } from "react-to-print";
import DetailAnswersInPdf from "./DetailAnswersInPdf";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DialogDetailPdf({
  openDetailPdf,
  handleCloseDetailPdf,
  dataDetailPdf,
  suvery,
  questions,
  attachments,
}) {
  const componentRef = useRef();

  const handleGeneratePDF = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Dialog
        fullScreen
        open={openDetailPdf}
        onClose={handleCloseDetailPdf}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            {localStorage.getItem("imageUrl") !== "null" && (
              <MDBox
                component="img"
                src={localStorage.getItem("imageUrl")}
                width="45px"
                height="40px"
                style={{ marginRight: "2%" }}
              />
            )}
            <Typography sx={{ flex: 1 }} variant="h5" component="div">
              Detalle de la Inspeccion
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseDetailPdf}>
              Cerrar
            </Button>
            <Button autoFocus color="primary" onClick={handleGeneratePDF}>
              PDF
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ backgroundColor: "#fff" }}>
          <div ref={componentRef}>
            {dataDetailPdf ? (
              <ModalWithMap>
                <CardContent>
                  <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                      {localStorage.getItem("imageUrl") !== "null" && (
                        <MDBox
                          component="img"
                          src={localStorage.getItem("imageUrl")}
                          width="45px"
                          height="40px"
                          style={{ marginRight: "2%", marginTop: 20, marginBottom: 20 }}
                        />
                      )}
                      <Typography
                        sx={{ flex: 1, marginTop: 3, marginBottom: 3 }}
                        variant="h5"
                        component="div"
                      >
                        {dataDetailPdf?.completionDetail.surveyPublished.name}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <br />
                  <br />
                  <hr />
                  <br />
                  <br />
                  <Grid>
                    <Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography variant="h5" align="center" gutterBottom component="div">
                            Datos de la inspeccion
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Nombre: </strong>
                            {!!dataDetailPdf && dataDetailPdf?.user?.firstName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Apellidos: </strong>
                            {!!dataDetailPdf && dataDetailPdf?.user?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Tipo de documento: </strong>
                            {!!dataDetailPdf &&
                              !!dataDetailPdf.user &&
                              !!dataDetailPdf.user.userProfile &&
                              dataDetailPdf.user.userProfile.identificationType}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Número de identificación: </strong>
                            {!!dataDetailPdf &&
                              !!dataDetailPdf.user &&
                              !!dataDetailPdf.user.userProfile &&
                              dataDetailPdf.user.userProfile.nuip}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Teléfono: </strong>
                            {!!dataDetailPdf && dataDetailPdf?.user?.phoneNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Cargo: </strong>
                            {!!dataDetailPdf &&
                              !!dataDetailPdf.user &&
                              !!dataDetailPdf.user.userProfile &&
                              dataDetailPdf.user.userProfile.position}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Fecha de visita: </strong>
                            {moment(!!dataDetailPdf && dataDetailPdf?.user?.visitDate).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Nombre: </strong>
                            {!!dataDetailPdf && dataDetailPdf.customer.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Tipo de documento: </strong>
                            {!!dataDetailPdf && dataDetailPdf.customer.identificationType}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Número de identificación: </strong>
                            {!!dataDetailPdf && dataDetailPdf.customer.nIdentification}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Dirección: </strong>
                            {!!dataDetailPdf && dataDetailPdf.customer.address}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>Teléfono: </strong>
                            {!!dataDetailPdf && dataDetailPdf.customer.phoneNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="body2" color="#212121" gutterBottom>
                            <strong>ARL: </strong>
                            {!!dataDetailPdf && dataDetailPdf.customer.arl}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!!dataDetailPdf && dataDetailPdf.service && false && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h5" align="center" gutterBottom component="div">
                              Estrategia de pago
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="body2" color="#212121" gutterBottom>
                              <strong>Nivel de riesgo: </strong>
                              {!!dataDetailPdf &&
                                dataDetailPdf.customer &&
                                dataDetailPdf.customer.customerDetail.riskLevel}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="body2" color="#212121" gutterBottom>
                              <strong>Metros cuadrados totales: </strong>
                              {!!dataDetailPdf &&
                                dataDetailPdf.customer &&
                                dataDetailPdf.customer.customerDetail.totalSquareMeters}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography variant="body2" color="#212121" gutterBottom>
                              <strong>Metros construidos: </strong>
                              {!!dataDetailPdf &&
                                dataDetailPdf.customer &&
                                dataDetailPdf.customer.customerDetail.squareMetersBuilt}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {suvery ? (
                      <>
                        <Grid item xs={12}>
                          {!!suvery &&
                            suvery.Forms.length > 0 &&
                            suvery.Forms.map((form) => (
                              <>
                                <DetailAnswersInPdf
                                  form={form}
                                  answers={questions}
                                  attachments={attachments}
                                />
                              </>
                            ))}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} mt={5}>
                          <Typography
                            style={{
                              backgroundColor: "#424242",
                              borderRadius: "5px",
                              color: "#fafafa",
                              padding: "10px",
                            }}
                            variant="h6"
                            align="center"
                            gutterBottom
                            component="div"
                          >
                            No hay formulario
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="h6"
                      fontSize="13px"
                      align="center"
                      gutterBottom
                      component="div"
                    >
                      Fue aprobada la Inspeccion, según su resultado
                    </Typography>
                  </Grid>
                </CardContent>
              </ModalWithMap>
            ) : (
              <div>No hay resultados</div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

DialogDetailPdf.defaultProps = {
  openDetailPdf: false,
  suvery: {},
  questions: [],
  dataDetailPdf: {},
  attachments: [],
};

DialogDetailPdf.propTypes = {
  openDetailPdf: PropTypes.bool,
  handleCloseDetailPdf: PropTypes.func.isRequired,
  suvery: PropTypes.objectOf(PropTypes.string),
  questions: PropTypes.arrayOf(PropTypes.string),
  dataDetailPdf: PropTypes.objectOf(PropTypes.string),
  attachments: PropTypes.objectOf(PropTypes.string),
};

export default DialogDetailPdf;
