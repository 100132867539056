import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDInput from "components/MDInput";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MenuItem from "@mui/material/MenuItem";
import { Field, Form, Formik } from "formik";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import * as Yup from "yup";
import FormResolution from "../FormResolution";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  username: Yup.string().required("Campo requerido"),
  accessKey: Yup.string().required("Campo requerido"),
});

function DialogRegisterCredential({
  open,
  typeDialog,
  handleClose,
  setCodeProvider,
  codeProvider,
  setLocalResolution,
  localResolution,
  listProvider,
  handleCredential,
  dataEdit,
  loadingButton,
  handleCreateResolition,
  idCredentialNew,
}) {
  const [clickSave, setClickSave] = useState(false);
  const handleCodeProvider = (ev) => {
    setCodeProvider(ev.target.value);
  };

  const handleLocalResolution = () => {
    setLocalResolution(!localResolution);
    // eslint-disable-next-line no-console
    console.log(idCredentialNew);
  };

  const submit = (values) => {
    setClickSave(true);
    handleCredential(values);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth="true" maxWidth="lg">
        {localResolution && clickSave ? (
          <FormResolution
            loadingButton={loadingButton}
            handleClose={handleClose}
            handleCreateResolition={handleCreateResolition}
            isModal
            idCredential={dataEdit.id}
          />
        ) : (
          <Formik
            initialValues={{
              name: typeDialog === "registro" ? "" : dataEdit.name,
              username: typeDialog === "registro" ? "" : dataEdit.username,
              accessKey: typeDialog === "registro" ? "" : dataEdit.accessKey,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submit(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <DialogTitle>
                  {typeDialog === "registro" ? "Nueva credencial" : "Editar credencial"}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="name"
                        type="text"
                        as={MDInput}
                        variant="standard"
                        label="Nombre"
                        fullWidth
                        error={errors.name && touched.name}
                        helperText={touched.name && errors.name}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="username"
                        type="text"
                        as={MDInput}
                        variant="standard"
                        label="Nombre de usuario"
                        fullWidth
                        error={errors.username && touched.username}
                        helperText={touched.username && errors.username}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        name="accessKey"
                        type="text"
                        as={MDInput}
                        variant="standard"
                        label="Llave de acceso"
                        fullWidth
                        error={errors.accessKey && touched.accessKey}
                        helperText={touched.accessKey && errors.accessKey}
                        inputProps={{ autoComplete: "off" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mt={1}>
                        <TextField
                          select
                          label="Proveedor"
                          name="identificationType"
                          fullWidth
                          variant="standard"
                          id="outlined-size-normal"
                          value={codeProvider}
                          onChange={handleCodeProvider}
                        >
                          {listProvider.map((type) => (
                            <MenuItem key={type.code} value={type.code}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <MDBox>
                        <div style={{ fontSize: "13px" }}>¿Cuenta con resolución local?</div>
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              inputProps={{ "aria-label": "controlled" }}
                              checked={localResolution}
                              onChange={handleLocalResolution}
                            />
                          }
                          label=""
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* {localResolution && (
                    <>
                      <FormResolution
                        loadingButton={loadingButton}
                        handleClose={handleClose}
                        handleCreateResolition={handleCreateResolition}
                        isModal={false}
                      />
                    </>
                  )} */}
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={handleClose} variant="outlined" color="info">
                    Cancelar
                  </MDButton>
                  <MDButton type="submit" disabled={loadingButton} variant="gradient" color="info">
                    Guardar
                  </MDButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
}

DialogRegisterCredential.defaultProps = {
  open: false,
  setCodeProvider: "",
  setLocalResolution: "",
  listProvider: [],
  dataEdit: {},
  loadingButton: false,
};

DialogRegisterCredential.propTypes = {
  open: PropTypes.bool,
  typeDialog: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  setCodeProvider: PropTypes.element,
  codeProvider: PropTypes.string.isRequired,
  setLocalResolution: PropTypes.element,
  localResolution: PropTypes.string.isRequired,
  listProvider: PropTypes.arrayOf(PropTypes.string),
  handleCredential: PropTypes.func.isRequired,
  dataEdit: PropTypes.objectOf(PropTypes.string),
  loadingButton: PropTypes.bool,
  handleCreateResolition: PropTypes.func.isRequired,
  idCredentialNew: PropTypes.string.isRequired,
};

export default DialogRegisterCredential;
