import httpRequest from "../httpRequest";

// eslint-disable-next-line import/prefer-default-export
export const getRolesService = async () => {
  try {
    return await httpRequest.getEntries(`roles`);
  } catch (error) {
    return JSON.parse(error);
  }
};

export const eliminarRol = async (idRol) => {
  try {
    const data = await httpRequest.remove(`roles`, idRol);
    return data;
  } catch (error) {
    const { data } = error;
    throw data;
  }
};
