export const SET_DATA_SERVICE_TYPES = "[SERVICE_TYPES] SET_DATA_SERVICE_TYPES";
export const CREATE_SERVICE_TYPES = "[SERVICE_TYPES] CREATE_SERVICE_TYPES";
export const UPDATE_SERVICE_TYPES = "[SERVICE_TYPES] UPDATE_SERVICE_TYPES";
export const ACTIVATE_SERVICE_TYPES = "[SERVICE_TYPES] ACTIVATE_SERVICE_TYPES";
export const DES_ACTIVATE_SERVICE_TYPES = "[SERVICE_TYPES] DES_ACTIVATE_SERVICE_TYPES";
export const SET_DIALOG_TYPES = "[SERVICE_TYPES] SET_DIALOG_TYPES";
export const RESET_SERVICE_WITHOUT_TOKEN_TYPES =
  "[SERVICE_TYPES] RESET_SERVICE_WITHOUT_TOKEN_TYPES";
export const SET_DATA_SERVICE_WITHOUT_TOKEN_TYPES =
  "[SERVICE_TYPES] SET_DATA_SERVICE_WITHOUT_TOKEN_TYPES";
export const GET_SERVICE_BY_TENANT = "[SERVICE_TYPES] GET_SERVICE_BY_TENANT";
