import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import ViewPdf from "components/ViewPdf";
import StatusCell from "../../../../components/StatusCell/StatusCell";
import FooterCardCustomer from "../../componets/FooterCardCustomer";
import { getCertificatePdf } from "../../../../store/certificate/actions";
// import CompleteModalToPay from "./CompleteModalToPay";

function CardsPayments({ payment, callback, complete }) {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const [isOpenCompleteDataModal, setIsOpenCompleteDataModal] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [billingUrl, setBillingUrl] = useState(false);
  // const [currentPaymentSelect, setCurrentPaymentSelect] = useState(null);
  // const [paymetOrderSelectedId, setPaymentOrderSelectedId] = useState("");

  const handleCloseViewPdf = () => setOpenOrderDetails(false);

  const handleOpenViewPdf = async (url) => {
    const blob = await dispatch(getCertificatePdf(url));
    const bloobUrl = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = bloobUrl;
    link.download = `factura.pdf`;
    setBillingUrl(link);
    setOpenOrderDetails(true);
  };

  const handleCancel = async () => {
    callback(payment);
  };

  // const handleOpenModalCompleteData = (id, row) => {
  //   setPaymentOrderSelectedId(id);
  //   setCurrentPaymentSelect(row);
  //   setIsOpenCompleteDataModal(true);
  // };

  // const handleCloseModalCompleteData = () => {
  //   setIsOpenCompleteDataModal(false);
  // };

  const renderState = (value) => {
    let status;
    if (!value) {
      status = <StatusCell icon="close" color="error" status="Pendiente" />;
    } else {
      status = <StatusCell icon="done_all" color="success" status="pagada" />;
    }
    return status;
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "#fffff"}
      coloredShadow="dark"
      borderRadius="lg"
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox p={2}>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Servicio a pagar:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                {payment?.serviceName}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Valor del servicio: &nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {`$ ${payment?.valueToPay.toLocaleString()}`}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Estado factura:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {payment?.electronicInvoiceDocument === null && "No emitida"}
                {payment?.electronicInvoiceDocument?.documentStatus === "ERROR_MANDANTE" &&
                  "Error Mandante"}
                {payment?.electronicInvoiceDocument?.documentStatus === "INPROGRESS" &&
                  "En progreso"}
                {payment?.electronicInvoiceDocument?.documentStatus === "INPROGRESS_SEND" &&
                  "En progreso"}
                {payment?.electronicInvoiceDocument?.documentStatus === "ERROR_PROVIDER" &&
                  "Error proveedor"}
                {payment?.electronicInvoiceDocument?.documentStatus === "ERROR_DIAN" &&
                  "Error DIAN"}
                {payment?.electronicInvoiceDocument?.documentStatus ===
                  "ERROR_BILLINGINFORMATION" && "Error información de factura"}
                {payment?.electronicInvoiceDocument?.documentStatus === "ERROR_MANDANTE" &&
                  "Error mandante"}
                {payment?.electronicInvoiceDocument?.documentStatus === "ERROR_LOCAL" &&
                  "Error local"}
                {payment?.electronicInvoiceDocument?.documentStatus === "PENDING" && "Pendiente"}
                {payment?.electronicInvoiceDocument?.documentStatus === "GENERATED" && "Generada"}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>

        <FooterCardCustomer>
          {" "}
          <MDBox width="100%">
            <Grid container spacing={0.5}>
              <Grid xs>
                <MDBox
                  mr={1}
                  display="flex"
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  {renderState(payment.isPaid)}
                </MDBox>
              </Grid>
              <Grid xs>
                <MDBox
                  display="flex"
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDButton
                    onClick={() => handleCancel(payment)}
                    variant="text"
                    color="success"
                    disabled={payment.isPaid}
                  >
                    <Icon>paid_icon </Icon>&nbsp;Pagar
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid xs>
                <MDBox
                  display="flex"
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDButton
                    onClick={() => complete(payment.id, payment)}
                    variant="text"
                    color="success"
                    disabled={!payment.isPaid}
                  >
                    <Icon>file_copy_icon </Icon>&nbsp;Completar
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid xs>
                <MDBox
                  display="flex"
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDButton
                    variant="text"
                    onClick={() => handleOpenViewPdf(payment?.electronicInvoiceDocument.urlPdf)}
                    disabled={
                      payment?.electronicInvoiceDocument === null ||
                      payment?.electronicInvoiceDocument.documentStatus !== "GENERATED"
                    }
                    color="dark"
                  >
                    <Icon>visibility </Icon>&nbsp;Ver factura
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </FooterCardCustomer>
        {/* <CompleteModalToPay
          open={isOpenCompleteDataModal}
          onClose={handleCloseModalCompleteData}
          paymentOrderId={paymetOrderSelectedId}
          payment={currentPaymentSelect}
        /> */}
        <ViewPdf
          open={openOrderDetails}
          data={billingUrl}
          type={3}
          tittlePdf="Factura"
          onClose={handleCloseViewPdf}
        />
      </MDBox>
    </MDBox>
  );
}

CardsPayments.defaultProps = {
  callback: () => {},
  complete: () => {},
};
CardsPayments.propTypes = {
  callback: PropTypes.func,
  complete: PropTypes.func,
  payment: PropTypes.shape({
    id: PropTypes.string,
    paymentOrderNumber: PropTypes.number,
    isPaid: PropTypes.bool,
    serviceName: PropTypes.string,
    valueToPay: PropTypes.bool,
    electronicInvoiceDocument: PropTypes.string,
  }).isRequired,
};

export default CardsPayments;
