// eslint-disable-next-line import/prefer-default-export
export const SEARCH_PAYMENT_CREDENTIALS = "[PAYMENT_CREDENTIALS] SEARCH_PAYMENT_CREDENTIALS";
export const EDIT_PAYMENT_CREDENTIALS = "[PAYMENT_CREDENTIALS] EDIT_PAYMENT_CREDENTIALS";
export const CHANGE_STATUS_PAYMENT_CREDENTIALS =
  "[PAYMENT_CREDENTIALS] CHANGE_STATUS_PAYMENT_CREDENTIALS";
export const GET_URL_AUHT_MERCADO_PAGO_CREDENTIALS =
  "[PAYMENT_CREDENTIALS] GET_URL_AUHT_MERCADO_PAGO_CREDENTIALS";

export const CREATE_MERCADO_PAGO_CREDENTIALS =
  "[PAYMENT_CREDENTIALS] CREATE_MERCADO_PAGO_CREDENTIALS";
export const UPDATE_MERCADO_PAGO_CREDENTIALS =
  "[PAYMENT_CREDENTIALS] UPDATE_MERCADO_PAGO_CREDENTIALS";
