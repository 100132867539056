import httpRequest from "../httpRequest";

// eslint-disable-next-line import/prefer-default-export
export const getCitiesService = async (params) => {
  try {
    return await httpRequest.getCities(params);
  } catch (error) {
    return JSON.parse(error);
  }
};
