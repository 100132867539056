import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, HeatMap, Marker } from "google-maps-react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "./styles.css";

const MapContainer = ({
  centerPosition,
  style,
  customers,
  zoomtwo,
  isPositionAttachment,
  filter,
  employees,
  type,
}) => {
  const position = useSelector(({ map }) => map.location);
  const [center, setCenter] = useState(centerPosition || position);
  const zoom = useSelector(({ map }) => map.zoom);
  const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 252, 212, 1)",
    "rgba(0, 252, 212, 1)",
    "rgba(0, 252, 212, 1)",
    "rgba(0, 252, 212, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(252, 212, 68, 1)",
    "rgba(252, 212, 68, 1)",
    "rgba(252, 212, 68, 1)",
    "rgba(255, 0, 0, 1)",
  ];

  useEffect(() => {
    /* eslint-disable no-console */
    console.log(center, style);
    if (centerPosition) setCenter(centerPosition);
  }, [centerPosition]);

  return (
    <>
      <div className="map-container">
        <Map
          style={style}
          google={window.google}
          zoom={!zoomtwo ? zoom : zoomtwo}
          center={type === "1" ? position : centerPosition}
          initialCenter={type === "1" ? position : centerPosition}
        >
          {customers.length > 0 && (filter === 0 || filter === null) && (
            <HeatMap gradient={gradient} positions={customers} opacity={1} radius={10} />
          )}
          {employees.length > 0 && (filter === 0 || filter === null) && (
            <HeatMap gradient={gradient} positions={customers} opacity={1} radius={10} />
          )}
          {isPositionAttachment && <Marker />}
        </Map>
      </div>
    </>
  );
};

MapContainer.defaultProps = {
  customers: [],
  employees: [],
  zoomtwo: null,
  filter: 0,
  centerPosition: { lat: 4.703174, lng: -73.706568 },
  style: { height: "17%", width: "95%" },
  isPositionAttachment: false,
};

MapContainer.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
  employees: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
  centerPosition: PropTypes.objectOf(),
  zoomtwo: PropTypes.number,
  style: PropTypes.objectOf(),
  filter: PropTypes.number,
  isPositionAttachment: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAKlcg3KFkR5dPgq2JLkcDHP2Yq4moIcA4",
  libraries: ["visualization"],
  language: "es",
})(MapContainer);
