import { SEARCH_INVOICE } from "./types";
import { RESET_STATES } from "../allStorages/types";

export const INVOICE_INITIAL_STATE = {
  invoiceData: [],
};

export const invoice = (state = INVOICE_INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_INVOICE:
      return {
        ...state,
        invoiceData: action.payload.data,
      };
    case RESET_STATES:
      return INVOICE_INITIAL_STATE;

    default:
      return state;
  }
};
