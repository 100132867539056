import { getRolesService, eliminarRol } from "../../services/Roles/RolesService";

// eslint-disable-next-line import/prefer-default-export
export const getRolesAction = () => async (dispatch) => {
  const data = await getRolesService();
  dispatch({ type: "SET_DATA_ROLES", payload: data });
};

export const deleteRoleAction = (idRol) => async (dispatch) => {
  const data = await eliminarRol(idRol);
  dispatch({ type: "SET_DELETE_ROLES", payload: data });
};
