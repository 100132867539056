import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import moment from "moment";

function CardHistory({ historyData, payment }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        {historyData.typeAttempt === "MANUAL_PAYMENT" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              <strong>Tipo de pago:</strong> Manual
            </Typography>
            <Typography variant="body2">
              <strong>Usuario:</strong> {payment?.name}
            </Typography>
            <Typography variant="body2">
              <strong>Correo del usuario:</strong> {payment?.email}
            </Typography>
            <Typography variant="body2">
              <strong>Fecha de pago:</strong>{" "}
              {moment(historyData.paymentInformation.Date).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ mb: 1 }}>
              <strong>Tipo de pago:</strong> Pasarela de pago
            </Typography>
            <Typography variant="body2">
              <strong>Servicio pagado:</strong> {historyData.paymentInformation.Description}
            </Typography>
            <Typography variant="body2">
              <strong>Total pagado:</strong> ${historyData.paymentInformation.TransactionAmount}
            </Typography>
            <Typography variant="body2">
              <strong>Fecha de pago:</strong>{" "}
              {moment(historyData.paymentInformation.DateCreated).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}

CardHistory.defaultProps = {
  historyData: {},
};

CardHistory.propTypes = {
  historyData: PropTypes.objectOf(),
  payment: PropTypes.objectOf().isRequired,
};

export default CardHistory;
