import { Dialog, DialogContent } from "@mui/material";
import { createContext, useState, useEffect } from "react";
import MapContainer from "../HeatMap/Map";

export const DialogContext = createContext({ open: false, setOpen: () => {}, setMarket: () => {} });

// eslint-disable-next-line react/prop-types
export default function ModalWithMap({ children }) {
  const [open, setOpen] = useState(false);
  const [market, setMarket] = useState(null);

  useEffect(() => {
    if (!open) setMarket(null);
  }, [open]);

  return (
    <DialogContext.Provider
      value={{
        open,
        setOpen: (value) => setOpen(value),
        setMarket: (value) => {
          setMarket(value);
        },
      }}
    >
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ height: 450 }}>
          {market && (
            <MapContainer
              type="2"
              zoomtwo={15}
              centerPosition={market}
              style={{ height: "95%", width: "98%" }}
              customer={[market]}
              isPositionAttachment
            />
          )}
        </DialogContent>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
}
