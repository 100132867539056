import * as React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TableMui from "../../../../components/TableMui/TableMui";

function TableCredential({
  listCredential,
  rowCount,
  page,
  pageSize,
  loading,
  changePage,
  changePageSize,
  getDataSdvancedFilter,
  filters,
  updateFilters,
  handleEditCredential,
  handleChangeStatus,
  handleGetResolutionsByCredential,
}) {
  const handleChangePage = (value) => changePage(value);

  const handlePageSize = (newPageSize) => changePageSize(newPageSize);

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    {
      field: "name",
      headerName: "Nombre de credencial",
      width: 400,
      type: "string",
      fieldRef: "name",
    },
    {
      field: "username",
      headerName: "Nombre de usuario",
      width: 350,
      type: "string",
      fieldRef: "username",
    },
    {
      field: "providerCode",
      headerName: "Proveedor",
      width: 350,
      type: "string",
      fieldRef: "providerCode",
    },
    {
      field: "isActive",
      headerName: "Estado",
      width: 150,
      renderCell: ({ row }) => {
        const template = (
          <>
            {row.isActive ? "Activo" : "Inactivo"}
            <Switch
              checked={row.isActive}
              inputProps={{ "aria-label": "controlled" }}
              onChange={() => handleChangeStatus(row.id)}
            />
          </>
        );
        return template;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 220,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Tooltip title="Editar credencial">
              <IconButton
                onClick={() => handleEditCredential(row.id)}
                aria-label="delete"
                size="large"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Resoluciones">
              <IconButton
                onClick={() => handleGetResolutionsByCredential(row.id)}
                aria-label="delete"
                size="large"
              >
                <ListAltIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        );
        return template;
      },
    },
  ];

  return (
    <TableMui
      paginationMode="server"
      columns={columns}
      rows={listCredential}
      rowCount={rowCount}
      pagination
      page={page}
      pageSize={pageSize}
      rowsPerPageOptions={[5, 10, 20]}
      loading={loading}
      onPageChange={handleChangePage}
      onPageSizeChange={handlePageSize}
      disableSelectionOnClick
      checkboxSelection={false}
      getDataSdvancedFilter={getDataSdvancedFilter}
      filters={filters}
      updateFilters={updateFilters}
      autoHeight
    />
  );
}

TableCredential.defaultProps = {
  listCredential: [],
  getDataSdvancedFilter: () => {},
  filters: [],
  updateFilters: () => {},
};

TableCredential.propTypes = {
  listCredential: PropTypes.arrayOf(PropTypes.string),
  rowCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(),
  updateFilters: PropTypes.func,
  getDataSdvancedFilter: PropTypes.func,
  handleEditCredential: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleGetResolutionsByCredential: PropTypes.func.isRequired,
};

export default TableCredential;
