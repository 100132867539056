import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "./store/auth/actions";

const arrayPathExcluded = [
  "/login",
  "/landing-page",
  "/inicio",
  "/authentication/password-reset/cover",
  "/terminoscondiciones",
  "/authentication/reset-password",
];

// eslint-disable-next-line react/prop-types
const AxiosProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeChanges = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
      if (!arrayPathExcluded.includes(window.location.pathname)) navigate("../", { replace: true });
    }
  }, [dispatch]);
  useEffect(() => {
    window.addEventListener("storage", storeChanges);
    storeChanges();
    return () => window.removeEventListener("storage", storeChanges);
  }, [storeChanges]);
  return (
    <>
      {children}
      {/* <Loading open={open} message={message} /> */}
    </>
  );
};

export default AxiosProvider;
