import React, { useState, useEffect, useRef } from "react";
import { usePdf } from "react-pdf-js";

const PdfViewer = (url) => {
  const { document } = url;
  const [pages, setPages] = useState(null);
  const canvasEl = useRef(pages);

  const [loading, numPages] = usePdf({
    file: document,
    canvasEl,
  });

  useEffect(() => {
    setPages(numPages);
  }, [numPages]);

  return (
    <div>
      {loading && <span>Loading...</span>}
      <canvas ref={canvasEl} />
    </div>
  );
};

export default PdfViewer;
