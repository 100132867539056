import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import {
  CardActionArea,
  DialogActions,
  Checkbox,
  Switch,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import MDTypography from "components/MDTypography";
import { openUrl, filterByCode } from "utils/utils";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import Step from "@mui/material/Step";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stepper from "@mui/material/Stepper";
import * as Yup from "yup";
import axios from "axios";
// import { useAlert } from "react-alert";
import { getPrefente } from "../../../../../store/viewCustomer/actions";
import MDBox from "../../../../../components/MDBox";
import { generatePay } from "../../../../../store/paymentsGenerated/actions";
import CompleteBillDataDialog from "../../../../payments/components/CompleteBillInformation";
import { getPersonalProfile } from "../../../../../services/Personal/PersonalService";
import { ENDPOINT } from "../../../../../services/httpRequest";

function ModalToPay({ isOpen, onClose, credential, paymentsGateway, payment, callback }) {
  // console.log(paymentsGateway);
  const dispatch = useDispatch();
  // const alert = useAlert();
  const [currentTarget, setCurrentTarget] = useState(null);
  const [showFieldsToComplete, setShowFieldsToComplete] = useState(false);
  const [isCheckedCompleteData, setIsCheckedCompleteData] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showDigitCheck, setShowDigitCheck] = useState(false);
  const [showDigitCheckIdentification, setShowDigitCheckIdentification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalPaymentId, setGlobalPaymentId] = useState(null);
  const [globalCredentialId, setGlobalCredentialId] = useState(null);
  const [globalOrderServiceId, setGlobalServiceId] = useState(null);
  const [tenantData, setTenantData] = useState(null);
  const [globalResponsabilities, setGlobalResponsabilities] = useState(null);
  const [globalRegions, setGlobalRegions] = useState(null);
  const [globalResponsabilitiesFor, setGlobalResponsabilitiesFor] = useState(null);
  const [isOpenCompleteDataModal, setIsOpenCompleteDataModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [payType, setPayType] = useState(0);
  const [personTypes, setPersonTypes] = useState([]);
  const [typesIdentifications, setTypesIdentifications] = useState([]);
  const [activitiesEconomic, setActivitiesEconomic] = useState([]);
  const [regimenTypes, setRegimenTypes] = useState([]);
  const [paymentMeans, setPaymentMeans] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [globalCities, setGlobalCities] = useState([]);
  const [paymenType, setPaymentType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [personType, setPersonType] = useState("");
  const [regimeType, setRegimeType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationTypeCode, setIdentificationTypeCode] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [merchantRegistration, setMerchantRegistration] = useState("");
  const [responsibleFor, setResponsibleFor] = useState("");
  const [responsibilities, setResponsabilities] = useState("");
  const [defaultEconomic] = useState("161;162;164");
  // const [economicActivitie, setEconomicActivitie] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [billingRegionCode, setBillingRegionCode] = useState("");
  const [billingCityCode, setBillingCityCode] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingNeighborhood, setBillingNeighborhood] = useState("");
  const [billingContactName, setBillingContactName] = useState("");
  const [digitCheck, setDigitCheck] = useState("");
  const haveOneCredential = credential.length === 1;

  const resetFields = () => {
    setCompanyName("");
    setFirstName("");
    setLastName("");
    setIdentification("");
    setShowFieldsToComplete(false);
    setEmail("");
    setPhone("");
    setMerchantRegistration("");
    setResponsibleFor("");
    setBillingAddress("");
    setBillingPhone("");
    setBillingRegionCode("");
    setBillingCityCode("");
    setBillingPostalCode("");
    setBillingNeighborhood("");
    setBillingContactName("");
    setIdentificationTypeCode("");
    setResponsabilities("");
    setRegimeType("");
    setPersonType("");
    setCurrentTarget(null);
    setIsChecked(false);
    setIsCheckedCompleteData(false);
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const toPay = async (credentialId, orderServiceId) => {
    setGlobalCredentialId(credentialId);
    setGlobalServiceId(orderServiceId);
    if (isCheckedCompleteData === false) {
      const dataRquest = {
        paymentOrderId: orderServiceId,
        paymentCredentialId: credentialId,
        billingInformation: null,
      };
      const { payload } = await dispatch(getPrefente(dataRquest));
      if (payload.data?.initPoint) openUrl(payload.data.initPoint);
      handleClose();
      callback();
    } else {
      setShowFieldsToComplete(true);
    }
  };

  const handleChangeMs = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const initialValues = {
    paymentType: "",
  };

  const validationSchema = Yup.object().shape({
    paymentType: Yup.string().required("Campo requerido"),
  });

  const handleChange = (credentialSelected) => {
    setCurrentTarget(credentialSelected);
  };

  useEffect(() => {
    if (haveOneCredential) {
      toPay(credential[0].id, payment);
    }
  }, [isOpen]);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePRevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpenModalCompleteData = () => {
    setIsOpenCompleteDataModal(true);
  };

  const handleCloseModalCompleteData = () => {
    setIsOpenCompleteDataModal(false);
  };

  const endCompleteData = async () => {
    const dataRquest = {
      paymentOrderId: globalOrderServiceId,
      paymentCredentialId: globalCredentialId,
      billingInformation: {
        companyName,
        personType: personType.code,
        regimeType: regimeType.code,
        firstName,
        lastName,
        identification,
        digitCheck: !showDigitCheck ? "1" : digitCheck,
        identificationTypeCode: identificationTypeCode.code,
        email,
        phone,
        merchantRegistration,
        responsibleFor: responsibleFor.code,
        responsibilities: responsibilities.code,
        economicActivities: defaultEconomic,
        billingAddress,
        billingCountryName: "Colombia",
        billingCountryCode: "CO",
        billingRegionName: "Bogotá",
        billingRegionCode: billingRegionCode.code,
        billingCityName: "Bogotá, D.c.",
        billingCityCode: billingCityCode.code,
        billingPostalCode,
        billingNeighborhood,
        billingPhone,
        billingContactName,
      },
    };
    if (payType === 1) {
      const { payload } = await dispatch(getPrefente(dataRquest));
      if (payload.data?.initPoint) openUrl(payload.data.initPoint);
    } else {
      const dataRequest = {
        paymentOrderId: globalPaymentId,
        billingInformation: dataRquest.billingInformation,
      };
      await dispatch(generatePay(dataRequest));
    }
    handleClose();
    callback();
  };

  const getPaymentsMeans = async () => {
    const tenantId = localStorage.getItem("idTenant");
    const data = await getPersonalProfile();
    const isToken = localStorage.getItem("token");
    setCompanyName(data.firstName);
    setIdentificationTypeCode(data?.userProfile.identificationType);
    setIdentification(data?.userProfile.nuip);
    setEmail(data.email);
    setBillingAddress(data?.userProfile.address);
    setBillingPhone(data?.customerInfo.phoneNumber);
    setPhone(data?.customerInfo.phoneNumber);
    setBillingContactName(data?.customerInfo.name);
    setTenantData(data);

    const responsePaymentMeans = await axios.get(`${ENDPOINT}/paymentmeans`, {
      headers: { tenantId },
    });

    const personTypesC = await axios.get(`${ENDPOINT}/billinginformations/persontype`, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    const regimenType = await axios.get(`${ENDPOINT}/billinginformations/regimeType`, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    const responsabilitiesFor = await axios.get(`${ENDPOINT}/responsiblefor`, {
      headers: { tenantId },
    });
    const economicActivities = await axios.get(`${ENDPOINT}/economicactivities`, {
      headers: { tenantId },
    });
    const regions = await axios.get(`${ENDPOINT}/regions`, {
      headers: { tenantId },
    });
    const identificationTypes = await axios.get(
      `${ENDPOINT}/billinginformations/identificationtype`,
      {
        headers: { Authorization: `Bearer ${isToken}` },
      }
    );
    const responsabilitiesC = await axios.get(`${ENDPOINT}/billinginformations/responsibilities`, {
      headers: { Authorization: `Bearer ${isToken}` },
    });
    setGlobalResponsabilities(responsabilitiesC.data);
    setTypesIdentifications(identificationTypes.data);
    setGlobalRegions(regions.data);
    setActivitiesEconomic(economicActivities.data);
    setGlobalResponsabilitiesFor(responsabilitiesFor.data);
    setPersonTypes(personTypesC.data);
    setRegimenTypes(regimenType.data);
    setPaymentMeans(responsePaymentMeans.data);
  };

  const manualPayment = async (paymentOrderId) => {
    if (isCheckedCompleteData === false) {
      const dataRequest = { paymentOrderId };
      await dispatch(generatePay(dataRequest));
      handleClose();
      callback();
    } else {
      setShowFieldsToComplete(true);
    }
  };

  const validateStep1 = () => {
    if (
      personType === "" ||
      (showDigitCheck && companyName === "") ||
      (!showDigitCheck && (firstName === "" || lastName === "")) ||
      paymenType === "" ||
      regimeType === "" ||
      identification === "" ||
      (showDigitCheck && showDigitCheckIdentification && digitCheck === "") ||
      identificationTypeCode === ""
    ) {
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    if (
      email === "" ||
      phone === "" ||
      merchantRegistration === "" ||
      responsibleFor === "" ||
      responsibilities === "" ||
      personName === "" ||
      billingAddress === "" ||
      billingPhone === ""
    ) {
      return false;
    }
    return true;
  };

  const validateStep3 = () => {
    if (
      billingRegionCode === "" ||
      billingCityCode === "" ||
      billingPostalCode === "" ||
      billingNeighborhood === "" ||
      billingContactName === ""
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    getPaymentsMeans();
    // eslint-disable-next-line no-console
    console.log(tenantData);
  }, [isOpen]);

  const handleSubmit = async (paymentOrderId) => {
    setLoading(true);
    setGlobalPaymentId(paymentOrderId);
    // await dispatch(toPay(currentTarget.id, payment));
    switch (currentTarget.paymentGatewayCode) {
      case "MERCADO_PAGO": {
        setPayType(1);
        await toPay(currentTarget.id, paymentOrderId);
        break;
      }
      case "MERCADO_PAGO_API_KEY": {
        setPayType(1);
        await toPay(currentTarget.id, paymentOrderId);
        break;
      }
      case "PAGO_MANUAL": {
        setPayType(2);
        await manualPayment(paymentOrderId);
        const number = 1;
        if (number === 2) {
          handleOpenModalCompleteData();
        }
        break;
      }
      default: {
        alert.error("Por favor seleccione una forma de pago", { position: "top right" });
        break;
      }
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (billingRegionCode !== "") {
      const tenantId = localStorage.getItem("idTenant");
      const isToken = localStorage.getItem("token");
      const regions = await axios.get(`${ENDPOINT}/cities?regionCode=${billingRegionCode.code}`, {
        headers: { tenantId, Authorization: `Bearer ${isToken}` },
      });
      setGlobalCities(regions.data);
    }
  }, [billingRegionCode]);

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen && !haveOneCredential}
      fullWidth="true"
      onClose={() => handleClose()}
      aria-labelledby="responsive-dialog-title"
      sx={{ zIndex: 98, m: 0, p: 2 }}
    >
      {showFieldsToComplete ? (
        <DialogTitle>Información de la factura</DialogTitle>
      ) : (
        <DialogTitle>Metodo de pago</DialogTitle>
      )}
      <DialogContent>
        {showFieldsToComplete ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Stepper
                  activeStep={activeStep}
                  style={{
                    background: "#fffefe",
                    border: "none",
                    borderRadius: "0",
                    boxShadow: "none",
                    overflow: "hidden",
                  }}
                >
                  {activeStep === 0 && (
                    <Step
                      style={{
                        background: "#fffefe",
                        width: "100%",
                        border: "none",
                        borderRadius: "0",
                        boxShadow: "none",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          background: "#fffefe",
                          width: "100%",
                          border: "none",
                          borderRadius: "0",
                          boxShadow: "none",
                        }}
                      >
                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="personType"
                            value={personType}
                            name="personType"
                            onChange={(e, value) => {
                              if (value.code === "1") {
                                setShowDigitCheck(true);
                                setShowDigitCheckIdentification(true);
                                setIdentificationTypeCode({
                                  code: "31",
                                  name: "NIT",
                                });
                              } else {
                                setShowDigitCheck(false);
                              }
                              setPersonType(value);
                            }}
                            options={personTypes}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tipo de persona"
                                variant="standard"
                                required
                                fullWidth
                                name="personType"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        {showDigitCheck && (
                          <Grid item xs={6} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Nombre empresa"
                              name="companyName"
                              value={companyName}
                              onChange={(e) => {
                                setCompanyName(e.target.value);
                              }}
                              sx={{ width: "100%" }}
                              required
                              inputProps={{ type: "text", autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                        )}

                        {!showDigitCheck && (
                          <>
                            <Grid item xs={6} sm={6} md={6}>
                              <Field
                                as={TextField}
                                label="Nombre"
                                name="firstName"
                                sx={{ width: "100%" }}
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                                required
                                inputProps={{ type: "text", autoComplete: "off" }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                              />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Field
                                as={TextField}
                                label="Apellido"
                                name="lastName"
                                sx={{ width: "100%" }}
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                                required
                                inputProps={{ type: "text", autoComplete: "off" }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="paymentType"
                            value={paymenType?.name}
                            name="paymentType"
                            onChange={(e, value) => {
                              setPaymentType(value);
                            }}
                            options={paymentMeans}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tipo de pago"
                                variant="standard"
                                required
                                fullWidth
                                name="paymentType"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="regimeType"
                            value={regimeType?.name}
                            name="regimeType"
                            onChange={(e, value) => {
                              setRegimeType(value);
                            }}
                            options={regimenTypes}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tipo de régimen"
                                variant="standard"
                                required
                                fullWidth
                                name="regimeType"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="identificationTypeCode"
                            value={identificationTypeCode}
                            onChange={(e, value) => {
                              setIdentificationTypeCode(value);
                            }}
                            name="identificationTypeCode"
                            options={typesIdentifications}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tipo de identificación"
                                variant="standard"
                                required
                                fullWidth
                                name="identificationTypeCode"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={showDigitCheck ? 4 : 6}>
                          <Field
                            as={TextField}
                            label="Identificación"
                            name="identification"
                            sx={{ width: "100%" }}
                            value={identification}
                            onChange={(value) => {
                              setIdentification(value.target.value);
                            }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>

                        {showDigitCheck && showDigitCheckIdentification && (
                          <>
                            <Grid item xs={6} sm={6} md={1}>
                              <p style={{ marginTop: "10px", marginLeft: "20px" }}>-</p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={1}>
                              <Field
                                as={TextField}
                                label="DV"
                                name="digitCheck"
                                sx={{ width: "100%" }}
                                value={digitCheck}
                                onChange={(value) => {
                                  setDigitCheck(value.target.value);
                                }}
                                required
                                inputProps={{ type: "text", autoComplete: "off" }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item style={{ marginLeft: "80%" }}>
                          <MDBox mt={4} mb={1} pr={1}>
                            {activeStep === 2 ? (
                              <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                                Finalizar
                              </MDButton>
                            ) : (
                              <>
                                <Grid container>
                                  <Button
                                    color="secondary"
                                    style={{ marginLeft: "-770px" }}
                                    onClick={handlePRevStep}
                                    disabled
                                  >
                                    Volver
                                  </Button>

                                  <MDButton
                                    onClick={handleNextStep}
                                    variant="gradient"
                                    color="dark"
                                    style={{ marginLeft: "700px", width: "100px" }}
                                    disabled={!validateStep1()}
                                  >
                                    Continuar
                                  </MDButton>
                                </Grid>
                              </>
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Step>
                  )}
                  {activeStep === 1 && (
                    <Step style={{ background: "white", width: "100%" }}>
                      <Grid container spacing={2} style={{ background: "white", width: "100%" }}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Correo electrónico"
                            name="email"
                            value={email}
                            onChange={(value) => {
                              setEmail(value.target.value);
                            }}
                            sx={{ width: "100%" }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Teléfono"
                            name="phone"
                            value={phone}
                            onChange={(value) => {
                              setPhone(value.target.value);
                            }}
                            sx={{ width: "100%" }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Comerciante Registro"
                            name="merchantRegistration"
                            value={merchantRegistration}
                            onChange={(value) => {
                              setMerchantRegistration(value.target.value);
                            }}
                            sx={{ width: "100%" }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="responsibleFor"
                            defaultValue=""
                            name="responsibleFor"
                            value={responsibleFor?.name}
                            onChange={(e, value) => {
                              setResponsibleFor(value);
                            }}
                            options={globalResponsabilitiesFor}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Responsable de"
                                variant="standard"
                                required
                                fullWidth
                                name="responsibleFor"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="responsabilities"
                            defaultValue=""
                            name="responsabilities"
                            value={responsibilities?.name}
                            onChange={(e, value) => {
                              setResponsabilities(value);
                            }}
                            options={globalResponsabilities}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Responsabilidades"
                                variant="standard"
                                required
                                fullWidth
                                name="responsabilities"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="economicActivities"
                            multiple
                            value={personName}
                            onChange={handleChangeMs}
                            input={<OutlinedInput id="select-multiple-chip" label="Prueba" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            style={{
                              width: "100%",
                              minHeight: "46px",
                            }}
                          >
                            {activitiesEconomic.map((value) => (
                              <MenuItem key={value.name} value={value.name}>
                                {value.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Dirección de envío"
                            name="billingAddress"
                            value={billingAddress}
                            onChange={(value) => {
                              setBillingAddress(value.target.value);
                            }}
                            sx={{ width: "100%" }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Teléfono facturación"
                            name="billingPhone"
                            sx={{ width: "100%" }}
                            value={billingPhone}
                            onChange={(value) => {
                              setBillingPhone(value.target.value);
                            }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item style={{ marginLeft: "80%" }}>
                          <MDBox mt={4} mb={1} pr={1}>
                            {activeStep === 2 ? (
                              <MDButton type="submit" variant="gradient" color="dark" fullWidth>
                                Finalizar
                              </MDButton>
                            ) : (
                              <>
                                <Grid container>
                                  <Button
                                    color="secondary"
                                    style={{ marginLeft: "-770px" }}
                                    onClick={handlePRevStep}
                                  >
                                    Volver
                                  </Button>
                                  <MDButton
                                    onClick={handleNextStep}
                                    variant="gradient"
                                    color="dark"
                                    style={{ marginLeft: "700px", width: "100px" }}
                                    disabled={!validateStep2()}
                                  >
                                    Continuar
                                  </MDButton>
                                </Grid>
                              </>
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Step>
                  )}
                  {activeStep === 2 && (
                    <Step style={{ background: "#fffefe", width: "100%" }}>
                      <Grid container spacing={2} style={{ background: "#fffefe", width: "100%" }}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="billingRegionCode"
                            defaultValue=""
                            name="billingRegionCode"
                            value={billingRegionCode?.name}
                            onChange={(e, value) => {
                              setBillingRegionCode(value);
                            }}
                            options={globalRegions}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Región facturación"
                                variant="standard"
                                required
                                fullWidth
                                name="billingRegionCode"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Autocomplete
                            id="billingCityCode"
                            defaultValue=""
                            value={billingCityCode?.name}
                            onChange={(e, value) => {
                              setBillingCityCode(value);
                            }}
                            name="billingCityCode"
                            options={globalCities}
                            disabled={globalCities.length === 0}
                            getOptionLabel={(option) => option.name ?? option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Ciudad facturación"
                                variant="standard"
                                required
                                fullWidth
                                name="billingCityCode"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Código postal"
                            name="billingPostalCode"
                            value={billingPostalCode}
                            onChange={(value) => {
                              setBillingPostalCode(value.target.value);
                            }}
                            sx={{ width: "100%" }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Field
                            as={TextField}
                            label="Barrio facturación"
                            name="billingNeighborhood"
                            value={billingNeighborhood}
                            onChange={(value) => {
                              setBillingNeighborhood(value.target.value);
                            }}
                            sx={{ width: "100%" }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={12}>
                          <Field
                            as={TextField}
                            label="Nombre contacto"
                            name="billingContactName"
                            sx={{ width: "100%" }}
                            value={billingContactName}
                            onChange={(value) => {
                              setBillingContactName(value.target.value);
                            }}
                            required
                            inputProps={{ type: "text", autoComplete: "off" }}
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item style={{ marginLeft: "80%" }}>
                          <MDBox mt={4} mb={1} pr={1}>
                            {activeStep === 2 ? (
                              <>
                                <Button
                                  color="secondary"
                                  style={{ marginLeft: "-770px" }}
                                  onClick={handlePRevStep}
                                >
                                  Volver
                                </Button>
                                <MDButton
                                  type="submit"
                                  onClick={endCompleteData}
                                  variant="gradient"
                                  color="dark"
                                  fullWidth
                                  disabled={!validateStep3()}
                                  style={{ marginLeft: "690px", width: "100px" }}
                                >
                                  Finalizar
                                </MDButton>
                              </>
                            ) : (
                              <>
                                <Grid container>
                                  <Button
                                    color="secondary"
                                    style={{ marginLeft: "-770px" }}
                                    onClick={handlePRevStep}
                                  >
                                    Volver
                                  </Button>
                                  <MDButton
                                    onClick={handleNextStep}
                                    variant="gradient"
                                    color="dark"
                                    style={{ marginLeft: "600px", width: "100px" }}
                                  >
                                    Continuar
                                  </MDButton>
                                </Grid>
                              </>
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Step>
                  )}
                </Stepper>
              </Form>
            )}
          </Formik>
        ) : (
          <MDBox pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  disabled
                  label="Servicio a pagar"
                  type="text"
                  value={payment?.serviceName}
                  required
                  variant="standard"
                  fullWidth
                  name="dateTovisit"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TextField
                  disabled
                  label="Total a pagar"
                  value={payment?.valueToPay}
                  type="text"
                  required
                  variant="standard"
                  fullWidth
                  name="dateTovisit"
                />
              </Grid>
              <Grid item xs={4} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      verticalAlign="middle"
                    >
                      Seleccione forma de pago
                    </MDTypography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={1}>
                      {credential.map((item) => (
                        <Grid item mt={2} p={2} xs={3} sm={3} md={3}>
                          <Card>
                            <CardActionArea onClick={() => handleChange(item)}>
                              <Grid container spacing={2}>
                                <Grid item xs={10}>
                                  <MDBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="lg"
                                  >
                                    <MDBox height="100%">
                                      <Checkbox
                                        onClick={() => {
                                          setIsChecked(true);
                                        }}
                                        checked={currentTarget?.id === item.id}
                                      />
                                    </MDBox>
                                    <MDBox height="100%">
                                      <MDTypography
                                        variant="caption"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                      >
                                        {item.accountName}
                                        {
                                          filterByCode(paymentsGateway, item.paymentGatewayCode)
                                            ?.name
                                        }
                                      </MDTypography>
                                    </MDBox>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </DialogContent>
      {showFieldsToComplete && isChecked ? (
        <></>
      ) : (
        <DialogActions>
          {isChecked && (
            <>
              <Grid container>
                <Grid item>
                  <Switch
                    onClick={() => {
                      setIsCheckedCompleteData(!isCheckedCompleteData);
                    }}
                    checked={isCheckedCompleteData}
                  />
                </Grid>

                <Grid item>
                  <MDBox>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="#1B4E7C"
                      sx={{ cursor: "pointer" }}
                    >
                      Llenar datos factura.
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox>
                    <MDTypography
                      variant="caption"
                      fontWeight="bold"
                      color="red"
                      sx={{ cursor: "pointer", marginLeft: "10px", color: "red" }}
                    >
                      De lo contrario, podrán ser llenados por el administrador en otro momento
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </>
          )}
          <MDButton onClick={() => handleClose()} variant="outlined" color="secondary">
            Cancelar
          </MDButton>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <MDButton
              onClick={() => handleSubmit(payment?.id)}
              disabled={!currentTarget}
              type="submit"
              variant="gradient"
              color="dark"
              size="small"
            >
              Realizar pago
            </MDButton>
          )}
        </DialogActions>
      )}
      <CompleteBillDataDialog
        open={isOpenCompleteDataModal}
        onClose={handleCloseModalCompleteData}
      />
    </Dialog>
  );
}

ModalToPay.defaultProps = {
  payment: null,
  paymentsGateway: [],
  credential: [],
};

ModalToPay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  credential: PropTypes.objectOf(),
  payment: PropTypes.objectOf(),
  paymentsGateway: PropTypes.objectOf(),
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ModalToPay;
