import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import UpdateIcon from "@mui/icons-material/Update";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Icon, Card, CircularProgress } from "@mui/material";
import Switch from "@mui/material/Switch";
import { filterByCode } from "utils/utils";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import TableMui from "../../components/TableMui/TableMui";
import MDButtonCustomByTenant from "../../components/MDButton/MDButtonCustomByTenant";
import {
  searchCredentialsEv,
  changeStatusCredentials,
} from "../../store/paymentCredentials/actions";
import { getPaymentGateway } from "../../store/paymentGateway/actions";
import DialogCreateCredential from "./components/DialogCreateCredential";
import DialogTransactionMercadoPago from "./components/DialogsTransaction/DialogTransactionMercadoPago";
import { useQuery } from "../../utils/utils";
import DialogEditCredentialMercadoPago from "./components/DialogsTransaction/DialogEditCredentialMercadoPago";
import DialogEditRunway from "./DialogEditRunway";

function MyPaymentGateways() {
  const dispatch = useDispatch();
  const query = useQuery();
  const [queryItem, setQueryItem] = useState(query.get("code"));
  const { primaryColor, secondaryColor } = useSelector(({ company }) => company.tenantConfig);
  const [rowCount, setRowCount] = useState(5);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [isOpenDialogCreate, setIsOpenDialogCreate] = useState(false);
  const [credentialToEdit, setcredentialToEdit] = useState(false);
  const [isOpenEditName, setIsOpenEditName] = useState(false);
  const [openEditRunway, setOpenEditRunway] = useState(false);

  const [loading, setLoading] = useState(false);
  const credentials = useSelector(({ paymentCredentials }) => paymentCredentials.data);
  const paymentsGateway = useSelector(({ paymentGateway }) => paymentGateway.data);

  const searchMyCredentials = async () => {
    setLoading(true);
    const filsters = {
      advancedSearch: {
        fields: [],
        keyword: "",
      },
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
      advancedFilter,
    };
    const example = await dispatch(searchCredentialsEv(filsters));
    setDataTable(example);
    setLoading(false);
  };

  const handlePageSize = async (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleOpenEditName = (newCredential) => {
    setcredentialToEdit(newCredential);
    setIsOpenEditName(true);
  };

  const handleCloseEditName = () => {
    setcredentialToEdit(null);
    setIsOpenEditName(false);
  };

  const handleOpenEditRunway = () => {
    setOpenEditRunway(true);
  };

  const handleCloseEditRunway = () => {
    handleOpenEditRunway();
    setOpenEditRunway(false);
  };

  const handleOpenEditCredentials = (newCredential) => {
    setcredentialToEdit(newCredential);
    handleOpenEditRunway();
  };

  const handleChangePage = async (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (dataTable !== null && dataTable !== undefined) {
      setRowCount(dataTable.totalCount);
    }
  }, [dataTable]);

  useEffect(() => {
    searchMyCredentials();
  }, [page, pageSize, advancedFilter]);

  const handleChangeStatusCredentials = async (id) => {
    await dispatch(changeStatusCredentials(id));
    setIsOpen(!isOpen);
    await searchMyCredentials();
  };
  const columsTable = [
    { field: "id", headerName: "id", width: 220, hide: true },
    {
      field: "accountName",
      headerName: "Nombre Pasarela",
      type: "string",
      fieldRef: "accountName",
      width: 300,
    },
    {
      field: "paymentGatewayCode",
      headerName: "Tipo Pasarela",
      type: "string",
      fieldRef: "paymentGatewayCode",
      width: 300,
      renderCell: ({ value }) => {
        if (credentials.length > 0) {
          <>
            <p>{filterByCode(paymentsGateway, value.id)?.name}</p>
          </>;
        } else {
          <>
            <p>ddddd</p>
          </>;
        }
      },
    },
    {
      field: "status",
      headerName: "Estado",
      type: "boolean",
      fieldRef: "status",
      filterOptions: [
        { name: "Activo", value: true },
        { name: "Inactivo", value: false },
      ],
      width: 120,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Switch
              checked={row.status}
              onChange={() => handleChangeStatusCredentials(row.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </>
        );
        return template;
      },
    },
    {
      field: "acctions",
      headerName: "Acciones",
      width: 210,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Tooltip title="Actualizar nombre">
              <IconButton
                onClick={() =>
                  row?.paymentGatewayCode === "MERCADO_PAGO"
                    ? handleOpenEditName(row)
                    : handleOpenEditCredentials(row)
                }
                aria-label="delete"
                size="medium"
              >
                <UpdateIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        );
        return template;
      },
    },
  ];

  const dispatchGetpaymentGateway = async () => {
    await dispatch(getPaymentGateway());
  };

  const handleCangeStateDialog = () => setIsOpenDialogCreate(!isOpenDialogCreate);
  useEffect(() => {
    (async () => {
      await searchMyCredentials();
      await dispatchGetpaymentGateway();
    })();
  }, []);

  // const renderBody = () => {
  //   const template = credentials.length ? (
  //     credentials.map((row, index) => (
  //       // eslint-disable-next-line react/no-array-index-key
  //       <Grid key={index} item xs={12} sm={4} md={4}>
  //         <Card>
  //           <CardPaymentGateway
  //             paymentsGateway={paymentsGateway}
  //             credential={row}
  //             callback={() => searchMyCredentials()}
  //           />
  //         </Card>
  //       </Grid>
  //     ))
  //   ) : (
  //     <></>
  //   );
  //   return template;
  // };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <MDBox my={3}>
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
              <MDButton
                onClick={() => handleCangeStateDialog()}
                variant="gradient"
                color="info"
                sx={() => MDButtonCustomByTenant(primaryColor, secondaryColor)}
              >
                <Icon>add</Icon>&nbsp; Agregar Pasarela de pago
              </MDButton>
            </MDBox>
          </MDBox>
          <Grid container spacing={3}>
            {loading ? (
              <Grid item xs={12} md={12}>
                <MDBox my={3}>
                  {" "}
                  <MDBox display="flex" justifyContent="center" alignItems="flex-center" mb={2}>
                    {" "}
                    <CircularProgress />
                  </MDBox>
                </MDBox>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {dataTable !== null && (
                  <Card>
                    <TableMui
                      paginationMode="server"
                      columns={columsTable}
                      rows={dataTable.data}
                      rowCount={rowCount}
                      pagination
                      page={page}
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 20]}
                      loading={loading}
                      onPageChange={handleChangePage}
                      onPageSizeChange={handlePageSize}
                      disableSelectionOnClick
                      checkboxSelection={false}
                      getDataSdvancedFilter={(filter) => {
                        setAdvancedFilter(filter);
                      }}
                      filters={filters}
                      updateFilters={(newArrayFilters) => setFilters(newArrayFilters)}
                      autoHeight
                    />
                  </Card>
                )}
              </Grid>
            )}
          </Grid>
          <DialogCreateCredential
            onClose={() => handleCangeStateDialog()}
            open={isOpenDialogCreate}
            paymentsGateway={paymentsGateway}
            callback={() => searchMyCredentials()}
          />
          <DialogEditCredentialMercadoPago
            credential={credentialToEdit}
            open={isOpenEditName}
            callback={searchMyCredentials}
            onClose={handleCloseEditName}
          />
          <DialogEditRunway
            openEditRunway={openEditRunway}
            callback={searchMyCredentials}
            handleCloseEditRunway={handleCloseEditRunway}
            credential={credentialToEdit}
          />
        </Grid>
      </Grid>
      <DialogTransactionMercadoPago
        code={queryItem}
        open={Boolean(queryItem)}
        onClose={() => {
          setQueryItem(null);
          searchMyCredentials();
        }}
      />
    </DashboardLayout>
  );
}

export default MyPaymentGateways;
