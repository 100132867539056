// eslint-disable-next-line import/named
import { SET_DATA_TYPEDOCUMENTS } from "./types";
import {
  getListTipeDocumentsService,
  changeStatusService,
  deleteTipeDocumentsService,
  saveTypeDocumentService,
  UpdateTypeDocumentService,
} from "../../services/tipeDocuments/TipeDocumentsService";

// eslint-disable-next-line import/prefer-default-export
export const getTipeDocuments = (filters) => async (dispatch) => {
  const data = await getListTipeDocumentsService(filters);
  dispatch({ type: SET_DATA_TYPEDOCUMENTS, payload: data });
};

export const changeStatus = (id) => async () => {
  await changeStatusService(id);
};

export const deleteTipeDocuments = (id) => async () => {
  await deleteTipeDocumentsService(id);
};

export const saveTypeDocument = (dataDocument) => async () => {
  await saveTypeDocumentService(dataDocument);
};

export const UpdateTypeDocument = (dataDocumentUpdate, id) => async () => {
  await UpdateTypeDocumentService(dataDocumentUpdate, id);
};
