import { RESET_STATES } from "../allStorages/types";
import { SET_DATA_RESOLUTION } from "./types";

export const RESOLUTION_INITIAL_STATE = {
  dataResolutions: [],
  totalCount: 5,
};

export const resolution = (state = RESOLUTION_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DATA_RESOLUTION:
      return {
        ...state,
        dataResolutions: action.payload.data,
        totalCount: action.payload.totalCount,
      };
    case RESET_STATES:
      return RESOLUTION_INITIAL_STATE;
    default:
      return state;
  }
};
