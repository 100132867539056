// eslint-disable-next-line import/named
import { SET_DATA_CREDENTIALS, SET_ID_CREDENTIAL } from "./types";
import {
  saveCredentialService,
  getCredentialService,
  UpdateCredentialService,
  changeStatusService,
} from "../../services/Credential/CredentialService";

// eslint-disable-next-line import/prefer-default-export
export const saveCredentialAction = (dataCredential) => async (dispatch) => {
  const idCredential = await saveCredentialService(dataCredential);
  dispatch({ type: SET_ID_CREDENTIAL, payload: idCredential });
};

export const UpdateCredentialAction = (id, dataCredential) => async () => {
  await UpdateCredentialService(id, dataCredential);
};

export const getCredentials = (filters) => async (dispatch) => {
  const data = await getCredentialService(filters);
  dispatch({ type: SET_DATA_CREDENTIALS, payload: data });
};

export const changeStatus = (idChange) => async () => {
  await changeStatusService(idChange);
};

export const CleanIdCredentialAction = () => (dispatch) => {
  dispatch({ type: SET_ID_CREDENTIAL, payload: { data: "vacio" } });
};
