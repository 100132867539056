import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import ViewPdf from "components/ViewPdf";
import FooterCardCustomer from "../../componets/FooterCardCustomer";

function CardsServices({ servicio, callback }) {
  const [controller] = useMaterialUIController();
  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const handleCloseViewPdf = () => setOpenOrderDetails(false);

  const handleOpenViewPdf = () => {
    setOpenOrderDetails(true);
  };
  const { darkMode } = controller;
  //   const dispatch = useDispatch();

  const handleChange = (id) => {
    callback(id);
  };

  return (
    <MDBox
      borderRadius="lg"
      mt={2}
      coloredShadow="dark"
      bgColor={darkMode ? "transparent" : "grey-100"}
    >
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "rgba(52, 52, 52, 0)",
          boxShadow: 0,
        }}
      >
        <CardActionArea sx={{ borderRadius: "lg" }} onClick={() => handleChange(servicio)}>
          <CardContent style={{ backgroundColor: "white" }}>
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={10} md={10}>
                {" "}
                <MDBox width="100%" display="flex" flexDirection="column">
                  <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant="caption" color="text">
                      Fecha:&nbsp;&nbsp;&nbsp;
                      <MDTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        5/09/2022
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant="caption" color="text">
                      Valor:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        $40.300
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="caption" color="text">
                      Creada por:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        Everth Llanos
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      <FooterCardCustomer>
        {" "}
        <MDBox width="100%">
          <Grid container spacing={0.5}>
            <Grid xs>
              <MDBox
                display="flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <MDButton variant="text" onClick={handleOpenViewPdf} color="dark">
                  <Icon>send </Icon>&nbsp;Reenviar
                </MDButton>
              </MDBox>
            </Grid>
            <Grid xs>
              <MDBox
                display="flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <MDButton variant="text" onClick={handleOpenViewPdf} color="dark">
                  <Icon>visibility </Icon>&nbsp;Ver factura
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </FooterCardCustomer>
      <ViewPdf
        open={openOrderDetails}
        data="https://architectcoders.com/wp-content/uploads/2019/08/principios-solid-devexperto.pdf"
        onClose={handleCloseViewPdf}
      />
    </MDBox>
  );
}
CardsServices.defaultProps = {};
CardsServices.propTypes = {
  callback: PropTypes.func.isRequired,
  servicio: PropTypes.shape({
    id: PropTypes.string,
    certificateId: PropTypes.string,
    description: PropTypes.string,
    generateCertification: PropTypes.bool,
    generateMonetaryValue: PropTypes.bool,
    isActive: PropTypes.bool,
    nameService: PropTypes.string,
    strategy: PropTypes.string,
    survey: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    targetAudiences: PropTypes.string,
    timeCollectionMoney: PropTypes.string,
    validity: PropTypes.shape({ startDate: PropTypes.string, endDate: PropTypes.string }),
  }).isRequired,
};

export default CardsServices;
