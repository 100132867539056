import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentsIcon from "@mui/icons-material/Payments";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorIcon from "@mui/icons-material/Error";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { searchCredentialsActives } from "store/paymentCredentials/actions";
import { getPaymentGateway } from "store/paymentGateway/actions";
import TableMui from "../../../components/TableMui/TableMui";
import {
  getPaymentGenerated,
  getHistoryPaymentOrder,
  paymentOrderByIdAction,
} from "../../../store/paymentsGenerated/actions";
import StatusCell from "../../../components/StatusCell/StatusCell";
import ViewPdf from "../../../components/ViewPdf";
import DialogGeneratePay from "./DialogGeneratePay";
import DialogHistory from "./DialogHistory";
import CompleteBillDataDialog from "./CompleteBillInformation";
import { getCertificatePdf } from "../../../store/certificate/actions";
import DialogDetailById from "./DialogDetailById";

function ListPaymentsGenerated() {
  const dispatch = useDispatch();
  const [rowCount, setRowCount] = useState(5);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openDialogToCreatePay, setOpenDialogToCreatePay] = useState(false);
  const [openPdfBilling, setOpenPdfBilling] = useState(false);
  const [billingUrl, setBillingUrl] = useState("");
  const [currentPaymentSelect, setCurrentPaymentSelect] = useState(null);
  const [openDialogHistory, setOpenDialogHistory] = useState(false);
  const [isOpenCompleteDataModal, setIsOpenCompleteDataModal] = useState(false);
  const [paymetOrderSelectedId, setPaymentOrderSelectedId] = useState("");
  const [openDetailById, setOpenDetailById] = useState(false);
  const listPayments = useSelector(({ paymentGenerated }) => paymentGenerated.payments);
  const history = useSelector(({ paymentGenerated }) => paymentGenerated.history);
  const credentials = useSelector(({ paymentCredentials }) => paymentCredentials.data);
  const paymentsGateway = useSelector(({ paymentGateway }) => paymentGateway.data);
  const detailPaymentById = useSelector(
    ({ paymentGenerated }) => paymentGenerated.paymentdetailById
  );
  const [filters, setFilters] = useState([]);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const handlePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleOpenModalCompleteData = (id, row) => {
    setPaymentOrderSelectedId(id);
    setCurrentPaymentSelect(row);
    setIsOpenCompleteDataModal(true);
  };

  const handleOpenModalPdfBilling = async (url) => {
    const blob = await dispatch(getCertificatePdf(url));
    const bloobUrl = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = bloobUrl;
    link.download = `factura.pdf`;
    setBillingUrl(link);
    setOpenPdfBilling(true);
  };

  const handleCloseModalPdfBilling = () => {
    setOpenPdfBilling(false);
  };

  const handleCloseModalCompleteData = () => {
    setIsOpenCompleteDataModal(false);
  };

  const handleChangePage = (newPage) => setPage(newPage);

  const handleToCreatePay = (payment) => {
    setCurrentPaymentSelect(payment);
    setOpenDialogToCreatePay(true);
  };

  const handleFilter = async () => {
    const dataSearch = {
      advancedFilter,
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
    };
    const payload = await dispatch(getPaymentGenerated(dataSearch));
    setRowCount(payload.totalCount);
  };

  const searchMyCredentials = async () => {
    const dataSearch = {
      pageNumber: 0,
      pageSize: 0,
      orderBy: [],
    };
    await dispatch(searchCredentialsActives(dataSearch));
  };
  const dispatchGetpaymentGateway = async () => {
    await dispatch(getPaymentGateway());
  };

  const getHistoryPaymentOrderAction = async (id, row) => {
    await dispatch(getHistoryPaymentOrder(id));
    setCurrentPaymentSelect(row);
    setOpenDialogHistory(true);
  };

  const getPaymentOrderById = async (id) => {
    await dispatch(paymentOrderByIdAction(id));
    setOpenDetailById(true);
  };

  const closeDialogDetailById = async () => {
    setOpenDetailById(false);
  };

  const closeDialogHistory = () => {
    setOpenDialogHistory(false);
  };

  useEffect(() => {
    searchMyCredentials();
    dispatchGetpaymentGateway();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [page, pageSize, advancedFilter]);

  const columns = [
    { field: "id", headerName: "id", width: 220, hide: true },
    {
      field: "paymentOrderNumber",
      headerName: "#",
      type: "number",
      fieldRef: "paymentOrderNumber",
      width: 50,
    },
    {
      field: "serviceName",
      headerName: "Servicios a pagar",
      type: "string",
      fieldRef: "serviceName",
      minWidth: 300,
    },
    {
      field: "CustomerName",
      headerName: "Nombre del cliente",
      type: "string",
      fieldRef: "customer.name",
      minWidth: 300,
      renderCell: ({ row }) => row.customer.name,
    },
    {
      field: "isPaid",
      headerName: "Estado del pago",
      type: "boolean",
      fieldRef: "isPaid",
      width: 200,
      renderCell: ({ value }) => {
        let status;
        if (value) {
          status = <StatusCell icon="done" color="success" status="Pagada" />;
        } else {
          status = <StatusCell icon="notifications" color="warning" status="Pendiente de pago" />;
        }
        return status;
      },
      filterOptions: [
        {
          name: "Pagada",
          value: true,
        },
        {
          name: "Pendiente de pago",
          value: false,
        },
      ],
    },
    {
      field: "electronicInvoiceState",
      headerName: "Estado Factura",
      type: "string",
      fieldRef: "electronicInvoiceDocument",
      minWidth: 170,
      renderCell: ({ row }) => {
        if (row.electronicInvoiceDocument === null) return "No emitida";
        if (row.electronicInvoiceDocument.documentStatus === "ERROR_MANDANTE")
          return "Error Mandante";
        if (
          row.electronicInvoiceDocument.documentStatus === "INPROGRESS" ||
          row.electronicInvoiceDocument.documentStatus === "INPROGRESS_SEND"
        )
          return "En progreso";
        if (row.electronicInvoiceDocument.documentStatus === "ERROR_PROVIDER")
          return "Error Proveedor";
        if (row.electronicInvoiceDocument.documentStatus === "ERROR_DIAN") return "Error Dian";
        if (row.electronicInvoiceDocument.documentStatus === "ERROR_BILLINGINFORMATION")
          return "Error Información de Factura";
        if (row.electronicInvoiceDocument.documentStatus === "ERROR_MANDANTE")
          return "Error Mandante";
        if (row.electronicInvoiceDocument.documentStatus === "ERROR_LOCAL") return "Error Local";
        if (row.electronicInvoiceDocument.documentStatus === "PENDING") return "Pendiente";
        return "Generada";
      },
      filterOptions: [
        {
          name: "No emitida",
          value: null,
        },
        {
          name: "Error Mandante",
          value: "ERROR_MANDANTE",
        },
        {
          name: "En progreso",
          value: "INPROGRESS" || "INPROGRESS_SEND",
        },
        {
          name: "Error Proveedor",
          value: "ERROR_PROVIDER",
        },
        {
          name: "Error Dian",
          value: "ERROR_DIAN",
        },
        {
          name: "Error Información de Factura",
          value: "ERROR_BILLINGINFORMATION",
        },
        {
          name: "Error Mandante",
          value: "ERROR_MANDANTE",
        },
        {
          name: "Error Local",
          value: "ERROR_LOCAL",
        },
        {
          name: "Pendiente",
          value: "PENDING",
        },
      ],
    },
    {
      field: "valueToPay",
      headerName: "Valor a pagar",
      type: "number",
      fieldRef: "valueToPay",
      width: 200,
    },
    {
      field: "acctions",
      headerName: "Acciones",
      width: 210,
      renderCell: ({ row }) => {
        const template = (
          <>
            <Tooltip title="Registrar pago">
              <IconButton
                disabled={row.isPaid}
                onClick={() => handleToCreatePay(row)}
                aria-label="delete"
                size="medium"
              >
                <PaymentsIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver detalle de pago">
              <IconButton
                onClick={() => getHistoryPaymentOrderAction(row.id, row)}
                aria-label="delete"
                size="medium"
              >
                <VisibilityIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver factura">
              <IconButton
                onClick={() => handleOpenModalPdfBilling(row.electronicInvoiceDocument.urlPdf)}
                aria-label="delete"
                disabled={
                  row.electronicInvoiceDocument === null ||
                  row.electronicInvoiceDocument.documentStatus !== "GENERATED"
                }
                size="medium"
              >
                <DownloadIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Completar datos factura">
              <IconButton
                aria-label="delete"
                size="medium"
                disabled={!row.isPaid}
                onClick={() => {
                  handleOpenModalCompleteData(row.id, row);
                }}
              >
                <FileCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Observaciones de factura">
              <IconButton
                aria-label="delete"
                size="medium"
                onClick={() => {
                  getPaymentOrderById(row.id);
                }}
              >
                <ErrorIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        );
        return template;
      },
    },
  ];

  return (
    <>
      <TableMui
        paginationMode="server"
        columns={columns}
        rows={listPayments}
        disableSelectionOnClick
        checkboxSelection={false}
        autoHeight
        rowCount={rowCount}
        pagination
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        onPageChange={handleChangePage}
        onPageSizeChange={handlePageSize}
        filters={filters}
        updateFilters={(newArrayFilters) => setFilters(newArrayFilters)}
        getDataSdvancedFilter={(filter) => {
          setAdvancedFilter(filter);
        }}
      />
      <DialogGeneratePay
        open={openDialogToCreatePay}
        credentials={credentials}
        paymentsGateway={paymentsGateway}
        onClose={() => false}
        payment={currentPaymentSelect}
        callback={handleFilter}
      />
      <DialogHistory
        openDialogHistory={openDialogHistory}
        closeDialogHistory={closeDialogHistory}
        history={history}
        payment={currentPaymentSelect}
      />
      <CompleteBillDataDialog
        open={isOpenCompleteDataModal}
        onClose={handleCloseModalCompleteData}
        paymentOrderId={paymetOrderSelectedId}
        payment={currentPaymentSelect}
      />
      <DialogDetailById
        openDetailById={openDetailById}
        closeDialogDetailById={closeDialogDetailById}
        detailPaymentById={detailPaymentById}
      />
      <ViewPdf
        open={openPdfBilling}
        onClose={handleCloseModalPdfBilling}
        type={3}
        data={
          billingUrl !== null
            ? billingUrl
            : "https://profeuttec.yolasite.com/resources/Patrones%20de%20dise%C3%B1o%20-%20Erich%20Gamma.pdf"
        }
        tittlePdf="Factura"
      />
    </>
  );
}
export default ListPaymentsGenerated;
