import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { Field, Form, Formik } from "formik";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import Switch from "@mui/material/Switch";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { keyPressF } from "../../../../utils/utils";

function DialogRegisterDocument({
  open,
  handleCloseDocument,
  handleIsActive,
  active,
  requered,
  handleIsRequered,
  handleExpiration,
  expiration,
  handleSave,
  isEdit,
  dataEdit,
}) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    nDays: Yup.number().required("Campo requerido."),
  });

  const validationSchema2 = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
  });

  return (
    <>
      <Dialog open={open} onClose={handleCloseDocument} fullWidth="true" maxWidth="sm">
        <Formik
          initialValues={{
            code: isEdit ? dataEdit.code : "",
            name: isEdit ? dataEdit.name : "",
            nDays: isEdit ? dataEdit.nDays : "",
          }}
          validationSchema={expiration ? validationSchema : validationSchema2}
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogTitle>{isEdit ? "Editar Tipo de documento" : "Nuevo documento"}</DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Field
                      name="code"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Código"
                      fullWidth
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Field
                      name="name"
                      type="text"
                      as={MDInput}
                      variant="standard"
                      label="Nombre"
                      fullWidth
                      error={errors.name && touched.name}
                      helperText={touched.name && errors.name}
                      inputProps={{ autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <Typography variant="body2" gutterBottom>
                      Activo
                    </Typography>
                    <Switch checked={active} onChange={handleIsActive} />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <Typography variant="body2" gutterBottom>
                      Requerido
                    </Typography>
                    <Switch checked={requered} onChange={handleIsRequered} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography variant="body2" gutterBottom>
                      Fecha de expiración
                    </Typography>
                    <Switch checked={expiration} onChange={handleExpiration} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <MDBox mt={2}>
                      <Field
                        as={MDInput}
                        label="Días de vigencia"
                        variant="standard"
                        type="number"
                        onKeyPress={keyPressF}
                        fullWidth
                        disabled={!expiration}
                        name="nDays"
                        error={!expiration ? null : errors.nDays && touched.nDays}
                        helperText={!expiration ? null : touched.nDays && errors.nDays}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDButton onClick={handleCloseDocument} variant="outlined" color="info">
                  Cancelar
                </MDButton>
                <MDButton type="submit" variant="gradient" color="info">
                  Guardar
                </MDButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

DialogRegisterDocument.defaultProps = {
  open: false,
  active: false,
  requered: false,
  expiration: false,
  isEdit: false,
  dataEdit: {},
};

DialogRegisterDocument.propTypes = {
  open: PropTypes.bool,
  handleCloseDocument: PropTypes.func.isRequired,
  handleIsActive: PropTypes.func.isRequired,
  active: PropTypes.bool,
  requered: PropTypes.bool,
  handleIsRequered: PropTypes.func.isRequired,
  handleExpiration: PropTypes.func.isRequired,
  expiration: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  dataEdit: PropTypes.objectOf(PropTypes.string),
};
export default DialogRegisterDocument;
