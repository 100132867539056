// eslint-disable-next-line import/named
import { SET_DATA_RESOLUTION } from "./types";
import {
  saveResolutionService,
  getResolutionsService,
  changeStatusService,
  updateDataResolution,
} from "../../services/Resolution/ResolutionService";

// eslint-disable-next-line import/prefer-default-export
export const saveResolutionAction = (dataCredential) => async () => {
  await saveResolutionService(dataCredential);
};

export const updateResolutionAction = (id, dataCredential) => async () => {
  await updateDataResolution(id, dataCredential);
};

export const getResolutionActions = (filters) => async (dispatch) => {
  const data = await getResolutionsService(filters);
  dispatch({ type: SET_DATA_RESOLUTION, payload: data });
};

export const changeStatusResolution = (idChange) => async () => {
  await changeStatusService(idChange);
};
