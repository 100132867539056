import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import { useAlert } from "react-alert";
import MDButtonCustomByTenant from "components/MDButton/MDButtonCustomByTenant";
import Card from "@mui/material/Card";
import DialogRegisterCredential from "./components/DialogRegisterCredential";
import { getProvidersAction } from "../../store/provider/actions";
import {
  saveCredentialAction,
  getCredentials,
  UpdateCredentialAction,
  changeStatus,
  CleanIdCredentialAction,
} from "../../store/credential/actions";
import {
  saveResolutionAction,
  getResolutionActions,
  changeStatusResolution,
} from "../../store/Resolution/actions";
import TableCredential from "./components/TableCredential";
import DialogListResolution from "./components/DialogListResolution";

function Credentials() {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [typeDialog, setTypeDialog] = useState("");
  const [codeProvider, setCodeProvider] = useState("");
  const [filters, setFilters] = useState([]);
  const [localResolution, setLocalResolution] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [dataEdit, setDataEdit] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [pageResolution, setPageResolution] = useState(0);
  const [pageSizeResolution, setPageSizeResolution] = useState(5);
  const [loadingResolution, setLoadingResolution] = useState(false);
  const [filtersResolution, setFiltersResolution] = useState([]);
  const [idResolutionModal, setIdResolution] = useState(null);
  const [advancedFilterResolution, setAdvancedFilterResolution] = useState(null);

  const listProvider = useSelector(({ provider }) => provider.data);
  const { primaryColor } = useSelector(({ company }) => company.tenantConfig);
  const listCredential = useSelector(({ credential }) => credential.dataCredential);
  const rowCount = useSelector(({ credential }) => credential.totalCount);
  const idCredentialNew = useSelector(({ credential }) => credential.idCredential);
  const listResolutions = useSelector(({ resolution }) => resolution.dataResolutions);
  const rowCountResolution = useSelector(({ credential }) => credential.totalCount);

  const handleClickOpen = () => {
    setTypeDialog("registro");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLocalResolution(false);
    setCodeProvider(false);
  };

  const getListCredentials = useCallback(async () => {
    const dataSearch = {
      advancedFilter,
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
    };
    setLoading(true);
    await dispatch(getCredentials(dataSearch));
    setLoading(false);
  }, [pageSize, page, advancedFilter]);

  const getProviders = useCallback(async () => {
    await dispatch(getProvidersAction());
  }, []);

  useEffect(() => {
    getProviders();
  }, []);

  useEffect(() => {
    getListCredentials();
  }, [pageSize, page, advancedFilter]);

  const handleCredential = async (values) => {
    const data = {
      id: dataEdit === "registro" ? "" : dataEdit.id,
      name: values.name,
      username: values.username,
      accessKey: values.accessKey,
      providerCode: codeProvider,
      hasLocalResolution: localResolution,
    };
    if (typeDialog === "registro") {
      setLoadingButton(true);
      await dispatch(saveCredentialAction(data));
      setLoadingButton(false);
      if (!data.hasLocalResolution) {
        handleClose();
        dispatch(CleanIdCredentialAction());
      }
      alert.show("Credencial creada", {
        position: "top right",
      });
      await getListCredentials();
    } else {
      setLoadingButton(true);
      await dispatch(UpdateCredentialAction(dataEdit.id, data));
      setLoadingButton(false);
      if (!data.hasLocalResolution) {
        handleClose();
        dispatch(CleanIdCredentialAction());
      }
      alert.show("Credencial actualizada", {
        position: "top right",
      });
      await getListCredentials();
    }
  };

  const handleEditCredential = (id) => {
    setTypeDialog("Editar");
    setOpen(true);
    const filterCredentialEdit = listCredential.find((credential) => credential.id === id);
    setDataEdit(filterCredentialEdit);
    setLocalResolution(filterCredentialEdit.hasLocalResolution);
    setCodeProvider(filterCredentialEdit.providerCode);
  };

  const handleChangeStatus = async (idChange) => {
    await dispatch(changeStatus(idChange));
    await getListCredentials();
  };

  const handleCreateResolition = async (values) => {
    const data = {
      electronicInvoiceCredentialId:
        values.electronicInvoiceCredentialId === ""
          ? idCredentialNew
          : values.electronicInvoiceCredentialId,
      name: values.name,
      key: values.key,
      prefix: values.prefix,
      number: values.number,
      rangeinitial: values.rangeinitial,
      rangeFinal: values.rangeFinal,
      validFrom: values.validFrom,
      validUntil: values.validUntil,
    };
    await dispatch(saveResolutionAction(data));
    alert.show("Resolución creada", {
      position: "top right",
    });
    handleClose();
    dispatch(CleanIdCredentialAction());
    await getListCredentials();
  };

  const handleGetResolutionsByCredential = async (idCredential) => {
    setOpenList(true);
    const dataSearch = {
      advancedFilter,
      keyword: "",
      pageNumber: page + 1,
      pageSize,
      orderBy: [],
      isActive: true,
      electronicInvoiceCredentialId: idCredential,
    };
    setIdResolution(idCredential);
    setLoadingResolution(true);
    await dispatch(getResolutionActions(dataSearch));
    setLoadingResolution(false);
  };

  const handleCloseDialogList = () => {
    setOpenList(false);
  };

  // useEffect(() => {
  //   handleGetResolutionsByCredential();
  // }, [pageSizeResolution, pageResolution, advancedFilterResolution]);

  const handleChangeResolution = async (id) => {
    await dispatch(changeStatusResolution(id));
    handleGetResolutionsByCredential();
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={12}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ textAlign: "right" }}
          >
            <MDButton
              onClick={handleClickOpen}
              variant="gradient"
              color="info"
              sx={() => MDButtonCustomByTenant(primaryColor)}
            >
              Crear Credencial
            </MDButton>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <TableCredential
                listCredential={listCredential}
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                loading={loading}
                changePage={(value) => setPage(value)}
                changePageSize={(value) => setPageSize(value)}
                filters={filters}
                updateFilters={(newArrayFilters) => setFilters(newArrayFilters)}
                getDataSdvancedFilter={(filter) => {
                  setAdvancedFilter(filter);
                }}
                handleEditCredential={handleEditCredential}
                handleChangeStatus={handleChangeStatus}
                handleGetResolutionsByCredential={handleGetResolutionsByCredential}
              />
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
      <DialogRegisterCredential
        open={open}
        typeDialog={typeDialog}
        handleClose={handleClose}
        setCodeProvider={setCodeProvider}
        codeProvider={codeProvider}
        setLocalResolution={setLocalResolution}
        localResolution={localResolution}
        listProvider={listProvider}
        handleCredential={handleCredential}
        dataEdit={dataEdit}
        loadingButton={loadingButton}
        handleCreateResolition={handleCreateResolition}
        idCredentialNew={idCredentialNew}
      />
      <DialogListResolution
        openList={openList}
        handleCloseDialogList={handleCloseDialogList}
        listResolutions={listResolutions}
        rowCountResolution={rowCountResolution}
        setPageSizeResolution={setPageSizeResolution}
        pageSizeResolution={pageSizeResolution}
        setPageResolution={setPageResolution}
        pageResolution={pageResolution}
        loadingResolution={loadingResolution}
        filtersResolution={filtersResolution}
        setFiltersResolution={setFiltersResolution}
        setAdvancedFilterResolution={setAdvancedFilterResolution}
        advancedFilterResolution={advancedFilterResolution}
        handleChangeResolution={handleChangeResolution}
        getDataResolution={handleGetResolutionsByCredential}
        idCredentialNew={idResolutionModal}
        handleCreateResolition={handleCreateResolition}
      />
    </>
  );
}

export default Credentials;
