import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Layout from "layouts/authentication/components/Layout";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../../assets/images/logos/logo.svg";

function TermsAndConditions() {
  const matches = useMediaQuery("(min-width:1030px)");
  return (
    <Layout coverHeight="10vh">
      <Card>
        <MDBox
          component="img"
          src={logo}
          width={matches ? "20%" : "45%"}
          height="20%"
          style={{ marginLeft: matches ? "40%" : "27%" }}
        />
        <MDBox ml={2}>
          <MDTypography variant="h5" fontWeight="medium" mt={1}>
            POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES DE AIDCOL
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Fecha última actualización:11/7/2022.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            De conformidad con el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de
            27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta
            al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento
            General de Protección de Datos – RGPD. GlobalEDB, informa a los usuarios de la
            aplicación AidCol (en adelante, la Aplicación), acerca del tratamiento de los datos
            personales, que ellos voluntariamente hayan facilitado durante el proceso de registro,
            acceso y utilización del servicio.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            1. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            GlobalEDB, con NIT nº: 901153954-6 y domicilio en Valledupar, Colombia a efectos de
            notificaciones en: Cl 12 # 26 10 e con matrícula número 150789 (en adelante, el
            Responsable del Tratamiento), es la entidad responsable del tratamiento de los datos
            facilitados por los clientes de la Aplicación (en adelante, el/los Usuario/s).
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            2. FINALIDAD DEL TRATAMIENTO DE DATOS.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Para proceder al registro, acceso y posterior uso de la Aplicación, el Usuario deberá
            facilitar, de forma voluntaria, datos de carácter personal (esencialmente,
            identificativos y de contacto), los cuales serán incorporados a soportes automatizados
            titularidad de GlobalEDB.
          </MDTypography>
          <MDTypography display="block" variant="button" mt={2}>
            La recogida, almacenamiento, modificación, estructuración y en su caso, eliminación, de
            los datos proporcionados por los Usuarios, constituirán operaciones de tratamiento
            llevadas a cabo por el Responsable, con la finalidad de garantizar el correcto
            funcionamiento de la Aplicación, mantener la relación de prestación de servicios y/o
            comercial con el Usuario, y para la gestión, administración, información, prestación y
            mejora del servicio.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            3. RECOPILACIÓN DE INFORMACIÓN PERSONAL.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            La Aplicación recopilará la siguiente información personal del Usuario:
          </MDTypography>
          <MDTypography mt={2} ml={2} variant="h6" fontWeight="medium">
            3.1 INFORMACIÓN PERSONAL.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1} ml={2}>
            El usuario proporcionará su nombre, identificación, teléfono móvil, dirección de correo
            electrónico y demás información personal relevante en AidCol.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1} ml={2}>
            La información personal facilitada por el Usuario -especialmente, el correo electrónico
            o e-mail- podrán emplearse también para remitir boletines (newsletters), así como
            comunicaciones comerciales de promociones y/o publicidad de la Aplicación, siempre y
            cuando, el Usuario haya prestado previamente su consentimiento expreso para la recepción
            de estas comunicaciones vía electrónica.
          </MDTypography>
          <MDTypography mt={2} ml={2} variant="h6" fontWeight="medium">
            3.2 UBICACIÓN
          </MDTypography>
          <MDTypography display="block" variant="button" my={1} ml={2}>
            Aidcol recopilará la información de la ubicación de los Usuario tipo Inspectores cuando
            AidCol se ejecute en primer plano o en segundo plano en el dispositivo del Usuario.
            AidCol obtendrá la ubicación del Usuario a través de la dirección IP y GPS, esto con el
            fin de tener la ubicación en tiempo real de los inspectores y revisar si están
            realizando las tareas programadas.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            4. LEGITIMACIÓN.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            El tratamiento de los datos del Usuario, se realiza con las siguientes bases jurídicas
            que legitiman el mismo:
          </MDTypography>
          <MDTypography display="block" variant="button" my={1} ml={2}>
            La solicitud de información y/o la contratación de los servicios de la Aplicación, cuyos
            términos y condiciones se pondrán a disposición del Usuario en todo caso, con carácter
            previo, para su expresa aceptación.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1} ml={2}>
            El consentimiento libre, específico, informado e inequívoco del Usuario, poniendo a su
            disposición la presente política de privacidad, que deberá aceptar mediante una
            declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al
            efecto.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            En caso de que el Usuario no facilite a GlobalEDB sus datos, o lo haga de forma errónea
            o incompleta, no será posible proceder al uso de la Aplicación.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            5. CONSERVACIÓN DE LOS DATOS PERSONALES.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Los datos personales proporcionados por el Usuario, se conservarán en los sistemas y
            bases de datos del Responsable del Tratamiento, mientras aquél continúe haciendo uso de
            la Aplicación, y siempre que no solicite su supresión.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Con el objetivo de depurar las posibles responsabilidades derivadas del tratamiento, los
            datos se conservarán por un período mínimo de cinco años.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            6. DESTINATARIOS.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Los datos no se comunicarán a ningún tercero ajeno a GlobalEDB, salvo obligación legal o
            en cualquier caso, previa solicitud del consentimiento del Usuario.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            De otra parte, GlobalEDB podrá dar acceso o transmitir los datos personales facilitados
            por el Usuario, a terceros proveedores de servicios, con los que haya suscrito acuerdos
            de encargo de tratamiento de datos, y que únicamente accedan a dicha información para
            prestar un servicio en favor y por cuenta del Responsable.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            7. RETENCIÓN DE DATOS.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            GlobalEDB, informa al Usuario de que, como prestador de servicio de alojamiento de datos
            y en virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la
            Sociedad de la Información y de Comercio Electrónico (LSSI), retiene por un período
            máximo de 12 meses la información imprescindible para identificar el origen de los datos
            alojados y el momento en que se inició la prestación del servicio.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            La retención de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser
            utilizados en el marco de una investigación criminal o para la salvaguardia de la
            seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del
            Ministerio que así los requiera.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            La comunicación de datos a las Fuerzas y Cuerpos de Seguridad del Estado, se hará en
            virtud de lo dispuesto por la normativa sobre protección de datos personales, y bajo el
            máximo respeto a la misma.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            8. PROTECCIÓN DE LA INFORMACIÓN ALOJADA.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            El Responsable del Tratamiento, adopta las medidas necesarias para garantizar la
            seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el
            Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016,
            relativo a la protección de las personas físicas en lo que respecta al tratamiento de
            datos personales y a la libre circulación de los mismos.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Si bien el Responsable, realiza copias de seguridad de los contenidos alojados en sus
            servidores, sin embargo no se responsabiliza de la pérdida o el borrado accidental de
            los datos por parte de los Usuarios. De igual manera, no garantiza la reposición total
            de los datos borrados por los Usuarios, ya que los citados datos podrían haber sido
            suprimidos y/o modificados durante el periodo de tiempo transcurrido desde la última
            copia de seguridad.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Los servicios facilitados o prestados a través de la Aplicación, excepto los servicios
            específicos de backup, no incluyen la reposición de los contenidos conservados en las
            copias de seguridad realizadas por el Responsable del Tratamiento, cuando esta pérdida
            sea imputable al usuario; en este caso, se determinará una tarifa acorde a la
            complejidad y volumen de la recuperación, siempre previa aceptación del usuario. La
            reposición de datos borrados sólo está incluida en el precio del servicio cuando la
            pérdida del contenido sea debida a causas atribuibles al Responsable.
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            9. EJERCICIO DE DERECHOS.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            GlobalEDB, informa al Usuario de que le asisten los derechos de acceso, rectificación,
            limitación, supresión, oposición y portabilidad, los cuales podrá ejercitar mediante
            petición dirigida al correo electrónico: edwin@globaledb.com.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            Asimismo, el Usuario tiene derecho a revocar el consentimiento inicialmente prestado, y
            a interponer reclamaciones de derechos frente a la Agencia Española de Protección de
            Datos (AEPD).
          </MDTypography>
          <MDTypography mt={2} variant="h6" fontWeight="medium">
            10. COMUNICACIONES COMERCIALES POR VÍA ELECTRÓNICA.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            En aplicación de la LSSI (Ley de Servicios de la Sociedad de la Información), GlobalEDB,
            no enviará comunicaciones publicitarias o promocionales por correo electrónico u otro
            medio de comunicación electrónica equivalente que previamente no hubieran sido
            solicitadas o expresamente autorizadas por los destinatarios de las mismas.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            En el caso de usuarios con los que exista una relación contractual, jurídica o de
            servicios previa, el Responsable del Tratamiento, sí está autorizado al envío de
            comunicaciones comerciales referentes a productos o servicios del Responsable que sean
            similares a los que inicialmente fueron objeto de contratación con el cliente.
          </MDTypography>
          <MDTypography display="block" variant="button" my={1}>
            En caso de que el Usuario quiera darse de baja a la hora de recibir las citadas
            comunicaciones, podrá hacerlo remitiendo su voluntad por e-mail al correo electrónico:
            edwin@globaledb.com.
          </MDTypography>
        </MDBox>
      </Card>
    </Layout>
  );
}

export default TermsAndConditions;
