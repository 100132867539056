import { Autocomplete, CircularProgress, IconButton } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import PageLayout from "examples/LayoutContainers/PageLayout";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { Field, Form, Formik } from "formik";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import Checkbox from "@mui/material/Checkbox";
import DialogMapDirection from "components/dialogMapDirection";
import AutocompleteGoogleMap from "components/Map/AutocompleteGoogleMap";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import logo from "../../assets/images/logos/logo.svg";
import ViewPdf from "../../components/ViewPdf";
import SectionTwo from "../../image/SectionTwo.png";
import UploadDocumentsModal from "../documents/index";
import DocumentsTable from "../documents/DocumentsTable";

// eslint-disable-next-line import/named
import {
  getServiceWithoutToken,
  resetServiceWithoutToken,
  getDocumentsToUpload,
} from "../../store/generateService/actions";
import Basic from "../authentication/sign-in/basic/index";
import { getTenantsWithoutToken } from "../../store/tenant/actions";
import { getIdentificationTypes } from "../../store/identificationTypes/actions";
import { createSolicitudesAnonymous } from "../../store/solicitudes/actions";
import httpRequest from "../../services/httpRequest";

const initialValues = {
  name: "",
  identificationType: { name: "", code: "" },
  nIdentification: "",
  address: "",
  email: "",
  phoneNumber: "",
  contactName: "",
  serviceId: { nameService: "", id: "" },
  tenant: { name: "", id: "" },
  economicActivityCode: { code: "", name: "" },
};

// {
//   "name": "string",
//   "identificationType": "string",
//   "nIdentification": "string",
//   "address": "string",
//   "email": "string",
//   "phoneNumber": "string",
//   "contactName": "string",
//   "acceptTermsAndConditions": true,
//   "serviceId": "string",
//   "documents": [
//     "string"
//   ]
// }

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  email: Yup.string().required("Campo requerido"),
  address: Yup.string().required("Campo requerido"),
  phoneNumber: Yup.string().required("Campo requerido"),
  nIdentification: Yup.string().required("Campo requerido"),
});

const LandingPage = () => {
  const [tenantSelected, setTenantSelected] = useState("");
  const [typeSelect, setTypeSelect] = useState("");
  const [serviceSelect, setServiceSelect] = useState("");
  const [openDocumentsUpload, setDocumentUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentsToSend, setDocumentsToSend] = useState([]);
  const [listEconomicActivities, setListEconomicActivities] = useState([]);
  const [pdfLink] = useState("https://cdn.aidcol.com/docs/politica-privacidad.pdf");
  const [openPdf, setOpenPdf] = useState(false);
  const [openDialogDirection, setOpenDialogDirection] = useState(false);
  const [openPdf2, setOpenPdf2] = useState(false);
  const [onReset, setOnReset] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [location, setLocation] = useState(null);
  const [documentsUpload, setDocumentsUpload] = useState([]);
  const [pdfView, setPdfToView] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [solicitudLogin, setSolicitudLogin] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [page, setPage] = useState(0);
  const [hasSeted, setHasSeted] = useState(false);
  const handleCloseViewPdf = () => setOpenPdf(false);
  const alert = useAlert();
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const services = useSelector(({ service }) => service.dataService);
  const tenants = useSelector(({ company }) => company.dataTenants);
  const typesIdentity = useSelector(({ identificationTypes }) => identificationTypes.data);
  const matches = useMediaQuery("(min-width:1030px)");

  const handleOpenViewPdf = () => {
    setOpenPdf(true);
  };

  const handleOpenViewPdf2 = (url, name) => {
    setOpenPdf2(true);
    setPdfToView(url);
    setPdfName(name);
  };

  const handleCloseViewPdf2 = () => setOpenPdf2(false);

  const handleOpenDocumentsUpload = () => {
    setDocumentUpload(true);
  };

  const handleCloseDocumentsUpload = () => setDocumentUpload(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const documentsTs = [];
      documentsToSend.forEach((doc) => {
        documentsTs.push(doc.id);
      });
      const newData = {
        name: values.name,
        identificationType: values.identificationType.code,
        nIdentification: values.nIdentification.toString(),
        address: values.address,
        email: values.email,
        phoneNumber: values.phoneNumber.toString(),
        contactName: values.contactName,
        serviceId: values.serviceId.id,
        documents: documentsTs,
      };
      await dispatch(createSolicitudesAnonymous(tenantSelected.id, newData));
      setLoading(false);
      alert.success("Se ha enviado la solicitud correctamente");
      resetForm();
      setCheckboxChecked(false);
      setTenantSelected(null);
      setTypeSelect(null);
      setServiceSelect(null);
      setDocumentsToSend([]);
      setOnReset(true);
    } catch (e) {
      setLoading(false);
      alert.error(`${e.Messages[0]}`, {
        position: "top right",
      });
    }
  };

  const getEconomicActivities = useCallback(async () => {
    const responseEconomicActiv = await httpRequest.get(`economicactivities`);
    setListEconomicActivities(responseEconomicActiv);
  }, []);

  useEffect(() => {
    (async () => {
      if (tenantSelected !== "" && tenantSelected !== null) {
        await dispatch(
          getServiceWithoutToken(
            tenantSelected.id !== undefined ? tenantSelected.id : tenantSelected
          )
        );
        const data = await dispatch(
          getDocumentsToUpload(tenantSelected.id !== undefined ? tenantSelected.id : tenantSelected)
        );
        setDocumentsUpload(data);
      }
    })();
  }, [tenantSelected]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(documentsToSend);
  }, [documentsToSend]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(location);
  }, [location]);

  useEffect(() => {
    (async () => {
      await dispatch(getTenantsWithoutToken());
      await dispatch(getIdentificationTypes());
    })();
    getEconomicActivities();
  }, []);

  useEffect(() => {
    const urlTenan = window.location;
    const urlTenanSplit = urlTenan.hostname.split(".");
    if (urlTenanSplit.length === 3) {
      if (urlTenanSplit[0] !== "www") {
        setHasSeted(true);
        setTenantSelected(urlTenanSplit[0].toUpperCase());
      }
    }
  }, []);

  return (
    <PageLayout background="white">
      <MDBox
        width="100vw"
        height="100%"
        sx={{
          backgroundImage: `url(${SectionTwo})`,
          backgroundRepeat: "no-repeat",
          minHeight: "100vH",
          backgroundPositionX: "right",
        }}
      >
        <MDBox component="img" src={logo} width="20%" height="20%" style={{ marginLeft: "40%" }} />
        <Grid container pl={5} spacing={2} justifyContent="center" alignItems="center">
          {!solicitudLogin && (
            <>
              <Grid item xs={12} sm={12} md={5}>
                <Basic setLoginSolicitud={setSolicitudLogin} />
              </Grid>
            </>
          )}

          {solicitudLogin && (
            <>
              <Grid item xs={12} sm={!matches ? 12 : 5} md={!matches ? 12 : 5}>
                <MDBox
                  pt={1}
                  px={1}
                  role="form"
                  style={{ backgroundColor: "white", width: "500px" }}
                >
                  <MDTypography
                    variant="h5"
                    textAlign="center"
                    fontWeight="medium"
                    color="#1B4E7C"
                    mt={1}
                  >
                    Solicitud de servicio
                  </MDTypography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form>
                        <Grid container spacing={2} px={2}>
                          {hasSeted === false && (
                            <>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Autocomplete
                                  id="tenant"
                                  defaultValue={initialValues.tenant}
                                  name="tenant"
                                  options={tenants}
                                  value={tenantSelected}
                                  getOptionLabel={(option) => option.name ?? option}
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      "tenant",
                                      value !== null ? value : initialValues.tenant
                                    );
                                    setTenantSelected(value);
                                    if (!value) {
                                      dispatch(resetServiceWithoutToken());
                                      setFieldValue("serviceId", { nameService: "", id: "" });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Empresa"
                                      variant="standard"
                                      fullWidth
                                      required
                                      name="tenant"
                                      InputLabelProps={{ shrink: true }}
                                      error={errors.tenant && touched.tenant}
                                      helperText={touched.tenant && errors.tenant}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12} sm={6} md={hasSeted ? 12 : 6}>
                            <Autocomplete
                              id="serviceId"
                              defaultValue={initialValues.serviceId}
                              name="serviceId"
                              options={services}
                              value={serviceSelect}
                              getOptionLabel={(option) => option.nameService ?? option}
                              onChange={(e, value) => {
                                setFieldValue(
                                  "serviceId",
                                  value !== null ? value : initialValues.serviceId
                                );
                                setFieldValue("tenant", null);
                                setServiceSelect(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Servicio"
                                  variant="standard"
                                  fullWidth
                                  required
                                  name="serviceId"
                                  InputLabelProps={{ shrink: true }}
                                  error={errors.serviceId && touched.serviceId}
                                  helperText={touched.serviceId && errors.serviceId}
                                />
                              )}
                            />
                          </Grid>
                          <MDTypography
                            variant="h5"
                            textAlign="center"
                            fontWeight="medium"
                            color="#1B4E7C"
                            style={{ marginLeft: "37%", marginTop: "2%" }}
                          >
                            Datos del cliente
                          </MDTypography>
                          <Grid item xs={12} sm={6} md={6}>
                            <Field
                              as={TextField}
                              fullWidth
                              label="Nombre completo o razón social"
                              name="name"
                              required
                              error={errors.name && touched.name}
                              helperText={touched.name && errors.name}
                              inputProps={{ autoComplete: "off" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Autocomplete
                              id="identificationType"
                              defaultValue={initialValues.identificationType}
                              name="identificationType"
                              options={typesIdentity}
                              value={typeSelect}
                              getOptionLabel={(option) => option.name ?? option}
                              onChange={(e, value) => {
                                setFieldValue(
                                  "identificationType",
                                  value !== null ? value : initialValues.identificationType
                                );
                                setTypeSelect(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo de identificación"
                                  variant="standard"
                                  fullWidth
                                  required
                                  name="identificationType"
                                  InputLabelProps={{ shrink: true }}
                                  error={errors.identificationType && touched.identificationType}
                                  helperText={
                                    touched.identificationType && errors.identificationType
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Field
                              as={TextField}
                              label="Identificación"
                              name="nIdentification"
                              fullWidth
                              required
                              inputProps={{ type: "number", autoComplete: "off" }}
                              error={errors.nIdentification && touched.nIdentification}
                              helperText={touched.nIdentification && errors.nIdentification}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Field
                              as={TextField}
                              name="email"
                              fullWidth
                              label="Correo electrónico"
                              required
                              inputProps={{ type: "email", autoComplete: "off" }}
                              error={errors.email && touched.email}
                              helperText={touched.email && errors.email}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} display="flex" flexDirection="row">
                            <AutocompleteGoogleMap
                              label="Dirección"
                              defaultValue=""
                              name="address"
                              resetField={onReset}
                              setOnResetField={(value) => setOnReset(value)}
                              onChangeInputValue={(value) => setFieldValue("address", value)}
                              onChangeValue={(value) => setLocation(value)}
                            />
                            <IconButton
                              disabled={!location}
                              size="medium"
                              onClick={() => setOpenDialogDirection(true)}
                            >
                              <PlaceIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Field
                              as={TextField}
                              fullWidth
                              required
                              label="Teléfono"
                              name="phoneNumber"
                              inputProps={{ autoComplete: "off", type: "number" }}
                              InputLabelProps={{ shrink: true }}
                              variant="standard"
                              error={errors.phoneNumber && touched.phoneNumber}
                              helperText={touched.phoneNumber && errors.phoneNumber}
                            />
                          </Grid>
                          {false && (
                            <>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Autocomplete
                                  id="economicActivityCode"
                                  defaultValue={initialValues.economicActivityCode}
                                  name="economicActivityCode"
                                  options={listEconomicActivities}
                                  getOptionLabel={(option) =>
                                    option.code === "" ? "" : `${option.code}-${option.name}`
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      "economicActivityCode",
                                      value !== null ? value : initialValues.economicActivityCode
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Código actividad económica"
                                      variant="standard"
                                      fullWidth
                                      name="economicActivityCode"
                                      InputLabelProps={{ shrink: true }}
                                      error={
                                        errors.economicActivityCode && touched.economicActivityCode
                                      }
                                      helperText={
                                        touched.economicActivityCode && errors.economicActivityCode
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Field
                                  as={TextField}
                                  fullWidth
                                  label="ARL"
                                  name="contactName"
                                  inputProps={{ autoComplete: "off" }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="standard"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Field
                                  as={TextField}
                                  fullWidth
                                  label="Numero de trabajdores"
                                  name="cantidadTrabajadores"
                                  inputProps={{ autoComplete: "off", type: "number" }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="standard"
                                  error={
                                    errors.cantidadTrabajadores && touched.cantidadTrabajadores
                                  }
                                  helperText={
                                    touched.cantidadTrabajadores && errors.cantidadTrabajadores
                                  }
                                />
                              </Grid>
                            </>
                          )}
                          {typeSelect && typeSelect.code === "NIT" && (
                            <>
                              <Grid item xs={12} sm={12} md={12}>
                                <MDTypography
                                  variant="h5"
                                  textAlign="center"
                                  fontWeight="medium"
                                  color="#1B4E7C"
                                  mt={1}
                                >
                                  Datos del Representante Legal
                                </MDTypography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                <Field
                                  as={TextField}
                                  fullWidth
                                  required
                                  label="Nombre"
                                  name="contactName"
                                  inputProps={{ autoComplete: "off" }}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(value) =>
                                    setFieldValue("contactName", value.target.value)
                                  }
                                  variant="standard"
                                />
                              </Grid>
                              {false && (
                                <>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      required
                                      label="Teléfono"
                                      name="contactName"
                                      inputProps={{ autoComplete: "off" }}
                                      InputLabelProps={{ shrink: true }}
                                      variant="standard"
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      required
                                      label="Dirección "
                                      name="contactName"
                                      inputProps={{ autoComplete: "off" }}
                                      InputLabelProps={{ shrink: true }}
                                      variant="standard"
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      required
                                      label="Email"
                                      name="contactName"
                                      inputProps={{ autoComplete: "off" }}
                                      InputLabelProps={{ shrink: true }}
                                      variant="standard"
                                    />
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>

                        {documentsUpload.length > 0 && (
                          <MDButton
                            variant="gradient"
                            style={{
                              backgroundColor: documentsToSend.length > 0 ? "gray" : "#96BE1F",
                              color: "#3C3C3B",
                              width: "35%",
                              marginTop: "1%",
                            }}
                            disabled={documentsToSend.length > 0}
                            onClick={handleOpenDocumentsUpload}
                          >
                            Cargar documentos
                          </MDButton>
                        )}
                        <MDBox mt={2} style={{ backgroundColor: "white" }}>
                          {documentsToSend.length > 0 && (
                            <DocumentsTable
                              documentsListData={documentsToSend}
                              rowCount={documentsToSend.length}
                              page={page}
                              changePage={setPage}
                              pageSize={pageSize}
                              changePageSize={setPageSize}
                              handleShow={handleOpenViewPdf2}
                              setDocumentsSend={setDocumentsToSend}
                              tenantId={tenantSelected?.id}
                            />
                          )}
                        </MDBox>
                        <Grid container mt={2}>
                          <Grid item>
                            <Checkbox
                              onClick={() => {
                                setDisabledButton(!disabledButton);
                                setCheckboxChecked(!checkboxChecked);
                              }}
                              checked={checkboxChecked}
                            />
                          </Grid>

                          <Grid item>
                            <MDBox>
                              <MDTypography
                                variant="caption"
                                fontWeight="bold"
                                onClick={handleOpenViewPdf}
                                color="#1B4E7C"
                                sx={{ "text-decoration": "underline", cursor: "pointer" }}
                              >
                                Aceptar términos y condiciones
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        </Grid>
                        <ViewPdf
                          open={openPdf}
                          data={pdfLink}
                          type={1}
                          onClose={handleCloseViewPdf}
                        />
                        <ViewPdf
                          open={openPdf2}
                          data={pdfView}
                          type={3}
                          tittlePdf={pdfName}
                          onClose={handleCloseViewPdf2}
                        />

                        <MDBox mb={1} pr={1}>
                          {loading === true ? (
                            <Grid container justifyContent="center" alignItems="center">
                              <Grid item>
                                <CircularProgress color="secondary" />
                              </Grid>
                            </Grid>
                          ) : (
                            <>
                              <MDButton
                                type="submit"
                                variant="gradient"
                                style={{ backgroundColor: "#96BE1F", color: "#3C3C3B" }}
                                fullWidth
                                disabled={!disabledButton}
                              >
                                Enviar solicitud
                              </MDButton>
                              <MDTypography textAlign="center" fontWeight="medium" color="#1B4E7C">
                                ó
                              </MDTypography>
                              <MDButton
                                onClick={() => setSolicitudLogin(false)}
                                fullWidth
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                Iniciar sesión
                              </MDButton>
                            </>
                          )}
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
      {documentsUpload.length > 0 && tenantSelected && (
        <UploadDocumentsModal
          open={openDocumentsUpload}
          uploadDocuments={documentsUpload}
          onClose={handleCloseDocumentsUpload}
          tenantId={tenantSelected?.id !== undefined ? tenantSelected?.id : tenantSelected}
          setDocumentsSend={setDocumentsToSend}
          type={1}
        />
      )}
      {location && (
        <DialogMapDirection
          corsd={location}
          open={openDialogDirection}
          onClose={() => setOpenDialogDirection(false)}
        />
      )}
    </PageLayout>
  );
};

export default LandingPage;
