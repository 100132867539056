import React, { useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDButtonCustomByTenant from "components/MDButton/MDButtonCustomByTenant";
import MDButton from "components/MDButton";
import TableResolution from "../TableResolution";
import FormEditResolution from "../FormEditResolution";
import FormResolution from "../FormResolution";

function DialogListResolution({
  openList,
  handleCloseDialogList,
  listResolutions,
  rowCountResolution,
  pageResolution,
  pageSizeResolution,
  setPageResolution,
  setPageSizeResolution,
  loadingResolution,
  filtersResolution,
  setFiltersResolution,
  setAdvancedFilterResolution,
  handleChangeResolution,
  getDataResolution,
  idCredentialNew,
  handleCreateResolition,
}) {
  const { primaryColor } = useSelector(({ company }) => company.tenantConfig);
  const [dataResolution, setDataResolution] = useState(null);
  const [enableEditResolution, setEnableEditResolution] = useState(false);
  const [enableCreateResolution, setEnableCreateResolution] = useState(false);

  const changeResolutionSelected = (resolution) => {
    setDataResolution(resolution);
  };

  const closeEditForm = async (state) => {
    setEnableEditResolution(state);
    if (!state) {
      await getDataResolution(dataResolution?.electronicInvoiceCredentialId);
    }
  };

  const saveResolution = async (values) => {
    await handleCreateResolition(values);
    await getDataResolution(idCredentialNew);
    setEnableCreateResolution(false);
  };

  return (
    <>
      <Dialog open={openList} onClose={handleCloseDialogList} fullWidth="true" maxWidth="lg">
        {!enableEditResolution && !enableCreateResolution ? (
          <>
            <DialogTitle>Resoluciones</DialogTitle>
          </>
        ) : (
          <></>
        )}

        <DialogContent>
          <Grid container spacing={1}>
            {!enableEditResolution && !enableCreateResolution ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ textAlign: "right" }}
                >
                  <MDButton
                    onClick={() => setEnableCreateResolution(true)}
                    variant="gradient"
                    color="info"
                    sx={() => MDButtonCustomByTenant(primaryColor)}
                  >
                    Crear Resolución
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <TableResolution
                      listResolutions={listResolutions}
                      rowCountResolution={rowCountResolution}
                      page={pageResolution}
                      pageSize={pageSizeResolution}
                      loading={loadingResolution}
                      changePage={(value) => setPageResolution(value)}
                      changePageSize={(value) => setPageSizeResolution(value)}
                      filters={filtersResolution}
                      updateFilters={(newArrayFilters) => setFiltersResolution(newArrayFilters)}
                      getDataSdvancedFilter={(filter) => {
                        setAdvancedFilterResolution(filter);
                      }}
                      handleChangeResolution={handleChangeResolution}
                      changeResolutionSelected={changeResolutionSelected}
                      editForm={closeEditForm}
                    />
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                {enableCreateResolution ? (
                  <>
                    <FormResolution
                      idCredential={idCredentialNew}
                      handleClose={() => {
                        setEnableCreateResolution(false);
                      }}
                      isModal
                      handleCreateResolition={saveResolution}
                    />
                  </>
                ) : (
                  <>
                    <FormEditResolution
                      dataResolution={dataResolution}
                      handleClose={closeEditForm}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

DialogListResolution.defaultProps = {
  listResolutions: [],
  openList: false,
  setPageSizeResolution: 0,
  setPageResolution: 0,
  loadingResolution: false,
  filtersResolution: [],
  setFiltersResolution: "",
  setAdvancedFilterResolution: "",
};

DialogListResolution.propTypes = {
  listResolutions: PropTypes.arrayOf(PropTypes.string),
  openList: PropTypes.bool,
  handleCloseDialogList: PropTypes.func.isRequired,
  rowCountResolution: PropTypes.number.isRequired,
  setPageResolution: PropTypes.element,
  pageResolution: PropTypes.number.isRequired,
  pageSizeResolution: PropTypes.number.isRequired,
  setPageSizeResolution: PropTypes.element,
  loadingResolution: PropTypes.bool,
  filtersResolution: PropTypes.arrayOf(),
  setFiltersResolution: PropTypes.element,
  setAdvancedFilterResolution: PropTypes.element,
  handleChangeResolution: PropTypes.func.isRequired,
  getDataResolution: PropTypes.func.isRequired,
  idCredentialNew: PropTypes.string.isRequired,
  handleCreateResolition: PropTypes.func.isRequired,
};

export default DialogListResolution;
