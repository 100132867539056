// eslint-disable-next-line import/prefer-default-export
export const SET_DATA_ORDER_SELECT_MAX = "[ORDER_TYPES] SET_DATA_ORDER_SELECT_MAX";
export const SET_DATA_ORDER_SELECT_MIN = "[ORDER_TYPES] SET_DATA_ORDER_SELECT_MIN";
export const SET_DATA_SOLICITUDES_SERVICES = "[SOLICITUDES_TYPES] SET_DATA_SERVICES_SOLICITUDES";
export const ASSOCIATE_TO_CUSTOMER = "[SOLICITUDES_TYPES] ASSOCIATE_TO_CUSTOMER";
export const CREATE_DATA_ORDER = "[ORDER_TYPES] CREATE_DATA_ORDER";
export const CLOSE_SERVICE_SOLICITUDES = "[SOLICITUDES_TYPES] CLOSE_SERVICE_SOLICITUDES";
export const CREATE_SOLICITUDES_SERVICE = "[SOLICITUDES_TYPES] CREATE_SOLICITUDES_SERVICE]";
export const CUSTOMER_CREATE_SOLICITUDES_SERVICE =
  "[SOLICITUDES_TYPES] CUSTOMER_CREATE_SOLICITUDES_SERVICE]";
export const CANCEL_SERVICE_OF_ADMIN = "[VIEW_CUSTOMER] CANCEL_SERVICE_OF_ADMIN";
