import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
// import { Document, Page } from "react-pdf";
import CardSectionPdf from "./CardSectionPdf";
import PdfViewer from "./PdfViewer";

function DetailAnswersInPdf({ form, answers, attachments }) {
  // const canvasEl = useRef(null);

  const renderQuestionsAndAnswers = (Questions) => {
    const sectionsWithAnswers = Questions.filter((question) => {
      const answersByQuestion = answers.filter((answer) => answer.questionCode === question.Code);
      const attachmentsForQuestion = attachments.filter(
        (attachment) => attachment.questionCode === question.Code
      );
      return answersByQuestion.length > 0 || attachmentsForQuestion.length > 0;
    });
    return (
      <Grid container spacing={6}>
        {sectionsWithAnswers.map((question) => (
          <Grid item xs={12} sm={6} md={6} lg={15} key={question.Code}>
            <CardSectionPdf
              name={question.Name}
              answers={answers.filter((answer) => answer.questionCode === question.Code)}
              attachments={attachments.filter(
                (attachment) => attachment.questionCode === question.Code
              )}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderForm = () => {
    const sectionsWithAnswers = form.Sections.filter((section) => {
      const questionsWithAnswers = section.Questions.filter((question) => {
        const answersByQuestion = answers.filter((answer) => answer.questionCode === question.Code);
        const attachmentsForQuestion = attachments.filter(
          (attachment) => attachment.questionCode === question.Code
        );
        return answersByQuestion.length > 0 || attachmentsForQuestion.length > 0;
      });
      return questionsWithAnswers.length > 0;
    });

    if (sectionsWithAnswers.length === 0) {
      return null;
    }

    const supportedFormats = ["pdf", "docx", "txt", "xlsx"];
    const filteredAttachments = attachments.filter((item) => {
      const urlParts = item.url.split(".");
      const fileExtension = urlParts[urlParts.length - 1].toLowerCase();
      return supportedFormats.includes(fileExtension);
    });

    return (
      <>
        <br />
        <Typography
          style={{
            padding: "10px",
          }}
          variant="h5"
          align="center"
          gutterBottom
          component="div"
        >
          Formulario: {form.Name}
        </Typography>
        <Grid container spacing={2}>
          {sectionsWithAnswers.map((section) => (
            <Grid item xs={12} key={section.Code}>
              <Typography
                style={{
                  padding: "10px",
                  marginTop: "2px",
                }}
                variant="h6"
                align="center"
                gutterBottom
                component="div"
              >
                Sección: {section.Name}
              </Typography>
              {/* <br /> */}
              <MDBox sx={{ mr: 15, ml: 2, my: 2 }}>
                {renderQuestionsAndAnswers(section.Questions)}
              </MDBox>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 40 }}>
          <br />
          {filteredAttachments.map((item) => (
            <MDBox sx={{ mr: 2, ml: 14, my: 1 }} key={item.code}>
              <PdfViewer document={item.url} />
            </MDBox>
          ))}
        </Grid>
      </>
    );
  };
  return renderForm();
}
DetailAnswersInPdf.defaultProps = {
  form: {},
  answers: [],
  attachments: [],
};

DetailAnswersInPdf.propTypes = {
  form: PropTypes.objectOf(PropTypes.string),
  answers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
  attachments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
};

export default DetailAnswersInPdf;
