import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AlertConfirm from "components/AlertConfirm";
import {
  Dialog,
  DialogTitle,
  // DialogContent,
  Checkbox,
  Grid,
  Card,
  CardActionArea,
  DialogActions,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { changeStatusManualPayment } from "store/paymentsGenerated/actions";
import { filterByCode, openUrl } from "utils/utils";
import MDBox from "components/MDBox";

function DialogToPayForAdmin({
  open,
  onClose,
  credentials,
  idsSelected,
  paymentsGateway,
  totalToPay,
  totalToPayMarketplace,
}) {
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [dataCredential, setDataCredential] = useState("");
  const dispatch = useDispatch();
  const [currentTarget, setCurrentTarget] = useState(null);

  const handleChange = (credentialSelected) => {
    setCurrentTarget(credentialSelected);
  };

  const handleClose = () => {
    setCurrentTarget(null);
    onClose();
  };

  const handleCloseAlerConfirm = () => {
    setOpenAlertConfirm(false);
  };

  const handleOpenAlert = (credential) => {
    setOpenAlertConfirm(true);
    setDataCredential(credential);
  };
  const handleChangeStatusManualPayment = async (manualsPaymentsId) => {
    try {
      const { payload } = await dispatch(changeStatusManualPayment({ manualsPaymentsId }));
      if (payload.data?.initPoint) openUrl(payload.data.initPoint);
    } catch (e) {
      if (e.Messages) alert.error(e.Messages[0], { position: "top right" });
      else alert.error("Erro al actualizar pago manual", { position: "top right" });
    } finally {
      onClose();
    }
  };

  const toPay = async (credential) => {
    switch (credential.paymentGatewayCode) {
      case "MERCADO_PAGO":
        await handleChangeStatusManualPayment(idsSelected);
        break;
      case "MERCADO_PAGO_API_KEY":
        await handleChangeStatusManualPayment(idsSelected);
        break;
      default:
        break;
    }
    setOpenAlertConfirm(false);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={handleClose}
        fullWidth="true"
      >
        <DialogTitle id="alert-dialog-title">Seleccione Pasarela de pago</DialogTitle>
        <Grid item xs={4} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDTypography
                variant="button"
                color="text"
                fontWeight="regular"
                verticalAlign="middle"
              >
                Seleccione forma de pago
              </MDTypography>
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1}>
                {credentials.map((item) => (
                  <Grid item mt={2} p={2} xs={3} sm={3} md={3}>
                    <Card>
                      <CardActionArea onClick={() => handleChange(item)}>
                        <Grid container spacing={2}>
                          <Grid item xs={10}>
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="lg"
                            >
                              <MDBox height="100%">
                                <Checkbox checked={currentTarget?.id === item.id} />
                              </MDBox>
                              <MDBox height="100%">
                                <MDTypography
                                  variant="caption"
                                  fontWeight="medium"
                                  textTransform="capitalize"
                                >
                                  {item.accountName}
                                  {filterByCode(paymentsGateway, item.paymentGatewayCode)?.name}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions>
          <MDButton onClick={() => handleClose()} variant="outlined" color="info">
            Cancelar
          </MDButton>
          <MDButton
            onClick={() => handleOpenAlert(currentTarget)}
            disabled={!currentTarget}
            variant="gradient"
            color="info"
          >
            Realizar pago
          </MDButton>
        </DialogActions>
      </Dialog>
      <AlertConfirm
        open={openAlertConfirm}
        title="!Atención¡"
        context={`¿Seguro que desea cambiar a recibido los siguientes pagos seleccionados.?
          Valor total pagado
          $${totalToPay.toLocaleString()} y
          Comisión total a pagar
          $${totalToPayMarketplace.toLocaleString()}.`}
        onClose={handleCloseAlerConfirm}
        onAccept={() => toPay(dataCredential)}
      />
    </>
  );
}

DialogToPayForAdmin.defaultProps = {
  open: false,
  credentials: [],
  idsSelected: [],
  paymentsGateway: [],
  totalToPay: 0,
  totalToPayMarketplace: 0,
};

DialogToPayForAdmin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  credentials: PropTypes.arrayOf(),
  idsSelected: PropTypes.arrayOf(),
  paymentsGateway: PropTypes.arrayOf(),
  totalToPay: PropTypes.number,
  totalToPayMarketplace: PropTypes.number,
};

export default DialogToPayForAdmin;
