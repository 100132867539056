import * as React from "react";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";

function DialogDetailById({ openDetailById, closeDialogDetailById, detailPaymentById }) {
  return (
    <div>
      <Dialog open={openDetailById} onClose={closeDialogDetailById} maxWidth="lg" fullWidth>
        <DialogTitle>Detalle de factura</DialogTitle>
        <DialogContent>
          <Typography variant="h5" gutterBottom component="div">
            Factura
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Pais:</strong>{" "}
                {!!detailPaymentById.billingInformation &&
                  detailPaymentById.billingInformation.billingCountryName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Ciudad:</strong>{" "}
                {!!detailPaymentById.billingInformation &&
                  detailPaymentById.billingInformation.billingCityName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Dirección:</strong>{" "}
                {!!detailPaymentById.billingInformation &&
                  detailPaymentById.billingInformation.billingAddress}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" gutterBottom component="div">
            Cliente
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Dirección:</strong>{" "}
                {!!detailPaymentById.customer && detailPaymentById.customer.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Nombre:</strong>{" "}
                {!!detailPaymentById.customer && detailPaymentById.customer.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Tipo de identificación:</strong>{" "}
                {!!detailPaymentById.customer && detailPaymentById.customer.identificationType}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Identificación:</strong>{" "}
                {!!detailPaymentById.customer && detailPaymentById.customer.nIdentification}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Email:</strong>{" "}
                {!!detailPaymentById.customer && detailPaymentById.customer.email}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" gutterBottom component="div">
            Documento factura electronica
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                <strong>Observación:</strong>{" "}
                {!!detailPaymentById.electronicInvoiceDocument &&
                  detailPaymentById.electronicInvoiceDocument.observation}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!!detailPaymentById.electronicInvoiceDocument &&
              !!detailPaymentById.electronicInvoiceDocument.dianStateReason &&
              detailPaymentById.electronicInvoiceDocument.dianStateReason.length > 0 ? (
                detailPaymentById.electronicInvoiceDocument.dianStateReason.map((error) => (
                  <Typography variant="subtitle2" gutterBottom component="div">
                    <strong>Error:</strong> {error}
                  </Typography>
                ))
              ) : (
                <Typography variant="subtitle2" gutterBottom component="div">
                  <strong>No hay errores</strong>
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="dark" onClick={() => closeDialogDetailById()}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogDetailById.defaultProps = {
  openDetailById: false,
  detailPaymentById: {},
};

DialogDetailById.propTypes = {
  openDetailById: PropTypes.bool,
  closeDialogDetailById: PropTypes.func.isRequired,
  detailPaymentById: PropTypes.objectOf(),
};

export default DialogDetailById;
