import React, { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import Switch from "@mui/material/Switch";
import DialogRenewSubscription from "./RenewSubscription";

function CardCompany({ onUpdate, company, checked, onChangeCheck }) {
  const [controller] = useMaterialUIController();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const handleCloseRenewDialog = () => setOpenCancelDialog(false);
  const handleRenewSolicitud = () => {
    setOpenCancelDialog(true);
  };
  const { darkMode } = controller;

  const handleSetVisible = () => {
    onChangeCheck(company.id, checked);
  };

  const handleClick = () => {
    onUpdate(company);
  };

  return (
    <>
      <Card style={{ background: "#dadada73" }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <MDTypography
                noWrap="true"
                variant="h6"
                fontWeight="medium"
                style={{ color: "black", fontSize: "22px" }}
              >
                <strong>{company.name}</strong>
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDTypography
                variant="h6"
                noWrap="true"
                fontWeight="medium"
                style={{ color: "black" }}
              >
                <strong style={{ color: "#344767" }}>Representante legal:</strong>{" "}
                {company.legalRepresentative.name}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDTypography variant="h6" fontWeight="medium" style={{ color: "black" }}>
                <strong style={{ color: "#344767" }}>Email:</strong> {company.adminEmail}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDTypography variant="h6" fontWeight="medium" style={{ color: "black" }}>
                <strong style={{ color: "#344767" }}>NIT:</strong> {company.nIdentification}
              </MDTypography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: "-26px" }}>
          <MDButton
            onClick={() => handleClick()}
            variant="text"
            color={darkMode ? "white" : "dark"}
          >
            <Icon>edit</Icon>&nbsp;editar
          </MDButton>
          <MDButton
            onClick={() => handleRenewSolicitud()}
            variant="text"
            color={darkMode ? "white" : "dark"}
          >
            <Icon>autorenew</Icon>&nbsp;Renovar subscripción
          </MDButton>
          <Switch checked={checked} onChange={handleSetVisible} />
        </CardActions>
      </Card>
      <DialogRenewSubscription
        open={openCancelDialog}
        onClose={handleCloseRenewDialog}
        idTenant={company.id}
      />
    </>
  );
}

// Typechecking props for the Bill
CardCompany.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onChangeCheck: PropTypes.func.isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    adminEmail: PropTypes.string,
    nIdentification: PropTypes.string,
    legalRepresentative: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default CardCompany;
