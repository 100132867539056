import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SwipeableViews from "react-swipeable-views";
import { bindKeyboard } from "react-swipeable-views-utils";
import { Dialog, Grid, IconButton } from "@mui/material";
import { DialogContext } from "components/ModalWithMap";
import MDBox from "../../../components/MDBox";
import ViewPdf from "../../../components/ViewPdf/index";
import logoPdf from "../../../assets/images/logos/pdf.png";
import logoDocs from "../../../assets/images/logos/docx.png";
import logoText from "../../../assets/images/logos/txt.png";
import logoXlsx from "../../../assets/images/logos/xlsx.png";
import logoAudio from "../../../assets/images/logos/audio.png";

const AutoPlaySwipeableViews = bindKeyboard(SwipeableViews);

function ViewAttachment({ attachments }) {
  const theme = useTheme();
  const [openViewPdf, setOpenViewPdf] = useState(false);
  const [documentForOpen, setDocumentForOpen] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [pdfUrl, setPdfUrl] = useState("");
  const maxSteps = attachments.length;
  // const [value, setValue] = useState(0);

  const renderImages = (step, height) => (
    <Box
      component="img"
      sx={{
        height: !height ? 255 : height,
        display: "block",
        width: "100%",
        objectFit: "contain",
      }}
      src={step.url}
      alt={step.questionCode}
    />
  );

  const handleCloseViewPdf = () => setOpenViewPdf(false);

  const handleOpenViewPdf = (pdfToView) => {
    setPdfUrl(pdfToView.url);
    setOpenViewPdf(true);
  };

  const renderVideos = (step, height) => (
    /* eslint-disable jsx-a11y/media-has-caption */
    <video controls name="media" width="100%" height={!height ? "255px" : height}>
      <source src={step.url} type="video/mp4" />
      <track />
    </video>
  );

  const renderAudios = () => (
    /* eslint-disable jsx-a11y/media-has-caption */
    <MDBox
      component="img"
      src={logoAudio}
      width="20%"
      height="35%"
      style={{ marginRight: "10%" }}
    />
  );

  const renderPdf = () => (
    /* eslint-disable jsx-a11y/media-has-caption */
    <MDBox component="img" src={logoPdf} width="20%" height="35%" style={{ marginRight: "10%" }} />
  );

  const downloadFile = (file) => {
    const downloadInstance = document.createElement("a");
    downloadInstance.href = file.url;
    downloadInstance.target = "_blank";
    downloadInstance.download = "Documento";

    document.body.appendChild(downloadInstance);
    downloadInstance.click();
    document.body.removeChild(downloadInstance);
  };

  const downloadOtherDocuments = (icon) => (
    <MDBox component="img" src={icon} width="20%" height="35%" style={{ marginRight: "10%" }} />
  );

  const renderMultimedia = (item, height = null) => {
    if (item) {
      if (item.url.endsWith(".mp4")) return renderVideos(item, height);
      if (item.url.endsWith(".m4a") || item.url.endsWith(".mp3")) return renderAudios(logoAudio);
      if (item.url.endsWith(".xlsx")) return downloadOtherDocuments(logoXlsx);
      if (item.url.endsWith(".txt")) return downloadOtherDocuments(logoText);
      if (item.url.endsWith(".doc") || item.url.endsWith(".docx"))
        return downloadOtherDocuments(logoDocs);
      if (item.url.endsWith(".pdf")) return renderPdf(item);
      return renderImages(item, height);
    }
    return null;
  };
  const handleClickAway = (item) => {
    if (!item || item.url.endsWith(".m4a") || item.url.endsWith(".mp3")) return;
    if (item.url.endsWith(".pdf")) {
      handleOpenViewPdf(item);
      return;
    }
    if (
      item.url.endsWith(".txt") ||
      item.url.endsWith(".xlsx") ||
      item.url.endsWith(".doc") ||
      item.url.endsWith(".docx")
    ) {
      downloadFile(item);
      return;
    }
    setDocumentForOpen(item);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <DialogContext.Consumer>
      {(value) => (
        <Box sx={{ maxWidth: "100%", flexGrow: 1, borderRadius: "5px" }}>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              gridColumnEnd: "span 1",
              ridRowEnd: "span 1",
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                px: 3,
                borderRadius: "5px",
                "-webkit-box-align": "center",
                alignItems: "center",
                position: "absolute",
                color: "#FFF",
                zIndex: 10,
                left: 0,
                right: 0,
                top: 0,
                opacity: 0.5,
                height: 40,
                bgcolor: "rgba(0, 0, 0, 0.9)",
                transition: "opacity 200ms ease-in",
                "&:hover": {
                  opacity: 1,
                },
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontSize={10} variant="button">
                    Hora y fecha: {attachments[activeStep].captureDate} - Ubicación :{" "}
                    <IconButton
                      sx={{ color: "#ffff", fontSize: 15 }}
                      onClick={() => {
                        value.setMarket({
                          lat: attachments[activeStep].latitude,
                          lng: attachments[activeStep].longitude,
                        });
                        value.setOpen(true);
                      }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {attachments.map((step, index) => (
                <div key={`${step.label}_${Math.random()}`}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      onClick={() => handleClickAway(step)}
                      sx={{
                        borderRadius: "5px",
                        height: 255,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      {renderMultimedia(step)}
                    </Box>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
          </Box>
          <MobileStepper
            variant="text"
            sx={{
              borderRadius: "0px 0px 5px 5px",
            }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Siguiente
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Anterior
              </Button>
            }
          />
          <ViewPdf
            open={openViewPdf}
            tittlePdf="Documento"
            data={pdfUrl}
            type={3}
            onClose={handleCloseViewPdf}
          />
          <Dialog
            maxWidth="lg"
            open={Boolean(documentForOpen)}
            onClose={() => setDocumentForOpen(null)}
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Box
              sx={{
                borderRadius: "5px",
                height: "560px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {renderMultimedia(documentForOpen, "100%")}
            </Box>
          </Dialog>
        </Box>
      )}
    </DialogContext.Consumer>
  );
}

ViewAttachment.defaultProps = {
  attachments: [],
};

ViewAttachment.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
};

export default ViewAttachment;
