import httpRequest from "../httpRequest";

// eslint-disable-next-line import/prefer-default-export
export const saveCredentialService = async (dataCredential) => {
  try {
    const idCredential = await httpRequest.post(
      `electronicinvoicecredentials/create`,
      dataCredential
    );
    return idCredential;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const getCredentialService = async (filters) => {
  try {
    const { data } = await httpRequest.post("electronicinvoicecredentials/search", filters);
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const UpdateCredentialService = async (id, dataCredential) => {
  try {
    const { data } = await httpRequest.put(
      `electronicinvoicecredentials/${id}/update`,
      dataCredential
    );
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};

export const changeStatusService = async (idChange) => {
  try {
    const { data } = await httpRequest.patch(
      `electronicinvoicecredentials/${idChange}/toggle`,
      idChange
    );
    return data;
  } catch (error) {
    return JSON.parse(error);
  }
};
