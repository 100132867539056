import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import PropsTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import arrayOptions from "./arrayVideoHelp.json";

function ModalHelpComponent({ open, onClose }) {
  const [cardsHelp, setCardsHelp] = useState(arrayOptions);
  const [currentShowed, setCurrentShowed] = useState(null);
  const [nameFilter, setNameFilter] = useState("");
  const [filterPath, setFilterPath] = useState("All");
  const { pathname } = useLocation();

  const handleClickColapse = (index) => {
    if (index === currentShowed) setCurrentShowed(null);
    else setCurrentShowed(index);
  };

  const handleOnClose = () => {
    setCurrentShowed(null);
    onClose();
  };

  useEffect(() => {
    if (pathname !== "/") {
      setFilterPath("Path");
    }
  }, [pathname]);

  useEffect(() => {
    if (filterPath === "Path") {
      const newArrat = arrayOptions.filter((item) => item.path === pathname);
      setCardsHelp(newArrat);
    } else setCardsHelp(arrayOptions);
  }, [filterPath]);

  useEffect(() => {
    if (nameFilter !== "") {
      const newArrat = arrayOptions.filter((item) => item.helpName.includes(nameFilter));
      setCardsHelp(newArrat);
    }
  }, [nameFilter]);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleOnClose}>
      <DialogTitle>Ayuda</DialogTitle>
      <DialogContent>
        <Grid container my={2} mx={1} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Buscar"
              value={nameFilter}
              fullWidth
              variant="standard"
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </Grid>
          <Grid item mt={1} xs={4}>
            <TextField
              select
              label="Mostrar"
              onChange={(e) => setFilterPath(e.target.value)}
              name="pathFIlter"
              value={filterPath}
              fullWidth
              variant="standard"
              id="outlined-size-normal"
            >
              <MenuItem value="All">Todas las opciones</MenuItem>
              <MenuItem value="Path">Pagina actual</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Stack sx={{ height: "400px", overflow: "auto" }} spacing={2}>
          {cardsHelp.map((itemHelp, index) => (
            <Card>
              <CardActionArea onClick={() => handleClickColapse(index)}>
                <CardHeader title={itemHelp.helpName} />
              </CardActionArea>
              <Collapse in={currentShowed === index}>
                <CardContent>
                  <iframe
                    width="100%"
                    height={400}
                    title={`${index}_${itemHelp.helpName}`}
                    src={itemHelp.url}
                  />
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

ModalHelpComponent.propTypes = {
  open: PropsTypes.bool.isRequired,
  onClose: PropsTypes.func.isRequired,
};

export default ModalHelpComponent;
